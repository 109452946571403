import React from 'react'

export const testQueryValidity = (query, actionType, fileInfo, pk) => {
    let queryMessage;
    let isQueryValid;
    let queryCleaned = cleanQuery(query);

    let queryStartProperly = doesQueryStartProperly(queryCleaned, actionType)
    if (queryStartProperly.valid){

        const columnNames = getColumnNames(fileInfo);
        let selectIdentifierValid = isSelectIdentifierValid(queryCleaned, actionType, columnNames);

        if (selectIdentifierValid.valid){

            if(isPKValid(queryCleaned, pk)){

                let whereAfterPKValid = isWhereAfterPKValid(queryCleaned, pk)
                if (whereAfterPKValid.valid){
                    if (whereAfterPKValid.filterClauseExists){

                        let filterClauseValid = isFilterClauseSyntaxValid(queryCleaned)
                        if (filterClauseValid.valid){
                            isQueryValid = true
                            queryMessage = filterClauseValid.filterClause
                        }
                        else{
                            isQueryValid = false
                            queryMessage = filterClauseValid.message
                        }
                    }
                    else{
                        isQueryValid = true
                        queryMessage = ""
                    }
                }
                else{
                    isQueryValid = false
                    queryMessage = whereAfterPKValid.message
                }
            }
            else{
                isQueryValid = false
                queryMessage = <span>The <b>fileID</b> after the <b>from</b> keyword is invalid. Your <b>fileID</b> is <b>{pk.toString()}</b></span>
            }
        }
        else{
            isQueryValid = false
            queryMessage = selectIdentifierValid.message
        }
    }
    else{
        isQueryValid = false
        queryMessage = queryStartProperly.message
    }

    return {
        isQueryValid: isQueryValid,
        queryMessage: queryMessage
    }
}

const cleanQuery = (query) =>{
    // Lowercase everything except words between single quotes
    let queryTrim = query.trim()
    // Regex to find all values between single quotes
    const regex = /'(.*?)'/g;
    const found = queryTrim.match(regex);

    let lowerCaseQuery = queryTrim.toLowerCase();

    if (found !== null){
        if (found.length > 0){
            for (let i=0; i<found.length; i++){
                let startIdx = queryTrim.indexOf(found[i])
                let endIdx = startIdx + found[i].length;

                lowerCaseQuery = lowerCaseQuery.substring(0, startIdx) + found[i] + lowerCaseQuery.substring(endIdx, lowerCaseQuery.length)
            }
        }
    }

    return lowerCaseQuery
}

const doesQueryStartProperly = (query, actionType) => {
    let doesQueryStartProperly = false;
    let validMessage = "";

    if (actionType === "readAction" || actionType === "updateAction"){
        if (query.substring(0, 6) === "select"){
            doesQueryStartProperly = true
        }
        else{
            validMessage = <span>Query needs to start with <b>select</b></span>
        }
    }
    else if (actionType === "deleteAction"){
        if (query.substring(0, 6) === "delete"){
            doesQueryStartProperly = true
        }
        else{
            validMessage = <span>Query needs to start with <b>delete</b></span>
        }
    }

    return {valid: doesQueryStartProperly, message: validMessage}

}

const getColumnNames = (fileInfo) => {
    let columnNames = [];

    for (let colName in fileInfo['jsonColumnSample']){
        columnNames.push(colName)
    }

    if ('columnInformation' in fileInfo){
        if ('names' in fileInfo['columnInformation']){
            if (fileInfo['columnInformation']['names'].length > 0){
                columnNames = fileInfo['columnInformation']['names']
            }
        }
    }

    return columnNames
}

const isSelectIdentifierValid = (query, actionType, columnNames) => {
    let isSelectIdentifierValid;
    let message = "";

    if (query.indexOf("from") === -1){
        isSelectIdentifierValid = false
        message = <span><b>from</b> needs to be in the query</span>
    }
    else{
        isSelectIdentifierValid = false;
        const identifierClause = query.substring(6, query.indexOf("from"))

        if (!doesStringHaveSpaces(identifierClause)){
            if (actionType === "deleteAction"){
                if (identifierClause === ""){
                    isSelectIdentifierValid = true
                }
                else{
                    message = <span>There should be nothing between <b>delete</b> and <b>from</b> keywords</span>
                }
            }
            else if (actionType === "updateAction"){
                if (identifierClause === "*"){
                    isSelectIdentifierValid = true
                }
                else{
                    message = <span>There can only be <b>*</b> between the <b>select</b> and <b>from</b> keywords. When updating, the column names and value to update are specified in the Request Body</span>
                }
            }
            else if (actionType === "readAction"){
                if (identifierClause === "*"){
                    isSelectIdentifierValid = true
                }
                else{
                    if (identifierClause.trim() !== ""){
                        const colNamesInIdentifier = identifierClause.split(",");

                        let invalidColNames = [];

                        for (let i=0; i<colNamesInIdentifier.length; i++){
                            if (!columnNames.includes(colNamesInIdentifier[i])){
                                invalidColNames.push(colNamesInIdentifier[i])
                            }
                        }

                        if (invalidColNames.length === 0){
                            isSelectIdentifierValid = true
                        }
                        else{
                            const invalidNamesJSX = invalidColNames.map((x, idx) => <b key={idx.toString()}>{x}</b>)
                            message = <span>There needs to be either a <b>*</b> or all valid <b>column names</b> separated by a , between <b>select</b> and <b>from</b>. Following <b>column names</b> are not valid: {invalidNamesJSX}</span>
                        }
                    }
                    else{
                        message = <span>There needs to be either a <b>*</b> or valid <b>column names</b> separated by a , between <b>select</b> and <b>from</b></span>
                    }
                }
            }
        }
        else{
            message = <span>No spaces are allowed between <b>select</b> and <b>from</b></span>
        }
    }

    return {valid: isSelectIdentifierValid, message: message}
}

const doesStringHaveSpaces = (strToTest) => {
    let stringHasSpaces = true;

    if (strToTest.length > 0){
        if (strToTest.trim() === strToTest && strToTest.trim() !== ""){
            if (strToTest.indexOf(" ") === -1){
                stringHasSpaces = false
            }
        }
    }

    return stringHasSpaces
}

const isPKValid = (query, pk) => {
    const pkStr = pk.toString();
    const pkStartIndex = query.indexOf("from") + 4
    const queryPK = query.substring(pkStartIndex, pkStr.length + pkStartIndex);

    return queryPK === pkStr
}

const isWhereAfterPKValid = (query, pk) => {
    let whereAfterPKValid = false;
    let message = "";
    let filterClauseExists = true;

    const pkEndIdx = query.indexOf(pk.toString()) + pk.toString().length;
    if (pkEndIdx === query.length){
        whereAfterPKValid = true
        filterClauseExists = false
    }
    else{
        if (query.substring(pkEndIdx, pkEndIdx + 5) === "where"){
            whereAfterPKValid = true
        }
        else{
            message = <span>Either <b>where</b> needs to be right after the <b>fileID</b> with no spaces. Or the <b>fileID</b> needs to be the end of the query.</span>
        }
    }

    return {
        valid: whereAfterPKValid,
        message: message,
        filterClauseExists: filterClauseExists
    }

}

const isFilterClauseSyntaxValid = (query) => {
    const filterClause = query.substring(query.indexOf("where") + 5, query.length);

    if (filterClause.trim() !== ""){
        return {
            valid: true,
            message: "",
            filterClause: filterClause
        }
    }
    else{
        return {
            valid: false,
            message: <span>You need a <b>filterClause</b> after the <b>where</b> keyword</span>,
            filterClause: filterClause
        }
    }
}
