import React, {Component} from 'react'
import {connect} from 'react-redux'
import PasswordForget from '../../Authentication/UserPages/Password/PasswordForget'
import {toggleModal} from "../../Actions/ToggleModal";
import API_Root from "../../Constants/API_Root";
import PlanType from './PlanType'
import CancelAccount from "./CancelAccount"

class ProfileModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateJoined: '',
            datePro: '',
            pricingPlan: ''
        }

        this.signUpToPro = this.signUpToPro.bind(this);
    }

    signUpToPro(){
        this.props.toggleModal(null);
        this.props.toggleModal("signUpPayModal")
    }

    componentWillMount(){
        const current = new Date();
        const utcOffset = current.getTimezoneOffset();

        const errorSetState = () => this.setState({
            dateJoined: '2020-01-01 00:00:00',
            datePro: '',
            pricingPlan: 'free'
        })

        fetch(API_Root + "api/get-profile-information/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                utcOffset: utcOffset,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token")
            })
        }).then(res =>
        {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({
                        dateJoined: data.dateJoined,
                        datePro: data.datePro,
                        pricingPlan: data.pricingPlan
                    })
                })
                .catch(err => console.log(err))
            }
            else{
                errorSetState()
            }
        }).catch(err => errorSetState())

    }

    render() {
        return (
            <div>
                <br/><br/>
                <div className="row">
                    <div className="col-sm-1"/>
                    <div className="col-sm-3">
                        <h5 className="thinHeading text-muted">Account Type</h5>
                    </div>
                    <div className="col-sm-7">
                        <PlanType pricingPlan={this.state.pricingPlan} datePro={this.state.datePro}/>
                    </div>
                    <div className="col-sm-1"/>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-1"/>
                    <div className="col-sm-10">
                        <h5 className="thinHeading text-muted" style={{color: 'red'}}>Cancel Your Account</h5>
                        <CancelAccount pricingPlan={this.state.pricingPlan}/>
                    </div>
                    <div className="col-sm-1"/>
                </div>
                <br/>
                <hr/>
                <div className="row">
                    <div className="col-sm-1"/>
                    <div className="col-sm-3">
                        <h5 className="thinHeading text-muted">Email</h5>
                    </div>
                    <div className="col-sm-7">
                        <h5 className="thinHeading">{localStorage.getItem("email")}</h5>
                    </div>
                    <div className="col-sm-1"/>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-1"/>
                    <div className="col-sm-3">
                        <h5 className="thinHeading text-muted">Date Joined</h5>
                    </div>
                    <div className="col-sm-7">
                        <h5 className="thinHeading">{this.state.dateJoined}</h5>
                    </div>
                    <div className="col-sm-1"/>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-1"/>
                    <div className="col-sm-10">
                        <PasswordForget/>
                    </div>
                    <div className="col-sm-1"/>
                </div>
                <br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {
    toggleModal: toggleModal
};

export default connect(mapStateToProps, mapActionsToProps)(ProfileModalBody)