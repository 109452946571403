import Icons from "../Constants/Icons";
import React from 'react'
import APIQuickstart from "./GuidesText/APIQuickstart";
import ReadRows from "./GuidesText/Read/ReadRows";
import CreateRows from "./GuidesText/CreateRows";
import UpdateRows from "./GuidesText/UpdateRows";
import DeleteRows from "./GuidesText/DeleteRows";
import QueryBody from "../InfoPages/Query/FullTutorial/QueryBody";
import SaveForm from "../InfoPages/Tutorials/TutorialsText/SaveForm";
import LiveDashboard from "../InfoPages/Tutorials/TutorialsText/LiveDashboard";
import ReadSpreadsheets from "../InfoPages/Tutorials/TutorialsText/ReadSpreadsheets";
import ReadSubset from "./GuidesText/Read/ReadSubset";
import UpdateSubsetRows from "./GuidesText/UpdateSubsetRows";
import ColumnNamesValid from "./ColumnNamesValid";
import GoogleSheetsGuide from "./GuidesText/GoogleSheetsGuide"

const GuideDetails = [
    {
        icon: 'laptop',
        header: 'Get Started with API Spreadsheets',
        desc: 'Quick start guide for API Spreadsheets',
        title: 'get-started-with-api-spreadsheets',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <APIQuickstart/>
    },
    {
        icon: 'google drive',
        header: 'Guide to Google Sheets',
        desc: 'Learn about using Google Sheets and Common Problems',
        title: 'guide-to-google-sheets',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <GoogleSheetsGuide/>
    },
    {
        icon: 'columns',
        header: 'How Do Column Names Work',
        desc: 'Learn what makes your Column Names valid',
        title: 'column-names',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <ColumnNamesValid/>
    },
    {
        icon: Icons.readAction,
        header: 'Read All Rows',
        desc: 'Read everything in your file',
        title: 'read-all-rows',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <ReadRows readType="all"/>
    },
    {
        icon: Icons.readAction,
        header: 'Read Subset of Data',
        desc: 'Read only subset of rows with a specified criteria',
        title: 'read-subset-data',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <ReadSubset/>
    },
    {
        icon: Icons.createAction,
        header: 'Create New Row(s)',
        desc: 'Create one or more new rows in your file',
        title: 'create-new-rows',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <CreateRows/>
    },
    {
        icon: Icons.updateAction,
        header: 'Update Data in All Rows',
        desc: 'Update data in column(s) for all your rows',
        title: 'update-data-all-rows',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <UpdateRows updateType="all"/>
    },
    {
        icon: Icons.updateAction,
        header: 'Update Data in Subset of Row(s)',
        desc: 'Update data in column(s) for a rows with a specified criteria',
        title: 'update-data-subset-rows',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <UpdateSubsetRows/>
    },
    {
        icon: Icons.deleteAction,
        header: 'Delete Row(s)',
        desc: 'Delete rows in your file based on a specified criteria',
        title: 'delete-rows',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <DeleteRows/>
    },
    {
        icon: 'code',
        header: 'Write a Valid Query',
        desc: 'Learn how to write a valid query on API Spreadsheets. Compare and contrast with SQL.',
        title: 'write-a-valid-query',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <QueryBody/>
    },
    {
        icon: 'wordpress forms',
        header: 'Save Web Form Data to Spreadsheets',
        desc: 'Learn how to save web form data directly to spreadsheets',
        title: 'save-web-form-data-to-spreadsheets',
        menuType: 'tutorial',
        iconType: 'semantic',
        article: <SaveForm/>
    },
    {
        icon: 'dashboard',
        header: 'Create Live Dashboard with Google Sheets',
        desc: 'Learn how to create a live dashboard with Google Sheets',
        title: 'create-live-dashboard-with-google-sheets',
        menuType: 'tutorial',
        iconType: 'semantic',
        article: <LiveDashboard/>
    },
    {
        icon: 'code',
        header: 'Read a Spreadsheet with R & Python',
        desc: 'Learn the easiest way to read a spreadsheet with R & Python',
        title: 'read-spreadsheet-excel-file-R-Python',
        menuType: 'tutorial',
        iconType: 'semantic',
        article: <ReadSpreadsheets/>
    }
]

export default GuideDetails