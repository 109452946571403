import React, {Component} from 'react'
import {connect} from 'react-redux'
import FileURL from "../FileURL"
import { Menu, Segment } from 'semantic-ui-react'
import Icons from "../../Constants/Icons";
import CreateOptionsToolbar from "./ActionTypeOptionsToolbar/CreateOptionsToolbar";
import ReadOptionsToolbar from "./ActionTypeOptionsToolbar/ReadOptionsToolbar";
import UpdateOptionsToolbar from "./ActionTypeOptionsToolbar/UpdateOptionsToolbar";
import DeleteOptionsToolbar from "./ActionTypeOptionsToolbar/DeleteOptionsToolbar";
import FileAccordion from "../FileAccordion"
import CodeAccordionUpdated from "../CodeAccordionUpdated";
import isObjectEmpty from "../../Constants/isObjectEmpty";
import EmptyFileWarning from "./ActionTypeOptionsToolbar/EmptyFileWarning";

class FileSummaryCodeIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            outputFormat: 'jsonRow',
            apiType: 'createAction',
            rows: JSON.parse(JSON.stringify(this.props.fileInformation[this.props.pkID].rowLength)),
            createFormat: 'columnValue',
            readQuery: false
        }

        this.changeState = this.changeState.bind(this);
        this.handleActionTypeChange = this.handleActionTypeChange.bind(this);
        this.getAPIType = this.getAPIType.bind(this);
    }

    getAPIType(){
        let apiType = this.state.apiType;

        if (this.props.showingTutorial){
            if (this.props.tutorialStep !== null){
                if (this.props.tutorialStep.includes("read")){
                    apiType = "readAction"
                }
                else if (this.props.tutorialStep.includes("create")){
                    apiType = "createAction"
                }
                else if (this.props.tutorialStep.includes("delete")){
                    apiType = "deleteAction"
                }
                else if (this.props.tutorialStep.includes("update")){
                    apiType = "updateAction"
                }
            }
        }

        return apiType
    }

    componentDidUpdate(prevProps){
        if (prevProps.fileInformation[this.props.pkID].rowLength !== this.props.fileInformation[this.props.pkID].rowLength){
            this.setState({
                rows: this.props.fileInformation[this.props.pkID].rowLength
            })
        }
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    handleActionTypeChange(e, { name }){
        this.setState({ apiType: name + "Action"})
    }

    render() {
        const fileInformation =  this.props.fileInformation[this.props.pkID];
        const proBlock = this.props.filePKProBlock[this.props.pkID];

        const apiType = this.getAPIType()

        const rowLen = fileInformation['rowLength'];

        let fileURL = <FileURL rowLen={rowLen}
                               rows={this.state.rows}
                               textPadding={this.props.textPadding}
                               outputFormat={this.state.outputFormat}
                               pk={this.props.pk}
                               pkID={this.props.pkID}
                               apiType={apiType}
                               readQuery={this.state.readQuery}
                        />;

        if (proBlock){
            fileURL = null
        }

        let fileEmpty = false;

        if (isObjectEmpty(fileInformation['jsonColumnSample'])){
            fileEmpty = true;
        }

        const fileType = fileInformation['inputType']

        const toolbars = {
            createAction: <CreateOptionsToolbar
                createFormat={this.state.createFormat}
                idx={this.props.idx}
                changeState={this.changeState}
                fileEmpty={fileEmpty}
                fileType={fileType}
                pkID={this.props.pkID}
                pk={this.props.pk}
            />,
            readAction: <ReadOptionsToolbar
                idx={this.props.idx}
                outputFormat={this.state.outputFormat}
                changeState={this.changeState}
                rowLen={rowLen}
                rows={this.state.rows}
                apiType={apiType}
                readQuery={this.state.readQuery}
                pkID={this.props.pkID}
            />,
            updateAction: <UpdateOptionsToolbar
                pkID={this.props.pkID}
                fileEmpty={fileEmpty}
                fileType={fileType}
                pk={this.props.pk}
            />,
            deleteAction: <DeleteOptionsToolbar
                pkID={this.props.pkID}
            />
        }

        const dataFormat = apiType === "readAction" ? this.state.outputFormat : apiType === "createAction"  ? this.state.createFormat : "columnValue"

        const divHeader = <div className="row">
            <div className="col-sm-12">
                <h4>Quickstart Code</h4>
            </div>
        </div>;

        let actionToolbarClassname = "fileSummaryCodeToolbar";
        let requestToolbarClassname = null;
        let codeSnippetClassname = null;
        let responseClassname = null;

        if (["readActionParameters", "createActionParameters", "deleteActionParameters", "updateActionParameters"].includes(this.props.tutorialStep)){
            actionToolbarClassname += " tutorialHighlighted tutorialHighlighted1";
            if (this.props.tutorialStep === "createActionParameters" || this.props.tutorialStep === "updateActionParameters"){
                requestToolbarClassname = "tutorialHighlighted"
            }
        }

        if (["readActionCodeSnippet", "createActionCodeSnippet", "deleteActionCodeSnippet", "updateActionCodeSnippet"].includes(this.props.tutorialStep)){
            codeSnippetClassname = "tutorialHighlighted"
        }

        if (this.props.tutorialStep === "readActionResponse"){
            responseClassname = "tutorialHighlighted"
        }

        return (
            <div>
                {this.props.tableType === "all" ? null : divHeader}
                <Menu attached='top' tabular>
                    <Menu.Item
                           name="create"
                           icon={Icons.createAction}
                           active={apiType === 'createAction'}
                           style={apiType === 'createAction' ? {backgroundColor: 'whitesmoke'} : null}
                           onClick={this.handleActionTypeChange} />
                    <Menu.Item
                        icon={Icons.readAction}
                        name="read"
                        active={apiType === 'readAction'}
                        style={apiType === 'readAction' ? {backgroundColor: 'whitesmoke'} : null}
                        onClick={this.handleActionTypeChange} />
                    <Menu.Item
                        icon={Icons.updateAction}
                        name="update"
                        active={apiType === 'updateAction'}
                        style={apiType === 'updateAction' ? {backgroundColor: 'whitesmoke'} : null}
                        onClick={this.handleActionTypeChange} />
                    <Menu.Item
                        icon={Icons.deleteAction}
                        name="delete"
                        active={apiType === 'deleteAction'}
                        style={apiType === 'deleteAction' ? {backgroundColor: 'whitesmoke'} : null}
                        onClick={this.handleActionTypeChange} />
                </Menu>
                <Segment attached="bottom" style={{backgroundColor: 'whitesmoke'}}>
                    <div className={actionToolbarClassname} id="fileSummaryCodeToolbar">
                        {toolbars[apiType]}
                    </div>
                    <div>
                        {fileEmpty && (apiType === "createAction" || apiType === "updateAction") ? <EmptyFileWarning pk={this.props.pk} pkID={this.props.pkID} actionType={apiType.replace("Action", "")} /> : null}
                    </div>
                    {
                        apiType === "updateAction" || apiType === "createAction" ?
                        <div className="row">
                            <div className="col-sm-12">
                                <br/>
                                <div className={requestToolbarClassname}>
                                    <FileAccordion pkID={this.props.pkID}
                                                   dataFormat={dataFormat}
                                                   apiType={apiType}
                                                   pk={this.props.pk}
                                                   tableType={this.props.tableType}
                                    />
                                </div>
                            </div>
                        </div> : null
                    }
                    <div className="row">
                        <div className="col-sm-12">
                            {fileURL}
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '20px'}}>
                        <div className="col-sm-12">
                            <div className={codeSnippetClassname} id="codeSnippetAccordion">
                                <CodeAccordionUpdated
                                    outputFormat={this.state.outputFormat}
                                    pk={this.props.pk}
                                    pkID={this.props.pkID}
                                    apiType={apiType}
                                    rowLen={rowLen}
                                    rows={this.state.rows}
                                    createFormat={this.state.createFormat}
                                    readQuery={this.state.readQuery}
                                    tableType={this.props.tableType}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        apiType === "readAction" ? <div className="row">
                            <div className="col-sm-12">
                                <br/>
                                <div id="fileResponseSampleDiv" />
                                <div className={responseClassname} >
                                    <FileAccordion pkID={this.props.pkID}
                                                   dataFormat={dataFormat}
                                                   apiType={apiType}
                                                   pk={this.props.pk}
                                                   tableType={this.props.tableType}
                                    />
                                </div>
                            </div>
                        </div> : null
                    }
                    <br/>

                </Segment>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePKProBlock: state.mainState.filePKProBlock,
    showingTutorial: state.mainState.showingTutorial,
    tutorialStep: state.mainState.tutorialStep
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FileSummaryCodeIndex)