import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyStatus} from "../Actions/ModifyStatus";
import {toggleModal} from "../Actions/ToggleModal";
import API_Root from "../Constants/API_Root";
import constants from "../Constants/constants";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import {toggleToast} from "../Actions/ToggleToast";
import RefreshFile from "./RefreshFile"
import {modifyModalInfo} from "../Actions/ModifyModalInfo";

class ReuploadFile extends Component {
    constructor(props) {
        super(props);

        this.reuploadFile = this.reuploadFile.bind(this);
        this.getStatusAndResults = this.getStatusAndResults.bind(this);
        this.fileUploadClick = this.fileUploadClick.bind(this);
    }

    fileUploadClick(e){
        this.fileUpload.click()
    }

    reuploadFile(e){
        this.props.toggleModal("loadingModalEditFile");

        const files = e.target.files;

        if (files.length > 0){
            const file = files[0];

            const formData = new FormData();

            formData.append("file", file);
            formData.append("email", localStorage.getItem("email"));
            formData.append("token", localStorage.getItem("token"));
            formData.append("pk", this.props.pk);

            this.props.modifyStatus("reading");

            const errorFetch = () => {this.props.toggleToast({show: true, message: "There was an error reading your file 😞. Please try again. Contact our Support if this error persists.", type: "error"}); this.props.modifyStatus("errorSaved"); this.props.toggleModal(null)};

            fetch(API_Root + 'api/reupload-api-file/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
                body: formData
            }).then(res => {
                if (res.status === 201){
                    res.json().then(
                        (data) => {
                            const pkArray = [{'id': this.props.pkID, "pk": data.pk}]
                            this.props.modifyStatus("saved");
                            this.getStatusAndResults(pkArray);
                    }).catch(err => errorFetch())

                    // const pkArray = [{'id': this.props.pkID, 'pk': this.props.pk}]
                }
                else{
                    errorFetch()
                }
            }).catch(err => errorFetch());

        }
    }

    getStatusAndResults(filePK){
        // Open up WebSockets
        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/read-api-files/");

        const props = this.props;

        // Offset to get created dt
        const current = new Date();
        const utcOffset = current.getTimezoneOffset();

        let socketInfo = {
            filePK: filePK,
            sheetRead: props.sheetRead,
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
            utcOffset: utcOffset,
            reupload: true,
            originalFilePK: [{'id': props.pkID, 'pk': props.pk}]
        };

        if (!('fixColumnNames' in this.props)){
            socketInfo['checkColumnNames'] = true
        }

        // Send the PKs and if any column names
        socket.onopen = function(event) {
            socket.send(JSON.stringify(socketInfo));
        };

        socket.onmessage = function(event) {
            const reply = JSON.parse(event.data);
            const status = reply['status'];
            console.log(reply)
            if (status === "api"){
                props.modifyStatus(status);
                let fileInformation = reply.fileInformation;
                let sheetRead = reply.sheetRead;

                let propsFileInformation = JSON.parse(JSON.stringify(props.fileInformation));
                let propsSheetRead = JSON.parse(JSON.stringify(props.sheetRead));

                if (typeof propsFileInformation !== 'undefined'){
                    if (Object.entries(propsFileInformation).length !== 0 && propsFileInformation.constructor === Object){
                        propsFileInformation[props.pkID] = fileInformation[props.pkID];
                        propsSheetRead[props.pkID] = sheetRead[props.pkID];
                    }
                }

                props.modifyFileInformation(propsFileInformation);
                props.modifySheetRead(propsSheetRead);

                props.toggleModal(null);

                socket.close()
            }
            else if (status === "error"){
                props.modifyStatus(status);
                socket.close()
                props.toggleModal(null);
                props.toggleToast({show: true, message: "There was an error reading your file 😞. Please try again. Contact our Support if this error persists.", type: "error"})
            }
            else if (status === "columnNamesError"){
                socket.close()
                props.modifyStatus("upload");
                props.toggleModal(null)
                props.modifyModalInfo({
                    infoType: "columnMap",
                    columnNamesMap: reply.columnNamesMap
                })
                props.toggleModal("fixColumnNamesModal")
            }
        };
    }

    render() {
        // Hides the Input Button
        const inputFileStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        };

        // Valid extensions for the file upload
        const acceptExtensions = constants.extensions.join(",");

        const inputType = 'inputType' in this.props.fileInformation[this.props.pkID] ? this.props.fileInformation[this.props.pkID]['inputType'] : null;

        if (inputType.includes("file")) {
            return (
                <div>
                <input
                    type="file"
                    name="reinputFileUpload"
                    id="reinputFileUpload"
                    className="form-control-file"
                    onChange={this.reuploadFile}
                    style={inputFileStyle}
                    multiple={false}
                    accept={acceptExtensions}
                    ref={fileUpload => this.fileUpload = fileUpload}
                />
                <label htmlFor="reinputFileUpload" style={{width: '100%'}}>
                    <Button content="Replace File" basic color='green' icon='upload' onClick={this.fileUploadClick} fluid />
                </label>
            </div>
            )
        }
        else{
            return(
                <RefreshFile pk={this.props.pk} pkID={this.props.pkID} inputType={inputType}/>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    sheetRead: state.mainState.sheetRead,
    editOptions: state.mainState.editOptions,
    fileInformation: state.mainState.fileInformation,

})

const mapActionsToProps = {
    modifyStatus: modifyStatus,
    toggleModal: toggleModal,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    toggleToast: toggleToast,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(ReuploadFile)