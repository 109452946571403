import React, {Component} from 'react'
import FileInformationTable from './FileInformationTable'
import Loader from 'react-loader-spinner'
import API_Root from "../Constants/API_Root";
import {connect} from "react-redux";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import { Segment, Button } from 'semantic-ui-react'
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import {initOriginalFilePK} from "../Actions/InitOriginalFilePK";
import {modifyFilesFetchedTime} from "../Actions/ModifyFilesFetchedTime";
import { isBrowser } from 'react-device-detect'
import { push } from 'react-router-redux'

// const shouldFetchFiles = (currDate, fetchDate) => {
//     let shouldFetch = true;
//
//     if (fetchDate !== null){
//         let minutesElapsed = Math.round(((currDate - fetchDate % 86400000) % 3600000) / 60000); // minutes
//
//         if (minutesElapsed <= 5){
//             shouldFetch = false;
//         }
//     }
//
//     return shouldFetch
// }

class FilesIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            fetching: true
        };

        this.goToUpload = this.goToUpload.bind(this);
    }

    componentWillMount(){
        // Offset to get created dt
        const current = new Date();

        // if (shouldFetchFiles(current, this.props.filesFetchedTime) || this.props.filePK.length === 1){
            fetch(API_Root + "api/get-api-files/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    utcOffset: current.getTimezoneOffset(),
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                })
            }).then(res => res.json())
                .then((data)=> {
                    const dataCopy = JSON.parse(JSON.stringify(data));
                    this.props.initOriginalFilePK(dataCopy.filePK)
                    this.props.modifyFilePK(dataCopy.filePK);
                    this.props.modifyFileInformation(data.fileInformation);
                    this.props.modifySheetRead(data.sheetRead);
                    this.props.modifyFilePKProBlock(data.filePKProBlock);
                    this.props.modifyFilesFetchedTime();
                    this.setState({fetching: false});
                })
                .catch(err => this.setState({fetching: false})).catch(err => this.setState({fetching: false}));
        // }
        // else{
        //     this.setState({fetching: false})
        // }
    }

    goToUpload(e){
        this.uploadLink.click()
    }

    render() {
        let body;

        if (this.state.fetching){
            body =
                <div style={{padding: '100px 0', textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4 className="thinHeading" style={{color: 'black'}}>Getting your files...</h4>
                </div>
        }
        else{
            if (this.props.originalFilePK.length > 0){
                body = <FileInformationTable tableType="all"/>
            }
            else{
                body =
                <div style={ isBrowser ? {paddingLeft: '200px', paddingRight: '200px'} : null}>
                    <Segment raised>
                        <a href="/upload" style={{display: 'none'}} ref={uploadLink => this.uploadLink = uploadLink}>Upload</a>
                        <h3 style={{padding: '20px', textAlign: 'center'}} className="thinHeading">You haven't converted any files to APIs yet.<br/>
                            <Button onClick={this.goToUpload} icon="upload" content="Upload a file to get started 😀" color="green" size="huge"/>
                        </h3>
                    </Segment>
                </div>
            }

        }

        return(
            <div style={{minHeight: '100vh', paddingTop: '50px', paddingBottom: '100px'}}>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    originalFilePK: state.mainState.originalFilePK,
    filesFetchedTime: state.mainState.filesFetchedTime
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    modifyFilePK: modifyFilePK,
    modifySheetRead: modifySheetRead,
    modifyFilePKProBlock: modifyFilePKProBlock,
    initOriginalFilePK: initOriginalFilePK,
    modifyFilesFetchedTime: modifyFilesFetchedTime,
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(FilesIndex)