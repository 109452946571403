import React, {Component} from 'react'
import HeroImage from "./Illustrations/HeroImage.svg";
// import SilcaLogo from "../Constants/SilcaLogo.png";
// import { Icon } from 'semantic-ui-react'
import UpdatedCTA from "./UpdatedCTA";
import SignUp2Wave from "./Illustrations/SignUp2Wave.svg";
import SignUp3Wave from "./Illustrations/SignUp3Wave.svg";
import SignUp4Wave from "./Illustrations/SignUp4Wave.svg";
import SignUp5Wave from "./Illustrations/SignUp5Wave.svg";
import FeatureCard from "./FeatureCard";
import WebFormIllustration from "./Illustrations/WebFormIllustration.svg"
import DashboardIllustration from "./Illustrations/DashboardIllustration.svg"
import AppsIllustration from "./Illustrations/AppsIllustration.svg"
import SaveTime from "./Illustrations/SaveTime.svg"
import Editing from "./Illustrations/Editing.svg"
import MinimalCoding from "./Illustrations/MinimalCoding.svg"
import HowItWorksAPI from "./Illustrations/HowItWorksAPI.svg"
import APIQuickstartCode from "../InfoPages/HowItWorks/Features/APIQuickstartCode.js"
import CustomerCarousel from "../InfoPages/CustomerCarousel";
import { connect } from 'react-redux'

class UpdatedLandingPageIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            buttonMargin: this.getResponsiveStyle("buttonMargin"),
            heroClassName: this.getResponsiveStyle("heroClassName"),
            headerFontSize: this.getResponsiveStyle("headerFontSize"),
            showHero: true,
            apiCount: 1700
        }

        this.updateDimensions = this.updateDimensions.bind(this);
        this.getResponsiveStyle = this.getResponsiveStyle.bind(this);
    }

    getResponsiveStyle(style, updateState=false){
        let styleAttr;

        if (window.innerWidth >= 1280){
            if (style === "buttonMargin"){
                styleAttr = Math.floor((window.innerWidth - 1280) * 0.2) + 90
            } else if (style === "heroClassName"){
                styleAttr = "updatedLandingHero"
            } else if (style === "headerFontSize"){
                styleAttr = Math.floor((window.innerWidth - 1280) * 0.2) + 275
            } else if (style === "showHero"){
                styleAttr = true
            }
        } else {
            if (window.innerWidth < 1100){
                if (style === "showHero"){
                    styleAttr = false
                }
            } else {
                if (style === "showHero"){
                    styleAttr = true
                }
            }

            if (style === "buttonMargin"){
                styleAttr = 90
            } else if (style === "heroClassName"){
                styleAttr = "updatedLandingHero"
            } else if (style === "headerFontSize"){
                styleAttr = 275
            }
        }

        if (updateState){
            this.setState({ [style]: styleAttr })
        } else {
            return styleAttr
        }
    }



    updateDimensions = () => {
        this.getResponsiveStyle("showHero", true)
        this.getResponsiveStyle("buttonMargin", true)
        this.getResponsiveStyle("heroClassName", true)
        this.getResponsiveStyle("headerFontSize", true)
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);

        fetch('https://api.apispreadsheets.com/data/1061/').then(res =>
        {
            if (res.status === 200){
                res.json().then(
                    data => this.setState({ apiCount: data["data"][0]["api_count"]})
                ).catch()
            }
        }).catch()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        return (
            <div style={{minHeight: '100vh', color: this.props.isDarkMode ? "white" : "black", backgroundColor: this.props.isDarkMode ? "black" : "white"}}>
                {
                    this.state.showHero ?
                        <div className={this.state.heroClassName}>
                            <div>
                                <div style={{marginLeft: '15%', textAlign: "center"}}>
                                    <h1 style={{fontSize: this.state.headerFontSize.toString() + "%", fontWeight: '900'}}>
                                        Create Live Dashboards with your Spreadsheet & Google Sheets
                                    </h1>
                                </div>
                                <UpdatedCTA style={{marginTop: this.state.buttonMargin.toString() + "px", marginLeft: '20%'}}/>
                            </div>
                            <div>
                                <img src={HeroImage} style={{width: '70%', height: 'auto', paddingRight: '5%', float: 'right'}} alt="Hero Spreadsheet Flow"/>
                            </div>
                        </div> :
                        <div style={{marginTop: '80px', fontFamily: "museo sans rounded, sans-serif"}}>
                            <div style={{textAlign: 'center'}}>
                                <h1 style={{fontSize: "450%", fontWeight: '900'}}>
                                    Instantly Use Your <br/>Spreadsheets as an <br/>API and Database
                                </h1>
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <UpdatedCTA style={{marginTop: "50px"}}/>
                            </div>
                        </div>
                }
                {/*<div className="updatedLandingSocialProof">*/}
                    {/*<div className="row">*/}
                        {/*<div className="col-sm-5">*/}
                            {/*<img src="https://images2.imgbox.com/97/0c/s6oR7LR6_o.png" alt="world globe" style={{height: '100%', float: 'right', width: '30%'}}/>*/}
                        {/*</div>*/}
                        {/*<div className="col-sm-7">*/}
                            {/*<h1 className="servingGlobalAPIHeader">*/}
                                {/*Serving <span style={{color: 'green'}}>{this.state.apiCount.toString()}</span>*/}
                                {/*<br/>*/}
                                {/*APIs Worldwide*/}
                            {/*</h1>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<br/><br/>*/}
                    {/*<div className="customerTestimonials">*/}
                    {/*<CustomerCarousel/>*/}
                    {/*<a href="http://www.silca.cc" target="_blank" rel="noopener noreferrer">*/}
                    {/*<img src={SilcaLogo} style={{width: '25%', margin: '0 auto', marginTop: '10px'}} alt="SilcaLogo" />*/}
                    {/*</a>*/}
                    {/*<a href="/case-study/silca" className="customerTestimonialLink"><h4 className="thinHeading linkStyle" style={{marginTop: '10px'}}>Learn how Silca uses API Spreadsheets to build their email list <Icon name="arrow right" /></h4></a>*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/*<div style={{textAlign: 'center', marginTop: '150px', marginBottom: '150px'}}>*/}
                    {/*<UpdatedCTA />*/}
                {/*</div>*/}
                {/*<div>*/}
                    {/*<h1 style={{textAlign: 'center', fontFamily: 'museo sans rounded, sans-serif', fontSize: '500%'}}>How it Works</h1>*/}
                    {/*<img src={HowItWorksAPI} style={{width: '100%', height: 'auto'}} alt="Connector2"/>*/}
                    {/*<div style={{width: '50%', margin: '0 auto'}}>*/}
                        {/*<APIQuickstartCode/>*/}
                    {/*</div>*/}
                    {/*<div style={{textAlign: 'center', marginTop: '150px'}}>*/}
                        {/*<UpdatedCTA />*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/*<div>*/}
                    {/*<img src={SignUp2Wave} style={{width: '100%', height: 'auto'}} alt="Connector1"/>*/}
                    {/*<div>*/}
                        {/*<h1 style={{textAlign: 'center', fontFamily: 'museo sans rounded, sans-serif', fontSize: '500%'}}>Use Cases</h1>*/}
                        {/*<br/>*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-sm-4">*/}
                                {/*<a href="/tutorials/save-web-form-data.html" target="_blank" className="nostyle">*/}
                                    {/*<FeatureCard*/}
                                        {/*img={WebFormIllustration}*/}
                                        {/*header="web-forms"*/}
                                        {/*desc="Save data from web forms to spreadsheets"*/}
                                    {/*/>*/}
                                {/*</a>*/}
                            {/*</div>*/}
                            {/*<div className="col-sm-4">*/}
                                {/*<a href="/tutorials/dashboard-with-google-sheets.html" target="_blank" className="nostyle">*/}
                                    {/*<FeatureCard*/}
                                        {/*img={DashboardIllustration}*/}
                                        {/*header="dashboards"*/}
                                        {/*desc="Create a live dashboard with Google Sheets"*/}
                                    {/*/>*/}
                                {/*</a>*/}
                            {/*</div>*/}
                            {/*<div className="col-sm-4">*/}
                                {/*<a href="/tutorials/react-site-with-no-backend.html" target="_blank" className="nostyle">*/}
                                    {/*<FeatureCard*/}
                                        {/*img={AppsIllustration}*/}
                                        {/*header="apps"*/}
                                        {/*desc="Use spreadsheet data to power your apps"*/}
                                    {/*/>*/}
                                {/*</a>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<img src={SignUp3Wave} style={{width: '100%', height: 'auto'}} alt="Connector2"/>*/}
                    {/*<div>*/}
                        {/*<h1 style={{textAlign: 'center', fontFamily: 'museo sans rounded, sans-serif', fontSize: '500%'}}>Benefits</h1>*/}
                        {/*<br/>*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-sm-4">*/}
                                {/*<FeatureCard*/}
                                    {/*img={SaveTime}*/}
                                    {/*header="save-time"*/}
                                    {/*desc="with not having to set up a database"*/}
                                {/*/>*/}
                            {/*</div>*/}
                            {/*<div className="col-sm-4">*/}
                                {/*<FeatureCard*/}
                                    {/*img={Editing}*/}
                                    {/*header="easy-editing"*/}
                                    {/*desc="in spreadsheets or live in Google Sheets"*/}
                                {/*/>*/}
                            {/*</div>*/}
                            {/*<div className="col-sm-4">*/}
                                {/*<FeatureCard*/}
                                    {/*img={MinimalCoding}*/}
                                    {/*header="minimal-coding"*/}
                                    {/*desc="experience required to get started"*/}
                                {/*/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<img src={SignUp4Wave} style={{width: '100%', height: 'auto'}} alt="Connector3"/>*/}
                    {/*<div style={{textAlign: 'center'}}>*/}
                        {/*<UpdatedCTA />*/}
                    {/*</div>*/}
                    {/*<img src={SignUp5Wave} style={{width: '100%', height: 'auto', marginTop: "-2px"}} alt="Connector4"/>*/}
                {/*</div>*/}

                {/*<div style={{margin: '0 auto', width: '25%'}}>*/}
                {/*<UpdatedCTA/>*/}
                {/*</div>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isDarkMode: state.mainState.isDarkMode
})

export default connect(mapStateToProps, null)(UpdatedLandingPageIndex)