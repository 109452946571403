import React, {Component} from 'react'
import SocialMediaButtons from "./SocialMediaButtons";
import { Icon } from 'semantic-ui-react'
import Images from "../../Constants/Images";

class MobileFooter extends Component {
    render() {
        return (
            <div className="footerIndex" style={{color: 'black', textAlign: 'center'}}>
                <br/><br/>
                <div className="fullFooterBox">
                    <h3>Made by</h3>
                    <img alt="Love Spreadsheets Logo" src={Images.loveLogo} style={{cursor: 'pointer', width: '80%'}} onClick={(e) => this.goToURL(e, "https://www.lovespreadsheets.com/")}/>
                    <br/>
                    <p style={{color: 'gray'}}><i>Helping Spreadsheet Users Play in the Big Data World</i></p>
                </div>
                <br/>
                <div style={{width: '50%', margin: '0 auto'}}>
                    <SocialMediaButtons />
                </div>
                <br/><br/>
                <a href="https://www.woyera.com/" target="_blank" rel="noopener noreferrer"><Icon name="copyright outline"/> 2020 Woyera Inc </a>
                <br/>
                <a href="/terms" target="_blank" rel="noopener noreferrer"> Terms & Conditions</a>
                <br/>
                <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                <br/><br/>
            </div>
        )
    }
}

export default MobileFooter