import React, {Component} from 'react'
import {connect} from 'react-redux'
import AuthenticatedApp from './AuthenticatedApp'

class AppRedux extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        // console.log("Is Dark Mode")
        // console.log(this.props.isDarkMode)

        return (
            <AuthenticatedApp isDarkMode={this.props.isDarkMode}/>
        )
    }
}

const mapStateToProps = (state) => ({
    isDarkMode: state.mainState.isDarkMode
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AppRedux)