import React, {Component} from 'react'
import {HelpInfo} from "./HelpInfo";
import Hero from "../../SharedComponents/Hero"
import HelpMenu from "./HelpMenu";
import HelpDisplay from "./HelpDisplay";

class HelpIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            menuItem: HelpInfo[0].info.name
        }

        this.changeMenuItem = this.changeMenuItem.bind(this);
    }

    changeMenuItem(menuItem){
        this.setState({
            menuItem: menuItem
        })
    }


    render() {
        return (
            <div className="helpIndex">
                <Hero
                    header="FAQs"
                    desc={<span>Our most frequently asked questions. </span>}
                    textColor="white"
                    color1="rgb(124,252,0)"
                    color2="rgb(0,128,0)"
                />
                <div className="row helpViewIndex">
                    <div className="col-sm-3">
                        <HelpMenu menuItem={this.state.menuItem} changeMenuItem={this.changeMenuItem} />
                    </div>
                    <div className="col-sm-9">
                        <HelpDisplay menuItem={this.state.menuItem}/>
                    </div>
                </div>
                <div className="faqEmailUs">
                    <div className="row">
                        <div className="col-sm-4" />
                        <div className="col-sm-4">
                            <div style={{textAlign: 'center'}}>
                                <h2>Have more questions?</h2>
                                <h4 style={{color: 'gray'}}>Email us at <a href="mailto:info@apispreadsheets.com?Subject=Pricing%20And%20Billing%20Questions">info@apispreadsheets.com</a></h4>
                            </div>
                        </div>
                        <div className="col-sm-4" />
                    </div>
                </div>
            </div>
        )
    }
}

export default HelpIndex