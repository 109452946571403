import React, {Component} from 'react'
import AceEditor from 'react-ace';
import 'brace/mode/html';
import CommentSection from "../CommentSection";

class LiveDashboard extends Component {
    render() {
        const startCode = '<!DOCTYPE html>\n' +
            '<html>\n' +
            '  <head>\n' +
            '    <title>Super Bowl Bar Chart</title>\n' +
            '    <script src="https://cdn.anychart.com/releases/8.0.0/js/anychart-base.min.js"></script>\n' +
            '  </head>\n' +
            '  <body>\n' +
            '    <div id="container" style="width: 100%; height: 100%"></div>                                   \n' +
            '    <script> <!--chart code -->  </script>\n' +
            '</body>\n' +
            '</html>'

        const fullCode = '<!DOCTYPE html>\n' +
            '<html>\n' +
            '  <head>\n' +
            '    <title>Super Bowl Bar Chart</title>\n' +
            '    <script src="https://cdn.anychart.com/releases/8.0.0/js/anychart-base.min.js"></script>\n' +
            '  </head>\n' +
            '  <body>\n' +
            '    <div id="container" style="width: 100%; height: 100%"></div>\n' +
            '    <script>\n' +
            '      anychart.onDocumentReady(function() {                                         // Fetch Data from the API\n' +
            '        fetch(\'https://api.apispreadsheets.com/data/422/?dataFormat=matrix\')\n' +
            '        .then((response) => {\n' +
            '            return response.json();\n' +
            '        })\n' +
            '        .then((myJson) => {\n' +
            '         // create data variable for the chart\n' +
            '          var data = {\n' +
            '            header: ["Team", "Wins"],\n' +
            '            rows: myJson.data\n' +
            '          }\n' +
            '          // create the chart\n' +
            '          var chart = anychart.column();\n' +
            '          // add the data\n' +
            '          chart.data(data);\n' +
            '          // set the chart title\n' +
            '          chart.title("Super Bowl Wins");\n' +
            '          // draw\n' +
            '          chart.container("container");\n' +
            '          chart.draw();\n' +
            '        });\n' +
            '      });\n' +
            '    </script>\n' +
            '  </body>\n' +
            '</html>'

        return (
            <div>
                <p>Okay, this is a bit of a cheeky title as this guide won’t cover how to make a full dashboard. But rather how to make a live bar graph connected to Google Sheets. </p>
                <p>This means you will have a bar graph on a website which will update live when you change values in your Google Sheet</p>
                <p>You can check out what the <a href="https://asharma327.github.io/LiveBarGraphDemo/" target="_blank" rel="noopener noreferrer">final result </a>will look like.</p>
                <figure>
                    <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/4810/0*KvpoeBesovdAVrM8" height="100%" width="100%" alt="LiveDashboard_1" />
                </figure>
                <p>You can take this knowledge and with a bit more learning of front-end development, you can extend it to make a complex organizational dashboard.</p>
                    <h4>Let’s Get Started!</h4>
                <p>We will be covering three tools that make this simple:</p>
                <ol>
                    <li>Create a Google Sheet API using <a href="/">API Spreadsheets</a></li>
                    <li>Creating an HTML file with the bar graph code using <a href="https://www.anychart.com/blog/2017/10/25/javascript-bar-chart-tutorial/" target="_blank" rel="noopener noreferrer">Any Chart</a> and connecting to the Google Sheets Data</li>
                    <li>Making the HTML file live by deploying it on GitHub using <a href="https://pages.github.com/" target="_blank" rel="noopener noreferrer">GitHub Pages</a> </li>
                </ol>
                <br/>
                <hr />
                <br/>
                <h1>Create a Google Sheet API using API Spreadsheets</h1>
                <br/>
                <h4><b>1. Create a Google Sheet in your Google Account</b></h4>
                <p> We are going to create one with the <b>Team</b> and <b>Wins</b> of the Teams that have won the Super Bowl 2019.</p>
                <figure>
                    <img style={{margin: '0 auto', display: 'block'}}src="https://miro.medium.com/max/343/1*GaKF-j8VSbM75gaOiDD_Zw.png" height="40%" width="40%" alt="LiveDashboard" />
                    <figcaption style={{textAlign: 'center'}}><sub>Sample Google Sheet </sub></figcaption>
                </figure>
                <h4><b>2. Convert this to an API</b></h4>
                <p>Sign Up for an account on API Spreadsheets</p>
                <p>Click on Google Sheets and select your <b>SuperBowlWinners</b> Google Sheet. After you have selected the file you should see the following</p>
                <figure>
                    <img style={{margin: '0 auto', display: 'block'}}src="https://miro.medium.com/max/700/1*Xw2HaIgkZ9Udksj5OS4F6Q.png" height="100%" width="100%" alt="API Spreadsheets Dashboard" />
                </figure>
                <h4><b>3. Change Data Format & Copy the URL</b></h4>
                <p>
                    Due to the Any Chart library we are using to make the graph, we need our data in a certain format.
                </p>
                <p>
                    So we are going to change the Data Format to <b>Matrix</b> and Copy the API URL.
                </p>
                    <figure>
                <img src="https://miro.medium.com/max/586/1*tsaVuvCOUhCzTF9z4-J_vg.png" height="100%" width="100%" alt="API Spreadsheets Dashboard" />
                    </figure>
                <p>Keep this URL handy as we will be using it in the next section.</p>
                <hr/>
                <br/>
                <h1>Creating HTML File with Bar Graph Code using Any Charts</h1>
                <p><i>This section is paraphrased from the Any Chart website so feel free to read that tutorial for a more in-depth look into this section.</i></p>
                <p><i>You can also check out the full HTML file at my GitHub repo: <a href="https://github.com/asharma327/LiveBarGraphDemo/blob/master/index.html" target="_blank" rel="noopener noreferrer">https://github.com/asharma327/LiveBarGraphDemo/blob/master/index.html</a></i></p>
                <p><b>1. Make an empty folder and call it anything you want</b></p>
                <p>I will be calling it <b><i>LiveBarGraphDemo</i></b> so anywhere you see that in the tutorial change it to what you are calling it</p>
                <p><b>2. Create a file inside the folder called <i>index.html</i></b></p>
                <p><b>3. Write the following code in <i>index.html</i></b></p>
                <div id="formCode">
                    <AceEditor
                        mode="html"
                        theme="monokai"
                        value={startCode}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={250}
                        width="auto"
                    />
                </div>
                <p>This code sets up the HTML file and references the Any Chart Javascript library that we will be using for our Bar Graph.</p>
                <p>It also has a <b>{'<div>'}</b> called <i>container</i> with width and height of 100%. This is where our Bar Graph will appear.</p>
                <p>After the <b>{'<div>'}</b> there is a <b>{'<script>'}</b> tag. This is where our chart code will go.</p>
                <p><b>4. Write the following code in between the <i>{'<script>'}</i> tags</b></p>
                <br/>
                <div id="formCode">
                    <AceEditor
                        mode="html"
                        theme="monokai"
                        value={fullCode}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={400}
                        width="auto"
                    />
                </div>
                <p>Let's walk through the code between the <b>{'<script>'}</b></p>
                <ul>
                    <li>We first fetch the data from the API that we created in the previous step</li>
                    <li>Then we create a variable called <b>data</b> that has <i>headers</i> and <i>rows</i>. We manually specify our <i>headers</i> as <b>Team</b> and <b>Wins</b>. The <i>rows</i> are obtained directly from the API</li>
                    <li>We use the <a href="https://www.anychart.com/blog/2017/10/25/javascript-bar-chart-tutorial/" target="_blank" rel="noopener noreferrer">Any Chart</a> reference to create a chart, provide it data, give a title, tell where the container is (in this case our {'<div>'} called container) and finally draw it</li>
                </ul>
                <br/>
                <p><b>5. Open up the <i>index.html</i> file in your browser</b></p>
                <br/>
                <p>And you should see a bar graph with teams and their super bowl wins</p>
                <br/>
                <figure>
                <img src="https://miro.medium.com/max/1278/1*hMsuUDDL2SJMV7qXn8Vbwg.png" height="100%" width="100%" alt="SuperBowl Winnings" />
                </figure>

                <br/>
                <h1>Deploy Chart on GitHub pages</h1>
                <br/>
                <p>Now we are going to be deploying this on a live website that can be accessed from anywhere.</p>
                <blockquote>
                    <p>And since our data is in Google Sheets, we can change it manually and our chart will update in real-time!</p>
                </blockquote>
                <p>We are going to be using GitHub pages to do this since it’s the easiest. Like the above, I will be heavily paraphrasing from the official <a href="https://pages.github.com/" target="_blank" rel="noopener noreferrer">GitHub pages tutorial</a> so feel free to check that out for a more in-depth guide</p>
                <p>Also, there are other ways to do this however I am going with the version below because of the least code being involved.</p>
                <p><b>1. Create a GitHub account if you don't have one</b></p>
                <p>Go to <a href="https://www.github.com/" target="_blank" rel="noopener noreferrer">www.github.com</a> and create an account</p>
                <p><b>2. Create a New Repository</b></p>
                <p>
                    I call mine <b><i>LiveBarGraphDemo</i></b> to keep it consistent and create it with the following settings
                </p>
                    <figure>
                <img src="https://miro.medium.com/max/800/1*zwzWAv20fgHQz8rMqU4Y1A.png" height="100%" width="100%" alt="GitHub Repo" />
                </figure>
                <p><b>3. Click on <i>upload an existing file</i>, select and commit the <i>index.html</i> file</b></p>
                <figure>
                    <img src="https://miro.medium.com/max/1094/1*tV6iiOQTPJaqpBqkm4rr4A.png" height="100%" width="100%" alt="GitHub Repo" />
                </figure>
                    <br/>
                    <figure>
                <img src="https://miro.medium.com/max/1059/1*u7d1bgNU052zqifPyRVxvw.png" height="100%" width="100%" alt="GitHub MasterBranch" />
                    </figure>
                <p><b>4. Enable GitHub Pages to track your file</b></p>
                <p>Now we are going to tell GitHub pages to deploy our file and repo online.</p>
                <p><b>Click on Settings</b></p>
                <img src="https://miro.medium.com/max/1028/1*6ItGNzwRIH3cf6Ex1kkOlg.png" height="100%" width="100%" alt="LiveDashboard_8" />
                <p>Now scroll down and under <b>Github Pages</b>, change the <b>Source</b> to <b>master branch</b></p>
                <img src="https://miro.medium.com/max/831/1*M5RLhI6TrFL0t9YC4A7AfA.png" height="100%" width="100%" alt="LiveDashboard_9" />
                <br/>
                <h1>Enjoy your live Bar Graph</h1>
                    <br/>
                <p>Your bar graph will be live at the website</p>
                <p><b>https://your-github-username.github.io/your-respository-name</b></p>
                <br/>
                <p>For example, mine is at</p>
                <p><b><a href="https://asharma327.github.io/LiveBarGraphDemo">https://asharma327.github.io/LiveBarGraphDemo</a></b></p>
                <hr/>
                <br/>
                <h1>Change Values Around</h1>
                <p>Now the fun part is that you can easily change the values in Google Sheets and see them reflected live on the chart on your website.</p>
                <p>This is how the graph looks now:</p>
                <br/>
                <figure>
                <img src="https://miro.medium.com/max/1278/1*hMsuUDDL2SJMV7qXn8Vbwg.png" height="100%" width="100%" alt="Superbowl Bar Graph" />
                </figure>
                <p>I am going to change the <b>New York Jets</b> to have 20 Super Bowl Wins in the Google Sheets</p>
                <br/>
                <img src="https://miro.medium.com/max/327/1*fbrjL8PLsDuxEGGLxTL-XA.png" height="40%" width="40%" alt="Google_Sheet" />
                <p>Now when I view the website, the scale has changed and the Jets bar has the most wins!</p>
                <br/>
                <img src="https://miro.medium.com/max/1273/1*2MEA-Gblyy_zRLGr79i29Q.png" height="100%" width="100%" alt="Superbowl_Bar" />
                <br />
                <h2>Next Steps</h2>
                <p>With a bit more fluency in HTML, CSS and Javascript you can create a full live dashboard using Google Sheets as your data source.</p>
                <br/>
                <p>If you have any further questions, feel free to email us at <a href="mailto:info@lovespreadsheets.com">info@lovespreadsheets.com!</a> We can’t wait to see what you build.</p>
                <p>Checkout our other <a href="/tutorials">tutorials! </a> </p>
                <CommentSection commentDiv={<div className="fb-comments" data-href="https://www.apispreadsheets.com/tutorials/create-live-dashboard-with-google-sheets" data-numposts="5" data-width="" />}/>

            </div>
        )
    }
}

export default LiveDashboard