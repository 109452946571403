import React, {Component} from 'react'
import FAQ from './FAQ'
import { Header, Icon } from 'semantic-ui-react'

class FAQBlock extends Component {
    render() {
        const FAQs = this.props.FAQs.map((x, idx) =>
            <FAQ key={idx} question={x.question} answer={x.answer} image={'image' in x ? x.image : null}/>
        )
        return (
            <div>
                <Header as='h1'>
                    <Icon name={this.props.icon} />
                    <Header.Content>
                        {this.props.title}
                    </Header.Content>
                </Header>
                {FAQs}
            </div>
        )
    }
}

export default FAQBlock