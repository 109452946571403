import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {resetAllStates} from "../../Actions/ResetAllStates";
import { auth } from '../../Authentication/firebase';
import Loader from 'react-loader-spinner'

class CancelAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cancelAccountStep: 0,
            deletingAccount: false
        }

        this.cancelAccount = this.cancelAccount.bind(this);
        this.resetCancel = this.resetCancel.bind(this);
    }

    cancelAccount(e){
        const currStep = this.state.cancelAccountStep;
        const incrementStep = () => this.setState({ cancelAccountStep: currStep + 1})

        if (currStep === 2){
            this.setState({ deletingAccount: true })

            fetch(API_Root + "api/delete-free-api-customer/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token")
                })
            }).then(res =>
                {
                    this.setState({ deletingAccount: false })
                    if (res.status === 200){
                        auth.deleteUser().then(() => {
                            alert("Account Deleted")
                            auth.doSignOut()
                            this.props.resetAllStates();
                        }).catch(err => { console.log(err); this.setState({ deletingAccount: false }); incrementStep() })
                    } else {
                        incrementStep()
                    }
                }
            ).catch(err => { this.setState({ deletingAccount: false }); incrementStep() });
        } else {
            incrementStep()
        }
    }

    resetCancel(e){
        this.setState({ cancelAccountStep: 0 })
    }

    render() {
        if (this.props.pricingPlan === "free"){
            if (this.state.deletingAccount){
                return(
                    <div>
                        <Loader
                            type="TailSpin"
                            color="black"
                            height="50"
                            width="50"
                        />
                        <h4 className="thinHeading" style={{color: 'black'}}>Canceling Your Account...</h4>
                    </div>
                )
            } else {
                if (this.state.cancelAccountStep === 0){
                    return(
                        <span>
                        <Button content="Cancel My Account" color="red" icon="trash alternate outline" onClick={this.cancelAccount} />
                    </span>
                    )
                } else if (this.state.cancelAccountStep === 1){
                    return(
                        <span>
                        <h6 style={{color: 'red'}} className="thinHeading">Are you sure you want to Cancel your Account?</h6>
                        <p>All your files and API URLs will be immediately deleted. There is no coming back.</p>
                        <Button content="Yes" color="red" onClick={this.cancelAccount} icon="trash alternate outline" />
                        <Button content="No" color="green" onClick={this.resetCancel} icon="user alternate outline" />
                    </span>
                    )
                } else if (this.state.cancelAccountStep === 2){
                    return(
                        <span>
                        <h6 style={{color: 'red'}} className="thinHeading">FINAL WARNING</h6>
                        <p>Your Account, Your Files and API URLs will be IMMEDIATELY deleted and stop working.</p>
                        <p>You will not be able to Log In to your account after you cancel</p>
                        <Button content="I Want to Keep my Account" color="green" onClick={this.resetCancel} icon="user alternate outline" />
                        <Button content="I Want to Cancel my Account" color="red" onClick={this.cancelAccount} icon="trash alternate outline" />
                    </span>
                    )
                } else{
                    return(
                        <span>
                        <h5 className="thinHeading" style={{color: 'red'}}>
                            There was a problem canceling your account.
                        </h5>
                        <h5 className="thinHeading">
                            Email us at <a href="mailto:cancel@apispreadsheets.com?Subject=API%20Spreadsheets%Cancel%20Account" style={{color: 'red'}}> cancel@apispreadsheets.com</a> from your account email to cancel your account.
                        </h5>
                    </span>
                    )
                }
            }
        }
        else {
            return (
                <span>
                    <h5 className="thinHeading">
                        Email us at <a href="mailto:cancel@apispreadsheets.com?Subject=API%20Spreadsheets%Cancel%20Account" style={{color: 'red'}}> cancel@apispreadsheets.com</a> from your account email.
                    </h5>
                </span>
            )
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    resetAllStates: resetAllStates
}

export default connect(mapStateToProps, mapActionsToProps)(CancelAccount)