import React, {Component} from 'react'
import {connect} from 'react-redux'
import DropboxVisual from './DropboxVisual'
import {toggleModal} from "../Actions/ToggleModal";

class DropboxNonAuth extends Component {
    constructor(props) {
        super(props);

        this.authUser = this.authUser.bind(this);
    }

    authUser(){
        this.props.toggleModal("needAuthModal")
    }


    render() {
        return (
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <div onClick={this.authUser}>
                        <DropboxVisual/>
                    </div>
                </div>
                <div className="col-sm-1" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(DropboxNonAuth)