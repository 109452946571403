import React, {Component} from 'react'
import SpreadsheetNumber from "./SpreadsheetNumber";
import { Button, Icon } from 'semantic-ui-react';
import CTAColGrid from "./CTAColGrid";

class CTA extends Component {
    constructor(props){
        super(props);

        this.goToSignUpPage = this.goToSignUpPage.bind(this);
    }

    goToSignUpPage(e){
        this.signUpLink.click();
    }

    render() {
        return (
            <div className="featureModalSlide">
                <div className="featureCTAHeroGrid">
                    <div className="featureCTAHeroGridCol1">
                        <CTAColGrid position="left"/>
                    </div>
                    <div className="featureCTAHeroGridCol2">
                        <div className="featureCTAHero">
                            <div className="featureCTAHeroText">
                                <h1>Currently Serving</h1>
                                <SpreadsheetNumber/>
                                <h1>APIs to Developers Worldwide</h1>
                            </div>
                        </div>
                        <div className="featureCTASignUp">
                            <a style={{display: 'none'}}
                               ref={signUpLink => this.signUpLink = signUpLink}
                               href="/signup"
                            >Contact Us Email</a>
                            <Button color="green" size="massive" id="featureCTASignUpButton" onClick={this.goToSignUpPage}>
                                Sign Up Free
                                <Icon name='right arrow' />
                            </Button>
                        </div>
                        <div className="featureCTAKnowMore">
                            <h6>Want to know more?</h6>
                            <span><a href="/features">Check out all the features</a></span>
                            <br/>
                        </div>
                    </div>
                    <div className="featureCTAHeroGridCol3">
                        <CTAColGrid position="right"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default CTA