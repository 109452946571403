import React, {Component} from 'react'
import DropboxVisual from "./DropboxVisual";
import { connect } from 'react-redux'
import {push} from "react-router-redux";
import {modifyIntegrationAuth} from "../Actions/ModifyIntegrationAuth";
import API_Root from "../Constants/API_Root";

class DropboxConnect extends Component {
    constructor(props){
        super(props);

        this.state = {
            redirectURL: window.location.href.includes("localhost") ? "http://localhost:5000" : "https://www.apispreadsheets.com"
        }

    }

    componentWillMount(){
        const url = window.location.href;

        const syncError = () => {
            this.props.toggleModal(null);
            this.props.toggleToast({show: true, message: "There was an error syncing your DropBox file! Try again or ask for Support if the error persists.", type: "error"});
        }

        if (url.includes("code")) {
            const code = url.substr(url.indexOf("code=") + 5, url.length)

            fetch('https://api.dropbox.com/oauth2/token', {
                method: 'POST',
                headers: {
                    Authorization: "Basic NXdrc2EwamlteWxncjl6OmdzeGx1NThoZm5nczZmeQ==",
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                    // "5wksa0jimylgr9z": "gsxlu58hfngs6fy",
                    // "client_id": "5wksa0jimylgr9z",
                    // "client_secret": "gsxlu58hfngs6fy",
                // })
                body: "code=" + code + "&grant_type=authorization_code&redirect_uri=" + this.state.redirectURL
            }).then((res) => {
                    res.json().then(
                        (data) => {
                            fetch(API_Root + "api/api-store-dropbox-auth/",
                                {
                                    method: 'POST',
                                    headers: {
                                        Accept: 'application/json, text/plain, */*',
                                    },
                                    body: JSON.stringify({
                                        email: localStorage.getItem("email"),
                                        token: localStorage.getItem("token"),
                                        dropbox: data,
                                    })
                                }).then((res) =>
                                    {
                                        if (res.status === 200){
                                            const currIntegration = JSON.parse(JSON.stringify(this.props.integrationAuth));

                                            currIntegration.dropbox = true;

                                            this.props.modifyIntegrationAuth(currIntegration);

                                            this.props.navigateTo("/")
                                        } else{
                                            syncError()
                                        }
                                    }).catch((err) => {syncError(); console.log(err)})
                    }).catch((err) => {syncError(); console.log(err)})
                }
            ).catch((err) => {syncError(); console.log(err)})
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <a href={"https://www.dropbox.com/oauth2/authorize?client_id=5wksa0jimylgr9z&response_type=code&redirect_uri=" + this.state.redirectURL}>
                        <DropboxVisual/>
                    </a>
                </div>
                <div className="col-sm-1" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    integrationAuth: state.mainState.integrationAuth
});

const mapActionsToProps = {
    modifyIntegrationAuth: modifyIntegrationAuth,
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(DropboxConnect)