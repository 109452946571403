import React, {Component} from 'react'
import { connect } from 'react-redux'
import API_Root from "../Constants/API_Root";
import { toggleModal } from "../Actions/ToggleModal";
import constants from "../Constants/constants";
import {toggleProBlock} from "../Actions/ToggleProBlock";
import FileDrop from 'react-file-drop';
import UploadIndicator from "./Indicators/UploadIndicator";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import copyArray from "../Constants/copyArray";
import {modifyStatus} from "../Actions/ModifyStatus";
import ReadingIndicator from './Indicators/ReadingIndicator'
import MergeIndicator from './Indicators/MergeIndicator'
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifyFinalColumnNames} from "../Actions/ModifyFinalColumnNames";
import {modifyFinalColumnPK} from "../Actions/ModifyFinalColumnPK";
import copyObject from "../Constants/copyObject";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import bytesToMB from "../Constants/bytesToMB";
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import isObjectEmpty from "../Constants/isObjectEmpty";
import {modifyCustomContactFiles} from "../Actions/ModifyCustomContactFiles";
import {modifyUpgradeText} from "../Actions/ModifyUpgradeText";
import { toggleToast } from "../Actions/ToggleToast";
import {initUploadedPK} from "../Actions/InitUploadedPK";
import {removePKFromArray} from "../Constants/removePKFromArray";
import {isBrowser} from 'react-device-detect'

class UploadFilesIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null
        };

        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.getStatusAndResults = this.getStatusAndResults.bind(this);
    }


    handleFileUpload(droppedFiles, e){
        // If files are being dropped vs. uploaded
        const files = droppedFiles === null ? e.target.files : droppedFiles;

        const formData = new FormData();

        let customContactFiles  = [];

        for (let i=0; i<files.length; i++){
            const fileObj = files[i];

            if (bytesToMB(fileObj.size) > 20){
                customContactFiles.push(fileObj.name)
            }
            else {
                formData.append("file" + i.toString(), fileObj);
            }
        }

        this.props.modifyCustomContactFiles(customContactFiles);

        formData.append("email", localStorage.getItem("email"));
        formData.append("token", localStorage.getItem("token"));

        this.props.modifyStatus("reading");

        fetch(API_Root + 'api/save-api-files/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: formData
        }).then(res => res.json())
            .then(data => {
            if (!data.error){
                // this.getFilePKs(data.taskID)

                // Modify the File Primary Keys
                let filePK = JSON.parse(JSON.stringify(data.filePK));
                let uploadedPK = JSON.parse(JSON.stringify(data.filePK));

                if (this.props.filePK.length > 0){
                    filePK = copyArray(this.props.filePK, data.filePK);
                }

                if (this.props.uploadedPK.length > 0){
                    uploadedPK = data.filePK.concat(this.props.uploadedPK)
                }

                this.props.modifyStatus("saved");
                this.props.initUploadedPK(uploadedPK)
                this.props.modifyFilePK(filePK);

                // Open up WebSocket when files are saved
                this.getStatusAndResults(data.filePK);
            }
            else{
                this.props.modifyStatus("upload");
                if ('message' in data){
                    this.props.toggleModal(null)
                    this.props.modifyUpgradeText(data.message)
                    this.props.toggleModal("upgradeModal")
                }
                else{
                    this.props.toggleToast({show: true, message: "There was an error uploading your file! Please try again!", type: "error"})
                }
            }
        }).catch((err) => {
            this.props.toggleToast({show: true, message: "There was an error uploading your file! Please try again!", type: "error"})
            this.props.modifyStatus("upload");
        }).catch((err => {
            this.props.toggleToast({show: true, message: "There was an error uploading your file! Please try again!", type: "error"})
            this.props.modifyStatus("upload");
        }));
    }

    getStatusAndResults(filePK){
        // Open up WebSockets
        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/read-api-files/");

        const props = this.props;

        // Offset to get created dt
        const current = new Date();
        const utcOffset = current.getTimezoneOffset();

        // Send the PKs and if any column names
        socket.onopen = function(event) {
            socket.send(JSON.stringify({
                utcOffset: utcOffset,
                filePK: filePK,
                sheetRead: props.sheetRead,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token")
            }));
        };

        socket.onmessage = function(event) {
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            if (status === "read"){
                props.modifyStatus(status);
                let filePKProBlock = reply['filePKProBlock'];

                if (typeof props.filePKProBlock !== 'undefined'){
                    if (!isObjectEmpty(props.filePKProBlock)){
                        filePKProBlock = copyObject(props.filePKProBlock, reply['filePKProBlock']);
                    }
                }

                props.modifyFilePKProBlock(filePKProBlock);
            }

            if (status === "api"){
                props.modifyStatus(status);
                let fileInformation = reply['fileInformation'];
                let sheetRead = reply['sheetRead'];

                if (typeof props.fileInformation !== 'undefined'){
                    if (Object.entries(props.fileInformation).length !== 0 && props.fileInformation.constructor === Object){
                        fileInformation = copyObject(props.fileInformation, reply.fileInformation);
                        sheetRead = copyObject(props.sheetRead, reply.sheetRead);
                    }
                }

                props.modifyFileInformation(fileInformation);
                props.modifySheetRead(sheetRead);

                if (isBrowser){
                    if (props.showingTutorial){
                        if (props.tutorialStep === "userPreference"){
                            props.toggleModal("quickStartModal")
                        }
                    }
                    else{
                        props.toggleModal(null)
                    }
                }

                socket.close()
            }

            if (status === "error"){
                this.props.initUploadedPK(removePKFromArray(this.props.uploadedPK, filePK))
                this.props.modifyFilePK(removePKFromArray(this.props.filePK, filePK));
                const erorrMessage = 'errorMessage' in reply ? reply['errorMessage'] : "There was an error reading your file :( Please try again"
                props.toggleToast({show: true, message: erorrMessage, type: "error"})
                socket.close()
            }
        };
    }

    render() {
        // Hides the Input Button
        const inputFileStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        };

        // Valid extensions for the file upload
        const acceptExtensions = constants.extensions.join(",");

        // Components for what status the job is in
        const indicators = {
            upload: <UploadIndicator status="upload"/>,
            reading: <ReadingIndicator percentage={0} />,
            saved: <ReadingIndicator percentage={45} />,
            read: <MergeIndicator percentage={90}/>,
            api: <UploadIndicator status="merged"/>
        };

        return (
            <div>
                <div className="row">
                    <input
                        type="file"
                        name="inputFileUpload"
                        id="inputFileUpload"
                        className="form-control-file"
                        onChange={(e) => this.handleFileUpload(null, e)}
                        style={inputFileStyle}
                        multiple={true}
                        accept={acceptExtensions}
                    />
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                        <div className="uploadOuterSection">
                            <label className="uploadInnerSection" htmlFor="inputFileUpload">
                                <FileDrop draggingOverTargetClassName="lightgray" onDrop={this.handleFileUpload}>
                                    <div className="uploadLabel">
                                        {indicators[this.props.status]}
                                    </div>
                                </FileDrop>
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-1" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    finalColumnNames: state.mainState.finalColumnNames,
    fileInformation: state.mainState.fileInformation,
    finalColumnPK: state.mainState.finalColumnPK,
    status: state.mainState.status,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock,
    uploadedPK: state.mainState.uploadedPK,
    showingTutorial: state.mainState.showingTutorial,
    tutorialStep: state.mainState.tutorialStep
});

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleProBlock: toggleProBlock,
    modifyFilePK: modifyFilePK,
    modifyStatus: modifyStatus,
    modifyFileInformation: modifyFileInformation,
    modifyFinalColumnNames: modifyFinalColumnNames,
    modifyFinalColumnPK: modifyFinalColumnPK,
    modifySheetRead: modifySheetRead,
    modifyFilePKProBlock: modifyFilePKProBlock,
    modifyCustomContactFiles: modifyCustomContactFiles,
    modifyUpgradeText: modifyUpgradeText,
    toggleToast: toggleToast,
    initUploadedPK: initUploadedPK
};

export default connect(mapStateToProps, mapActionsToProps)(UploadFilesIndex)