import React, {Component} from 'react'
import ModalApp from './Modal'
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import pricing from "../Constants/Pricing";
import capitalizeHyphenated from "../Constants/capitalizeHyphenated";
import { Button, Icon } from 'semantic-ui-react'
import {modifyPaymentModalOrigin} from "../Actions/ModifyPaymentModalOrigin";

class UpgradeModal extends Component {
    constructor(props) {
        super(props);

        this.getTierIdx = this.getTierIdx.bind(this);
        this.openPaymentModal = this.openPaymentModal.bind(this);
    }

    getTierIdx(){
        for (let i=0; i<pricing.length; i++){
            if (pricing[i].name === this.props.tier){
                return i
            }
        }

        return 0
    }

    openPaymentModal(){
        this.props.toggleModal(null);
        this.props.modifyPaymentModalOrigin(null);
        this.props.toggleModal("paymentModal")
    }

    render() {
        let body = null;
        let header = null;

        if (this.props.upgradeText !== ""){
            const tierIdx = this.getTierIdx();
            const tierName = this.props.tier !== null ? capitalizeHyphenated(this.props.tier) : "";

            const message = {
                "over_file_limit":
                    {
                        header: "",
                        body: "You have exceeded the limit of " + pricing[tierIdx].files.toString() + " files under the " + tierName + " plan"
                    }
            }

            body =
                <div style={{textAlign: 'center'}}>
                    <h3><span style={{color: 'red', paddingRight: '5px'}}><Icon name="warning sign" /></span> {message[this.props.upgradeText].body}</h3>
                    <br/>
                    <Button color="green"
                            onClick={this.openPaymentModal}
                            content={<h4>Upgrade <span role="img" aria-label="smile" style={{paddingLeft: '5px'}}>😀</span></h4>} />
                    <br/><br/>
                </div>

            header = message[this.props.upgradeText].header
        }




        return (
            <ModalApp name="upgradeModal"
                      header={header}
                      body={body}
                      size="medium"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    upgradeText: state.mainState.upgradeText,
    tier: state.mainState.tier
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(mapStateToProps, mapActionsToProps)(UpgradeModal)