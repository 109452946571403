import React, {Component} from 'react'
import { Dropdown } from 'semantic-ui-react'

class PaymentPlanSelection extends Component {
    constructor(props) {
        super(props);

        this.updatePlan = this.updatePlan.bind(this);
    }

    updatePlan(event, { value }){
        this.props.updatePlan(value);
    }

    render() {
        const planOptions = [
            {key: 1, value: "1", text: "Pro: 10,000 Rows & 10,000 Requests/Day"},
            {key: 2, value: "2", text: "Team: 50,000 Rows & 50,000 Requests/Day"},
        ]

        // if ('addFree' in this.props){
        //     planOptions.unshift({key: 0, value: "0", text: this.getPaymentPlanText(0)})
        // }
        //
        // if ('addEnterprise' in this.props){
        //     planOptions.push({key: 5, value: "5", text: this.getPaymentPlanText(5)})
        // }

        return (
            <Dropdown options={planOptions}
                      onChange={this.updatePlan}
                      selection
                      value={this.props.plan}
                      fluid />
        )
    }
}

export default PaymentPlanSelection