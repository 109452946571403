import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {toggleModal} from "../Actions/ToggleModal";
import {modifyEditID} from "../Actions/ModifyEditID";
import isObjectEmpty from "../Constants/isObjectEmpty";
import FileSummary from './FileSummary'
import FilesFilterSortIndex from "./FilesFilterSort/FilesFilterSortIndex";
import { isBrowser } from 'react-device-detect'

class FileInformationTable extends Component {
    constructor(props){
        super(props);

        this.editAll = this.editAll.bind(this);
        // Change this with excel sheet
        // this.shouldDisplayTable = this.shouldDisplayTable.bind(this);
    }

    editAll(e){
        this.props.toggleModal("editModal");
        this.props.modifyEditID("all");
    }

    render() {
        let table = null;

        if (!isObjectEmpty(this.props.fileInformation)){
            if ((this.props.tableType !== "all" && this.props.uploadedPK.length > 0) || this.props.tableType === "all"){
                let fileDropSources = [];
                // Get file names to check whether all extensions are the same
                let fileNames = [];

                // Grid column length
                const colLengths = ["6","3", "1", "2"];

                const initPKList = this.props.tableType === 'all' ? this.props.filePK : this.props.uploadedPK

                for (let i=0; i<initPKList.length; i++){
                    const pkID = initPKList[i]['id'];
                    const pk = initPKList[i]['pk'];

                    if (pk in this.props.fileInformation || pkID in this.props.fileInformation){
                        fileDropSources.push(
                            <FileSummary    idx={i} pkID={pkID}
                                            pk={pk}
                                            key={pk.toString() + i.toString()}
                                            colLengths={colLengths}
                                            textPadding="10"
                                            tableType={this.props.tableType}
                            />
                        );

                        fileNames.push(this.props.fileInformation[pkID].name);
                    }
                }

                let headerTitle = null;
                let fileTable;

                let fileFilterIndex = null;
                let fileNumDisplay = null;
                if (this.props.tableType === "all"){
                    headerTitle = "All Your Files and APIs"
                    fileFilterIndex = <FilesFilterSortIndex/>
                    const totalNumber = this.props.filePK.length > this.props.originalFilePK.length ? this.props.filePK.length : this.props.originalFilePK.length
                    fileNumDisplay = <small><i>Showing {this.props.filePK.length} of {totalNumber} files</i></small>
                }
                else{
                    headerTitle = "Files Selected"
                }

                let header =
                <div style={{textAlign: 'center'}}>
                    <h2 className="thinHeading"><Icon name="file alternate" />{headerTitle} <Icon name="code" /></h2>
                </div>

                fileTable = fileDropSources;

                table =
                <div style={{
                        // backgroundColor: '#F0F0F0',
                        // border: '1px solid #F0F0F0',
                        // borderRadius: '5px',
                        marginLeft: isBrowser ? "30px" : "10px",
                        marginRight: isBrowser ? "30px" : "10px"
                }} className="row">
                    <div className="col-sm-12">
                        {header}
                        <br/><br/>
                        {fileFilterIndex}
                        {fileNumDisplay}
                        {fileTable}
                    </div>
                </div>
            }
        }


        return (
            <div>
            {table}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation,
    uploadedPK: state.mainState.uploadedPK,
    originalFilePK: state.mainState.originalFilePK
});

const mapActionsToProps = {
    modifyFilePK: modifyFilePK,
    toggleModal: toggleModal,
    modifyEditID: modifyEditID
};

export default connect(mapStateToProps, mapActionsToProps)(FileInformationTable)