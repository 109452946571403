import React, {Component} from 'react'
import SampleStartTable from "../SampleStartTable";
import CreateTable from "./CreateTable";
import DocsBaseURL from '../../DocsBaseURL';
import DocsPostRequest from '../../DocsPostRequest';
import DocsPostBodyParameters from "./DocsPostBodyParameters";
import DocsPostBodyReq from "./DocsPostBodyReq";
import DocsPostHeaderParameters from "../../DocsPostHeaderParameters";
import PostHeaderReq from "./PostHeaderReq";
import DocsPostResponseCode from "../../DocsPostResponseCode";

class CreateIndex extends Component {
    render() {
        return (
            <div>
                <h3 className="thinHeading"><b>Create</b> refers to creating new rows in the file</h3>
                <br/>
                <div className="row">
                    <div className="col-sm-6">
                        <SampleStartTable/>
                    </div>
                    <div className="col-sm-6">
                        <CreateTable/>
                    </div>
                </div>
                <br/><br/>
                <h3>REQUEST</h3>
                <hr/>
                <h3>1. Make a <b>POST</b> Request to the following URL</h3>
                <DocsBaseURL url="data/[fileID]/"/>
                <i>Example URL</i>
                <DocsBaseURL url="data/1/" />
                <DocsPostRequest type="url" />
                <br/>
                <hr/>
                <h3>2. Include a JSON Encoded Body that contains the Row(s)</h3>
                <br/>
                <div className="row">
                    <div className="col-sm-6">
                        <DocsPostBodyParameters/>
                    </div>
                    <div className="col-sm-6">
                        <DocsPostBodyReq/>
                    </div>
                </div>
                <br/>
                <hr/>
                <h3 style={{color: 'gray'}}>3. Add Keys in the Request Header <i>if private</i></h3>
                <br/>
                <div className="row">
                    <div className="col-sm-6">
                        <DocsPostHeaderParameters/>
                    </div>
                    <div className="col-sm-6">
                        <PostHeaderReq/>
                    </div>
                </div>
                <br/>
                <hr/>
                <br/><br/>
                <h3>RESPONSE</h3>
                <hr/>
                <span>The following response codes can be returned</span>
                <div style={{fontSize: '115%'}}>
                    <DocsPostResponseCode/>
                </div>
            </div>
        )
    }
}

export default CreateIndex