import React, {Component} from 'react'
import ModalApp from '../SharedComponents/Modal'
import EditFileBody from './EditFileBody'
import {connect} from 'react-redux'

class EditModal extends Component {
    render() {
        const header = this.props.editID === "all" ? "Read All Files With These Options" : "Read File With These Options";

        return (
            <ModalApp name="editModal"
                      header={header}
                      size="small"
                      body={<EditFileBody/>}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(EditModal)