import React, { Component } from 'react';
import NavbarIndex from './SharedComponents/Navbar/NavbarIndex';
import Routes from './Routes';
import withAuthentication from './Authentication/withAuthentication';
import FooterIndex from './SharedComponents/Footer/FooterIndex'
import FeedbackModal from './SharedComponents/Feedback/FeedbackModal'
import LoadingModal from './SharedComponents/LoadingModal'
import EditModal from './EditFile/EditModal'
import ImportSheetsModal from './ImportSheets/ImportSheetsModal'
import ForgetPasswordModal from "./Authentication/UserPages/Password/ForgetPasswordModal";
import ProfileModal from "./InfoPages/Profile/ProfileModal";
import ShareModal from './FileDnD/ShareModal'
import PaymentModal from "./SharedComponents/Payment/PaymentModal";
import ContactUsModal from "./InfoPages/ContactUs/ContactUsModal";
import SupportModal from "./SharedComponents/Support/SupportModal";
import HowItWorksModal from "./InfoPages/HowItWorks/HowItWorksModal";
import NeedAuthModal from './Authentication/UserPages/NeedAuthModal';
import AnalyticsModal from "./Analytics/AnalyticsModal";
import ColumnNamesModal from "./FileDnD/ColumnNames/ColumnNamesModal";
import FileDescriptionModal from "./FileDnD/FileDescription/FileDescriptionModal";
import UpgradeModal from './SharedComponents/UpgradeModal'
import QueryModal from "./InfoPages/Query/FullTutorial/QueryModal";
import QueryQuickstartModal from "./InfoPages/Query/Quickstart/QueryQuickstartModal";
import KeysModal from "./FileDnD/KeysModal/KeysModal";
import ImageModal from "./SharedComponents/ImageModal/ImageModal"
import CustomToastContainer from "./SharedComponents/CustomToastContainer"
import CRUDModal from "./InfoPages/Features/FeaturesBody/CRUDModal";
import QuickstartModal from "./InfoPages/QuickstartModal/QuickstartModal";
import GoogleSheetsTokenErrorModal from "./InfoPages/GoogleSheetsTokenErrorModal";
import FixColumnNamesModal from "./FileDnD/FixColumnNames/FixColumnNamesModal"
import LoginModal from "./SharedComponents/LoginModal";
import GoogleSheetsErrorModal from "./InfoPages/GoogleSheetsErrorModal";

class AuthenticatedApp extends Component {
    render() {
        // TODO: Dark Mode would be easier to set up here
        return (
            <div>
                <GoogleSheetsErrorModal/>
                <KeysModal/>
                <HowItWorksModal/>
                <FeedbackModal/>
                <ImportSheetsModal/>
                <EditModal />
                <ShareModal />
                <FileDescriptionModal/>
                <QueryModal/>
                <QueryQuickstartModal/>
                <ImageModal />
                <CRUDModal/>
                <GoogleSheetsTokenErrorModal/>
                <LoadingModal name="loadingModalDropboxRefresh" header="Refreshing your Dropbox file..." message="Refreshing your Dropbox file..."/>
                <LoadingModal name="loadingModalGoogleSheetsRefresh" header="Refreshing your Google Sheets file..." message="Refreshing your Google Sheets file..."/>
                <LoadingModal name="loadingModalDropbox" header="Syncing your Dropbox file..." message="Syncing your Dropbox file..."/>
                <LoadingModal name="loadingModalGoogleSheets" header="Syncing your Google Sheets file..." message="Syncing your Google Sheets file..."/>
                <LoadingModal name="loadingModalFile" header="Preparing your Merged File" message="Preparing your Merged File" noClose/>
                <LoadingModal name="loadingModalImportSheets" header="Reading..." message="Reading Your Additional Sheets" noClose/>
                <LoadingModal name="loadingModalEditFile" header="Re-reading your files..." message="Re-Reading Files with Options" noClose/>
                <UpgradeModal />
                <LoginModal/>
                <ForgetPasswordModal/>
                <NeedAuthModal />
                <ProfileModal/>
                <PaymentModal/>
                <ContactUsModal/>
                <QuickstartModal/>
                <SupportModal/>
                <AnalyticsModal/>
                <ColumnNamesModal/>
                <FixColumnNamesModal />
                    <div>
                        <CustomToastContainer />
                        <NavbarIndex/>
                        <Routes/>
                        <FooterIndex/>
                    </div>
            </div>
        );
    }
}


export default withAuthentication(AuthenticatedApp)
