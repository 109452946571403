import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../Actions/ToggleModal";
import API_Root from "../Constants/API_Root";
import {toggleToast} from "../Actions/ToggleToast";

class DownloadFileButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloadFileURL: '',
            fetching: false
        }

        this.downloadFile = this.downloadFile.bind(this);
        this.openFile = this.openFile.bind(this);
    }

    downloadFile(e){
        this.setState({
            fetching: true
        })

        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/download-api-file/");

        socket.onopen = (event) => {
            socket.send(JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                pk: this.props.pk
            }))
        }

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            const downloadURL = reply['downloadURL'];

            if (downloadURL === null){
                this.setState({
                    fetching: false
                })
                this.props.toggleToast({show: true, message: "Sorry there was an error downloading your file :(", type: "error"})
            }
            else{
                this.setState({
                    fetching: false,
                    downloadFileURL: downloadURL
                })

                this.downloadLink.click()
            }

            socket.close()
        }

    }

    openFile(e){
        this.downloadLink.click()
    }

    render() {
        const downloadURL = this.state.downloadFileURL;
        const inputType = 'inputType' in this.props.fileInformation[this.props.pkID] ? this.props.fileInformation[this.props.pkID]['inputType'] : null;

        if (inputType.includes("file")){
            return (
                <div>
                    <a style={{display: 'none'}} ref={downloadLink => this.downloadLink = downloadLink}
                       href={downloadURL}>Download hidden</a>
                    <Button loading={this.state.fetching} content="Download File" basic color='green' icon='download' onClick={this.downloadFile} fluid/>
                </div>
            )
        }
        else if (inputType.includes("googleSheets")){
            const url = this.props.fileInformation[this.props.pkID]['fileURL']
            return (
                <div>
                    <a style={{display: 'none'}} ref={downloadLink => this.downloadLink = downloadLink}
                       href={url} target='_blank' rel="noopener noreferrer">Download hidden</a>
                    <Button content="Open File" basic color='green' icon='folder open outline' onClick={this.openFile} fluid/>
                </div>
            )
        }
        else if (inputType.includes("dropbox")){
            const url = this.props.fileInformation[this.props.pkID]['fileURL']
            return(
                <div>
                    <a style={{display: 'none'}} ref={downloadLink => this.downloadLink = downloadLink}
                       href={url}>Download hidden</a>
                    <Button content="Download File" basic color='green' icon='download' onClick={this.openFile} fluid/>
                </div>
            )
        }
        else{
            return(
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(DownloadFileButton)