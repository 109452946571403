import React, {Component} from 'react'
import DocsBaseURL from '../../DocsBaseURL'
import DocsGetURLParameters from "./DocsGetURLParameters";
import DocsGetResponse from "./DocsGetResponse";
import DocsGetResponseCode from "../../DocsGetResponseCode";

class ReadIndex extends Component {
    render() {
        let baseGetURL = 'data/[fileID]/?query=[query]&dataFormat=[dataFormat]&limit=[limit]&accessKey=[accessKey]&secretKey=[secretKey]/';

        return (
            <div>
                <h3 className="thinHeading"><b>Read</b> refers to reading rows from your file</h3>
                <br/><br/>
                <h3>REQUEST</h3>
                <hr/>
                <h3>Make a <b>GET</b> Request to the following URL</h3>
                <DocsBaseURL url={baseGetURL} />
                <i>Example URL</i>
                <DocsBaseURL url="data/184/?query=select*from184wherecol_name=123&dataFormat=matrix&limit=10&accessKey=12345678901234567890123456789012&secretKey=12345678901234567890123456789012/" />
                <br/>
                <DocsGetURLParameters/>
                <br/>
                <hr/>
                <br/><br/>
                <h3>RESPONSE</h3>
                <hr/>
                <h3>The response contains your <code>data</code></h3>
                <div className="row">
                    <div className="col-sm-6">
                        <DocsGetResponse/>
                    </div>
                    <div className="col-sm-6">
                        <div style={{fontSize: '115%'}}>
                            <DocsGetResponseCode/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReadIndex