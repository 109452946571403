import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Input } from 'semantic-ui-react'

class FileNameSearch extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e){
        this.props.changeState("searchName", e.target.value)
    }

    render() {
        return (
            <Input
                icon='search'
                placeholder="Search File Name..."
                loading={this.props.isFiltering}
                fluid
                onChange={this.handleInputChange}
                value={this.props.searchName}
            />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FileNameSearch)