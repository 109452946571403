import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react';

class UploadIndicator extends Component {
    render() {
        let heading = "Upload or Drop Files";
        let firstIconName = "file excel outline";

        if (this.props.status === "merged"){
            heading = "Upload More Files";
            firstIconName = "plus square outline"
        }

        return (
            <div>
                <span className="uploadIcons">
                    <Icon name={firstIconName} />
                    <Icon name="file alternate outline" />
                </span>
                <h4 className="thinHeading">{heading}</h4>
            </div>
        )
    }
}

export default UploadIndicator