import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'
import SampleTable from "./SampleTable";
import DocsBaseURL from "../../InfoPages/DocsPage/DocsBaseURL"
import {generateRandomKey} from "../../Constants/generateRandomKey";
import TogglePrivate from "./Read/TogglePrivate";
import GuideDetails from "../GuideDetails";
import {createFileInformation} from "./Functions/createFileInformation";
import {createFullURLFromBase} from "../../Constants/createFullURLFromBase";
import {createCodeSnippets} from "./Functions/createCodeSnippets";
import CodeSnippet from "../../FileDnD/CodeSnippet";
import BetaMessage from "../../SharedComponents/BetaMessage";

class DeleteRows extends Component {
    constructor(props){
        super(props);

        this.state = {
            isPrivate: false,
            sampleTableData: [
                [1, "Justin Trudeau", 252069.46, "Canada"],
                [2, "Jacinda Ardern", 339862.25, "New Zealand"],
                [3, "Boris Johnson", 187033.75, "UK"],
                [4, "Joko Widodo", 27200.8, "Indonesia"],
                [5, "Angela Merkel", 369727.4, "Germany"]
            ],
            deletedData: [
                [3, "Boris Johnson", 187033.75, "UK"],
                [4, "Joko Widodo", 27200.8, "Indonesia"],
            ],
            sampleTableHeaders: ["id", "name", "salary_$", "country"],
            sampleDataType: ["num", "str", "num", "str"],
            dataFormat: "row"
        }

        this.togglePrivate = this.togglePrivate.bind(this);
        this.modifyURLIfPrivate = this.modifyURLIfPrivate.bind(this);
        this.changeState = this.changeState.bind(this);
    }

    togglePrivate(e){
        this.setState({ isPrivate: !this.state.isPrivate })
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    modifyURLIfPrivate(url){
        if (this.state.isPrivate){
            const keyText = "accessKey=" + generateRandomKey(32) + "&secretKey=" + generateRandomKey(32)
            if (url.indexOf("?") === -1){
                url += "?" + keyText
            }
            else{
                url += "&" + keyText
            }
        }

        url += "/"

        return url
    }

    render() {
        // POST: Orange
        // GET: Yellow
        let baseGetURL = 'data/[fileID]/?&query=[query]&accessKey=[accessKey]&secretKey=[secretKey]/';

        const deleteSomeRowsURL = this.modifyURLIfPrivate('data/812/?query=DELETEFROM812WHEREsalary_$>=250000');
        const deleteAllRowsURL = this.modifyURLIfPrivate('data/812/?query=DELETEFROM812');

        const sampleFileInformationSomeRows = createFileInformation(this.state.deletedData, this.state.sampleTableHeaders, this.state.sampleDataType, 812, "Sample Data - World Leaders", this.state.isPrivate, createFullURLFromBase(deleteSomeRowsURL))
        const sampleFileInformationAllRows = createFileInformation(this.state.deletedData, this.state.sampleTableHeaders, this.state.sampleDataType, 812, "Sample Data - World Leaders", this.state.isPrivate, createFullURLFromBase(deleteAllRowsURL))

        const someRowsCode = createCodeSnippets(sampleFileInformationSomeRows, "deleteAction", createFullURLFromBase(deleteSomeRowsURL));
        const allRowsCode = createCodeSnippets(sampleFileInformationAllRows, "deleteAction", createFullURLFromBase(deleteAllRowsURL));

        return (
            <div>
                <BetaMessage/>
                <h2>Make a GET request to the following URL</h2>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={baseGetURL} />
                <br/>
                <p>Deleting is similar to Reading rows. Using the <code>query</code> parameter with a valid <b>Delete</b> query like <code>DELETEFROMfileIDWHEREfilterClause</code> will delete the rows that your query matches.</p>
                <br/><br/>
                <h2>Let's look at some examples</h2>
                <br/>
                <h4>Deleting Some Rows <TogglePrivate isPrivate={this.state.isPrivate} togglePrivate={this.togglePrivate}/></h4>
                <p>Make a GET request to the URL with your <code>fileID</code> and valid Delete <code>query</code>. You can learn more about writing a valid query <a href={"/guides/" + GuideDetails[7].title}>here</a></p>
                <br/>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={deleteSomeRowsURL} />
                <CodeSnippet
                    code={someRowsCode}
                    height={100}
                />
                <br/>
                <div className="row">
                    <div className="col-sm-6">
                        <SampleTable
                            tableData={this.state.sampleTableData}
                            tableHeaders={this.state.sampleTableHeaders}
                            header="Original Data"
                        />
                    </div>
                    <div className="col-sm-6">
                        <SampleTable
                            tableData={this.state.deletedData}
                            tableHeaders={this.state.sampleTableHeaders}
                            header="Data After Rows Being Deleted"
                        />
                    </div>
                </div>
                <br/><br/><br/><br/>
                <h4>Deleting All Rows <TogglePrivate isPrivate={this.state.isPrivate} togglePrivate={this.togglePrivate}/></h4>
                <p>The <code>query</code> in this case will NOT contain a <b>filterClause</b>, i.e. <code>WHEREcol_1='some_value'</code></p>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={deleteAllRowsURL} />
                <CodeSnippet
                    code={allRowsCode}
                    height={100}
                />
                <br/>
                <p>ALL Rows will be deleted from your file</p>
                <h5 style={{color: 'red'}}>USE THE DELETE ACTION WITH CAUTION AS YOU CAN'T REVERSE IT</h5>
                <br/>
            </div>
        )
    }
}

export default DeleteRows