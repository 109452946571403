import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import {modifyFileURL} from "../Actions/ModifyFileURL";
import { Button, Label } from 'semantic-ui-react';
import {getFileURL} from "../Constants/getFileURL";
import {toggleToast} from "../Actions/ToggleToast";

class FileURL extends Component {
    constructor(props){
        super(props);

        this.copyURL = this.copyURL.bind(this);
    }

    copyURL(e){
        // e.target.focus();
        this.mainUrlRef.select();
        document.execCommand("copy")
        window.getSelection().removeAllRanges();

        this.props.toggleToast({show: true, message: "URL Copied!", type: "success"})
    }

    render() {
        const url = getFileURL(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.outputFormat, this.props.apiType, this.props.rowLen, this.props.rows, this.props.readQuery)

        // const width = (url.length * 9).toString() + "px";
        const width = "100%"
        let color = "orange";
        let requestType = "POST"

        if (this.props.apiType === 'readAction' || this.props.apiType === 'deleteAction'){
            color = 'yellow'
            requestType = "GET"
        }

        let allClassName = "segment";
        if (["readActionParameters", "deleteActionParameters", "readAction", "createAction", "deleteAction", "updateAction"].includes(this.props.tutorialStep)){
            allClassName += " tutorialHighlighted";
        }

        return (
            <div style={{marginTop: '20px', backgroundColor: '#203354', color: 'lightgrey', borderRadius: '5px', opacity: '1'}} className={allClassName} id="fileURL">
                <h5>API URL
                    <Button content="Copy URL" inverted icon="copy" color="olive" onClick={this.copyURL} size="mini" style={{marginLeft: '10px'}}/>
                </h5>
                <div>
                    <Label color={color}>{requestType}</Label>
                </div>
                <input readOnly={true} className="wordwrap" style={{width: width, border: 'none', fontSize: '110%', backgroundColor: '#203354', color: 'white'}}
                       value={url}
                       type="text"
                       ref={(input) => {
                           this.mainUrlRef = input
                       }}/>
                <br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    tutorialStep: state.mainState.tutorialStep
});

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyFileURL: modifyFileURL,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(FileURL)