import React, {Component} from 'react'
import { Input } from 'semantic-ui-react';

class ColumnDesc extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnDesc: this.props.columnDesc
        }

        this.changeColumnDesc = this.changeColumnDesc.bind(this);
    }

    changeColumnDesc(e){
        this.props.handleInputChange(this.props.idx, "desc", e.target.value)
    }


    render() {
        return (
            <span>
                <Input fluid value={this.props.columnDesc} onChange={this.changeColumnDesc} />
            </span>
        )
    }
}

export default ColumnDesc