import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Message, Label} from 'semantic-ui-react';
import ImportSheets from './ImportSheets'
import RemoveFile from './RemoveFile';
import {toggleModal} from "../Actions/ToggleModal";
import {modifyEditID} from "../Actions/ModifyEditID";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import PrivateButton from './PrivateButton'
import KeysButton from './KeysModal/KeysButton'
import ShareButton from './ShareButton'
import FileSource from "./FileSource";


// import TestQueryButton from "./TestQueryButton"
import ColumnNames from './ColumnNames'
import FileDescriptionButton from "./FileDescription/FileDescriptionButton";
import FileOptions from "./FileOptions"
import AnalyticsButton from './AnalyticsButton'
import DownloadFileButton from './DownloadFileButton'
import ReuploadFile from './ReuploadFile'
import FileDescriptionModalBody from './FileDescription/FileDescriptionModalBody'
import FileSummaryCodeAccordion from './FileSummaryCode/FileSummaryCodeAccordion'
// import FileSummaryBaseURL from "./FileSummaryBaseURL";
import {getPricingText} from "../Constants/getPricingText";
import {isBrowser, isMobile } from 'react-device-detect'
import FixColumnNames from "./FixColumnNames/FixColumnNames"

class FileSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            outputFormat: 'jsonRow',
            apiType: 'get',
            rows: this.props.fileInformation[this.props.pkID].rowLength
        }

        this.openEditModal = this.openEditModal.bind(this);
        this.changeState = this.changeState.bind(this);
        this.openPaymentModal = this.openPaymentModal.bind(this);
    }

    openEditModal(){
        this.props.modifyEditID(this.props.pkID)
        this.props.toggleModal("editModal")
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    openPaymentModal(e){
        this.props.toggleModal("paymentModal");
    }

    render() {
        const fileInformation =  this.props.fileInformation[this.props.pkID];
        let fileName = fileInformation.name;
        // const size = formatBytes(fileInformation.size);

        const isExcel = fileName.split('.').pop() !== "csv" && fileName.split('.').pop() !== "txt";

        const sheetName = isExcel ? <span className="text-muted"><small>{this.props.sheetRead[this.props.pkID]}</small></span> : null;
        const importSheets = isExcel ? <ImportSheets idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} /> : null;

        const proBlock = this.props.filePKProBlock[this.props.pkID];

        const fileDivStyle = {
            // backgroundColor: '#F5F5F5',
            backgroundColor: '#f3f6fb',
            border: '1px solid #F0F0F0',
            // borderRadius: '5px',
            padding: '10px',
            // marginTop: '20px',
            marginBottom: '50px'
        };

        let disable = null;
        let proMessage = null;
        let fileIDLabel = <Label color="orange"><b>File ID: </b>{this.props.pk.toString()}</Label>;
        let fileSummaryAccordion = <FileSummaryCodeAccordion
            pk={this.props.pk}
            pkID={this.props.pkID}
            textPadding={this.props.textPadding}
            idx={this.props.idx}
            tableType={this.props.tableType}
        />

        if (proBlock){
            disable = {pointerEvents: 'none', opacity: '0.2'}
            proMessage = <Message
                floating={true} negative={true}
                icon='lock'
                header='File has more than 1500 records'
                content={<Button onClick={this.openPaymentModal} color="green">{"Upgrade to Pro for just " + getPricingText(this.props.currency, 1)}</Button>}
            />;
            fileIDLabel = null;
            fileSummaryAccordion = null;
        }
        else{
            if (fileInformation.fixColumnNames){
                disable = {pointerEvents: 'none', opacity: '0.2'}
                proMessage = <FixColumnNames pkID={this.props.pkID} pk={this.props.pk} idx={this.props.idx}/>
                fileIDLabel = null;
                fileSummaryAccordion = null;
            }
        }

        return (
            <div style={fileDivStyle}>
                <div style={{marginBottom: '10px'}} className="row">
                    <div className="col-sm-12">
                        {proMessage}
                    </div>
                </div>
                <div style={disable}>
                    <div className="row">
                        <div className={"col-sm-9 wordwrap"}>
                            <div className="fileNameBox">
                                <h3 style={{color: '#303030'}}>{fileName} {sheetName}</h3>
                                <FileSource fileInformation={fileInformation}/> {fileIDLabel} <Label>{fileInformation['createdDt']}</Label>
                                <br/>
                                <div style={{width: isBrowser ? '60%' : '100%', marginTop: '5px'}}>
                                    {/*<FileSummaryBaseURL url={"data/" + this.props.pk.toString() + "/"} />*/}
                                    {/*<br/>*/}
                                    <FileDescriptionModalBody pkID={this.props.pkID} pk={this.props.pk} />
                                    {isMobile ? <br/> : null}
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-3"}>
                            <PrivateButton textPadding={this.props.textPadding} pk={this.props.pk} idx={this.props.idx} pkID={this.props.pkID} />
                            <br/>
                            <div style={{float: 'right', width: '100%'}}>
                                <FileOptions
                                    // testQuery={isBrowser ? <TestQueryButton pkID={this.props.pkID} /> : null}
                                    shareButton={<ShareButton pk={this.props.pk} outputFormat={this.props.outputFormat} />}
                                    analyticsButton={<AnalyticsButton pk={this.props.pk} pkID={this.props.pkID}/>}
                                    downloadFileButton={<DownloadFileButton pk={this.props.pk} pkID={this.props.pkID}/>}
                                    reuploadFile={<ReuploadFile pk={this.props.pk} pkID={this.props.pkID} />}
                                    keysButton={<KeysButton pkID={this.props.pkID} pk={this.props.pk} />}
                                    columnNames={<ColumnNames pkID={this.props.pkID} pk={this.props.pk} />}
                                    fileDescription={<FileDescriptionButton pkID={this.props.pkID} pk={this.props.pk} />}
                                    importSheets={isBrowser ? importSheets : null }
                                    editFile={isBrowser ? <div><Button content="File Options" basic color='purple' icon='edit outline' onClick={this.openEditModal} fluid/></div> : null}
                                    removeFile={<RemoveFile idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} isExcel={isExcel} />}
                                />
                            </div>
                        </div>
                    </div>
                    <br/>
                    {
                        isBrowser ? fileSummaryAccordion : null
                    }
                    <br/>
                </div>
                {/*<div style={{marginTop: '10px'}} className="row">*/}
                    {/*<div className="col-sm-12">*/}
                        {/*{proMessage}*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock,
    currency: state.mainState.currency
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID,
    modifyFilePK: modifyFilePK
}

export default connect(mapStateToProps, mapActionsToProps)(FileSummary)