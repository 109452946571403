import React, {Component} from 'react'
import { Table } from 'semantic-ui-react'
import ColumnName from "./ColumnName";
// import ColumnTypeDropdown from "./ColumnTypeDropdown";
import ColumnDesc from "./ColumnDesc";
import {connect} from "react-redux";
import ColumnInfoSaveBox from './ColumnInfoSaveBox'
import { Icon } from 'semantic-ui-react'
import ColumnNames from "./ColumnNames.svg"
import ColumnNamesTutorial from "./ColumnNamesTutorial"

class ColumnNamesInfoBody extends Component {
    constructor(props){
        super(props);

        this.state = {
            originalColInfo: JSON.parse(JSON.stringify(this.props.fileInformation[this.props.editID].columnInformation)),
            colInfo: JSON.parse(JSON.stringify(this.props.fileInformation[this.props.editID].columnInformation)),
            colNames: JSON.parse(JSON.stringify(this.props.fileInformation[this.props.editID].columnInformation.names)),
            saving: false,
            showingColumnTransformPic: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.changeColumnName = this.changeColumnName.bind(this);
        this.discardChanges = this.discardChanges.bind(this);
        this.showColumnTransformPic = this.showColumnTransformPic.bind(this);
    }

    changeColumnName(idx, name){
        let colNames = JSON.parse(JSON.stringify(this.state.colNames));

        colNames[idx] = name

        this.setState({
            colNames: colNames
        })
    }

    handleInputChange(idx, changeType, changeValue){
        let colInfo = JSON.parse(JSON.stringify(this.state.colInfo))
        let colNames = colInfo.names;
        const currColName = colNames[idx];
        let colNameInfo = colInfo.info;
        let currColNameInfo = colNameInfo[currColName];

        if (changeType === "columnName"){
            if (changeValue !== currColName){
                colNames[idx] = changeValue
                colInfo.names = colNames;

                colNameInfo[changeValue] = currColNameInfo;

                delete colNameInfo[currColName];

                colInfo.info = colNameInfo;

                this.setState({
                    colInfo: colInfo
                })
            }
        } else {
            currColNameInfo[changeType] = changeValue
            colNameInfo[currColName] = currColNameInfo

            colInfo.info = colNameInfo;

            this.setState({
                colInfo: colInfo
            })
        }

    }

    discardChanges(){
        const prevInfo = this.state.originalColInfo;

        this.setState({
            colNames: prevInfo.names,
            colInfo: prevInfo
        })
    }

    showColumnTransformPic(e){
        const currState = JSON.parse(JSON.stringify(this.state.showingColumnTransformPic));

        this.setState({ showingColumnTransformPic: !currState })
    }

    render() {
        const colInfo = this.state.colInfo;

        const colNames = colInfo.names;
        const colNamesInfo = colInfo.info;

        let rows = [];

        for (let i=0; i<colNames.length; i++){
            const colName = colNames[i];
            const colNameInfo = colNamesInfo[colName];

            rows.push(
                <Table.Row key={i}>
                    <Table.Cell><ColumnName idx={i} changeColumnName={this.changeColumnName}  allColumnNames={colNames} allColumnNamesEdit={this.state.colNames} handleInputChange={this.handleInputChange} /></Table.Cell>
                    {/*<Table.Cell style={{color: 'gray'}}>{colNameInfo.original}</Table.Cell>*/}
                    <Table.Cell><ColumnDesc idx={i} handleInputChange={this.handleInputChange} columnDesc={colNameInfo.desc}/></Table.Cell>
                </Table.Row>
            )
        }


        return (
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <div style={{textAlign: 'center'}}>
                        <h2><Icon name="columns" /> Column Names</h2>
                        <ColumnNamesTutorial />
                        {/*<span className="linkStyle" onClick={this.showColumnTransformPic}>How are Column Names transformed <Icon name={this.state.showingColumnTransformPic ? "minus circle" : "plus circle"}/></span>*/}
                        {this.state.showingColumnTransformPic ? <img src={ColumnNames} alt="column names transform"/> : null}
                    </div>
                    <ColumnInfoSaveBox prevInfo={this.state.originalColInfo}
                    currInfo={this.state.colInfo}
                    discardChanges={this.discardChanges}
                    />
                    {/*<br/>*/}
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={6}>Name</Table.HeaderCell>
                                {/*<Table.HeaderCell width={2}>Name<br/><span className="tableHeaderDesc" style={{color: 'blue'}}> <Icon name="file alternate outline"/> File</span></Table.HeaderCell>*/}
                                <Table.HeaderCell>Description</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {rows}
                        </Table.Body>
                    </Table>
                    <br/>
                </div>
                <div className="col-sm-1" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ColumnNamesInfoBody)