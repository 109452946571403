import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../../SharedComponents/Modal"
import isObjectEmpty from "../../Constants/isObjectEmpty";
import FixColumnNamesTable from "./FixColumnNamesTable";
import { Icon } from 'semantic-ui-react';

class FixColumnNamesModal extends Component {
    render() {
        let body = null;
        if (this.props.modalInfo !== null){
            if (typeof this.props.modalInfo === "object"){
                if (!(isObjectEmpty(this.props.modalInfo))){
                    if ('infoType' in this.props.modalInfo){
                        if (this.props.modalInfo.infoType === "columnMap"){
                            body =
                                <div className="fixColumnNames">
                                    <h3 className="thinHeading"><span style={{color: 'red'}}><Icon name="warning sign" /></span> Column Names in your File were not Valid</h3>
                                    <div className="fixColumnNamesBox">
                                        <h5>Change the Column Names in your <Icon name="file alternate outline" />File and Try Again</h5>
                                        <FixColumnNamesTable columnNamesMap={this.props.modalInfo.columnNamesMap}/>
                                    </div>
                                </div>
                        }
                    }
                }
            }
        }

        return (
            <ModalApp name="fixColumnNamesModal"
                      header={null}
                      size="large"
                      body={body}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FixColumnNamesModal)