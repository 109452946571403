import React, {Component} from 'react'
import { Divider } from 'semantic-ui-react'

class DocsPostBodyParameters extends Component {
    render() {
        return (
            <div>
                <h5 id="parameterHeading">Request Body Parameters</h5>
                <Divider/>
                <span className="parameterName">data</span> <span className="parameterType">JSON Object</span>
                <span className="parameterSecondLine"><span className="parameterRequired">Required</span> </span>
                <span className="parameterDesc">The rows that you want to create. You can specify the rows in three different formats:</span>
                <ol>
                    <li><code>row</code></li>
                    <li><code>column</code></li>
                    <li><code>columnValue</code></li>
                </ol>
                <span className="parameterDesc">If you want to create more than one row, you MUST use either the <code>row</code> or <code>column</code> format</span>
                <br/><br/>
                <span className="parameterDesc">If you want to create just 1 row, you can use any format but the <code>columnValue</code> format is the simplest</span>
                <br/><br/>
                <span className="parameterDesc"><b>Examples of the different formats are on the right</b></span>
            </div>
        )
    }
}

export default DocsPostBodyParameters