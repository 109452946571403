import React, {Component} from 'react'
import ReactJson from 'react-json-view'

class ColumnNamesValid extends Component {
    render() {
        return (
            <div>
                <h2>We automatically read the first row in your File as Column Names</h2>
                <img src="https://images2.imgbox.com/d6/3f/CWvO821o_o.png" alt="Column Names in File" style={{marginBottom: '50px'}}/>
                <h2>The Column Names must be Unique</h2>
                <img src="https://images2.imgbox.com/37/04/tmiAYZoM_o.png" alt="Column Names Unique vs. Duplicate" style={{marginBottom: '50px'}}/>
                <h2>If you have duplicate Column Names, you will get an error when you upload your File</h2>
                <img src="https://images2.imgbox.com/ac/7c/mFg7eZ6n_o.png" alt="Error dashboard" style={{marginBottom: '50px'}}/>
                <h2>This error will remain until you fix your Column Names</h2>
                <ol style={{marginBottom: '50px'}}>
                    <li>You can fix in your file then Replace or Refresh</li>
                    <li>You can specify if there are no Column Names OR they are on another line</li>
                </ol>
                <hr/>
                <div style={{marginTop: '50px', marginBottom: '100px'}}>
                <h2>If you change your Column Names in your Google Sheets or DropBox file AFTER successfully uploading</h2>
                <p>AND the change turns out to create duplicate Column Names</p>
                <p style={{marginBottom: '25px'}}>You will get a <code>400</code> Error Code on any subsequent API Requests until you fix the duplicate names</p>
                <ReactJson src={{
                    "error": "Your column names are not valid. Read our column name guide: https://apispreadsheets.com/guides/column-names. Fix and try again."}} name={null} theme="ocean" displayObjectSize={false}/>
                </div>
                <h2>Email us at <a href="mailto:info@apispreadsheets.com">info@apispreadsheets.com</a> if you have any questions about this</h2>
            </div>
        )
    }
}

export default ColumnNamesValid