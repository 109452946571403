import React, {Component} from 'react'
import { Image } from "semantic-ui-react"
import {authorDetails} from "../../Constants/authorDetails";

class UseCaseCard extends Component {
    constructor(props){
        super(props);

        this.goToUseCase = this.goToUseCase.bind(this);
    }

    goToUseCase(e){
        this.useCaseLink.click()
    }

    render() {
        let header = null;
        let writtenBy = null;
        let writtenDt = null;

        if (!this.props.landing){
            header = <h2 onClick={this.goToUseCase}>{this.props.tutorialInfo.heading}</h2>
            writtenBy = <span><Image src={authorDetails[this.props.tutorialInfo.writtenBy]['pic']} avatar /> <a href={authorDetails[this.props.tutorialInfo.writtenBy]['social']} target="_blank" rel="noopener noreferrer">{this.props.tutorialInfo.writtenBy}</a></span>
            writtenDt = <span><i>{this.props.tutorialInfo.date}</i></span>
        }

        return (
            <div className="useCaseCard">
                <a href={"/" + this.props.url + ".html"} style={{display: "none"}} ref={useCaseLink => this.useCaseLink = useCaseLink}>Read more</a>
                <div onClick={this.goToUseCase} style={{cursor: 'pointer'}}>
                    {header}
                    <img src={this.props.img} alt="Use Case" style={{color: 'orange'}} onClick={this.goToUseCase}/>
                    <span onClick={this.goToUseCase}><br/><br/></span>
                    <h5 onClick={this.goToUseCase}>{this.props.title}</h5>
                </div>
                <div style={{paddingTop: '10px'}}>{writtenBy} {writtenDt}</div>
            </div>
        )
    }
}

export default UseCaseCard