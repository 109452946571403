import React, {Component} from 'react'
import ContactUsForm from "../InfoPages/ContactUs/ContactUsForm"

class UpdatedCTA extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div style={this.props.style}>
                <h4 style={{marginBottom: "-10px", textAlign: "center"}}>Launching on December 7th!<br/>Sign up for Beta Access</h4>
                <ContactUsForm/>
            </div>
        )
    }
}

export default UpdatedCTA