import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown, Button } from 'semantic-ui-react'
import {initOriginalFilePK} from "../../Actions/InitOriginalFilePK";
import {modifyFilePK} from "../../Actions/ModifyFilePK";
import {sortArrayOfObjects} from "../../Constants/sortArrayOfObject";

// 0 is ascending, 1 is descending
const iconNames = {
    fileName: ["sort alphabet up", "sort alphabet down"],
    createdDt: ["sort numeric up", "sort numeric down"]
}

const isListInOrder = (arr, keyType) => {
    let isInOrder = true;

    for (let i=0; i<arr.length - 1; i++){
        if (arr[i][keyType] > arr[i + 1][keyType]){
            isInOrder = false
            break
        }
    }

    return isInOrder
}

class DateSort extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sortType: 'createdDt'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.sortFiles = this.sortFiles.bind(this);
        this.findSortedOrder = this.findSortedOrder.bind(this);
    }

    handleInputChange(e, { value }){
        this.setState({
            sortType: value
        })
    }

    sortFiles(e, order){
        let sortedFilePK = sortArrayOfObjects(JSON.parse(JSON.stringify(this.props.filePK)), this.state.sortType)

        if (order === "descending"){
            sortedFilePK = sortedFilePK.reverse()
        }

        this.props.modifyFilePK(sortedFilePK)
    }

    findSortedOrder(){
        let sortedOrder = "unsorted";
        const filePK = JSON.parse(JSON.stringify(this.props.filePK))
        const filePKReverse = JSON.parse(JSON.stringify(this.props.filePK));
        filePKReverse.reverse()

        if (filePK.length > 1){
            const firstVal = filePK[0][this.state.sortType];
            const secondVal = filePK[1][this.state.sortType];
            if (firstVal < secondVal){
                if (isListInOrder(filePK, this.state.sortType)){
                    sortedOrder = "ascending"
                }
            }
            else if (firstVal > secondVal){
                if (isListInOrder(filePKReverse, this.state.sortType)){
                    sortedOrder = "descending"
                }
            }
            else{
                if (isListInOrder(filePK, this.state.sortType)){
                    sortedOrder = "ascending"
                }
                else if (isListInOrder(filePKReverse, this.state.sortType)){
                    sortedOrder = "descending"
                }
            }
        }

        return sortedOrder
    }

    render() {
        const sortedOrder = this.findSortedOrder()
        return (
            <div>
                <span>
                    Sort By: {' '}
                    <Dropdown
                        inline
                        options={[
                            {key: 0, value: 'createdDt', text: 'Date'},
                            {key: 1, value: 'fileName', text: 'Name'}
                        ]}
                        value={this.state.sortType}
                        onChange={this.handleInputChange}
                    />
                </span>
                <span style={{paddingLeft: '10px'}}>
                    <Button.Group>
                        <Button icon={iconNames[this.state.sortType][0]} basic={sortedOrder !== "ascending"} color="green" onClick={(e) => this.sortFiles(e, "ascending")}/>
                        <Button icon={iconNames[this.state.sortType][1]} basic={sortedOrder !== "descending"} color="green" onClick={(e) => this.sortFiles(e, "descending")}/>
                    </Button.Group>
                </span>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    originalFilePK: state.mainState.originalFilePK,
    filePK: state.mainState.filePK
})

const mapActionsToProps = {
    initOriginalFilePK: initOriginalFilePK,
    modifyFilePK: modifyFilePK
}

export default connect(mapStateToProps, mapActionsToProps)(DateSort)