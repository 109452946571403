import React, {Component} from 'react'
import { Table } from 'semantic-ui-react'

class ColumnTransformTable extends Component {
    render() {
        let rows = [];

        let changedCells = 'changedCells' in this.props ? this.props.changedCells : [];

        for (let i=0; i<this.props.columnNames.length; i++){
            let cells = [];

            for (let j=0; j<this.props.columnNames[i].length; j++){
                let cellStyle = null;
                if (i === 0){
                    cellStyle = {color: 'red'}
                }
                else if (i === this.props.columnNames.length - 1 && changedCells.includes(j)){
                    cellStyle = {color: 'green', backgroundColor: 'lightgreen', fontWeight: '800'}
                }
                else if (i === this.props.columnNames.length - 1 && !changedCells.includes(j)){
                    cellStyle = {color: 'green'}
                }

                cells.push(<Table.Cell key={i.toString() + j.toString()} style={cellStyle}>{this.props.columnNames[i][j]}</Table.Cell>)
            }

            rows.push(<Table.Row key={i.toString()}>{cells}</Table.Row>)
        }

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan={6}>{this.props.transform}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>
        )
    }
}

export default ColumnTransformTable