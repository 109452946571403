import React, {Component} from 'react'
import {connect} from 'react-redux'
import PaymentForm from './PaymentForm'
import PaymentPlanSelection from './PaymentPlanSelection'
import PopupPlanDetails from './PopupPlanDetails'
import { Icon } from 'semantic-ui-react'
import CurrencyDropdown from "../../InfoPages/Pricing/CurrencyDropdown"
import CustomerCarousel from "../../InfoPages/CustomerCarousel";

class CompletePaymentForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: localStorage.getItem("email"),
            plan: this.getPlan()
        }

        this.getPlan = this.getPlan.bind(this);
        this.updatePlan = this.updatePlan.bind(this);
    }

    getPlan(){
        const url = window.location.href;
        if (url.includes("?pricing=")){
            return url.substring(url.indexOf("?pricing=")+9, url.indexOf("?pricing=")+10)
        }
        else{
            return "1"
        }

    }

    updatePlan(plan){
        this.setState({
            plan: plan
        })
    }

    render() {
        let paymentOriginText = null;

        if (this.props.paymentModalOrigin !== null){
            paymentOriginText = {
                "private": <span style={{color: 'green'}}>APIs cannot be private on Free accounts<br/></span>
            }[this.props.paymentModalOrigin]
        }

        return (
            <div>
                <div className="segment-margin segment-raised">
                    <h2 className="thinHeading">Upgrade your API Spreadsheets Account </h2>
                    {paymentOriginText}
                    <br/>
                    <div className="row">
                        <div className="col-sm-7">
                            <span className="inputLabel">Plan <PopupPlanDetails trigger={<span className="linkStyle" style={{marginLeft: '25px'}}><small><Icon name="eye" /> View Plan Details</small></span> }
                                                                                plan={parseInt(this.state.plan)}
                            /></span>
                            <PaymentPlanSelection plan={this.state.plan}
                                                  updatePlan={this.updatePlan}
                            />
                        </div>
                        <div className="col-sm-5">
                            <div className="inputLabel">Currency</div>
                            <CurrencyDropdown />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <br/>
                            <PaymentForm
                                plan={parseInt(this.state.plan)}
                            />
                        </div>
                    </div>
                </div>
                <div className="segment-margin segment-raised">
                    <CustomerCarousel full/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    paymentModalOrigin: state.mainState.paymentModalOrigin
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CompletePaymentForm)