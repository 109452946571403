import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'
import {modifyShowingMinorTutorials} from "../../Actions/ModifyShowingMinorTutorials";
import API_Root from "../../Constants/API_Root";

class ColumnNamesTutorial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: true
        }

        this.handleChecked = this.handleChecked.bind(this);
    }

    handleChecked(e){
        let updatedShowingMinorTutorials = JSON.parse(JSON.stringify(this.props.showingMinorTutorials));
        updatedShowingMinorTutorials.columnMap = false;
        this.props.modifyShowingMinorTutorials(updatedShowingMinorTutorials);

        fetch(API_Root + 'api/unsubscribe-from-tutorial/', {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                minorTutorial: 'columnMap'
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then().catch()
    }

    render() {
        if (this.props.showingMinorTutorials.columnMap){
            if (this.state.display){
                return (
                    <div className="columnNamesTutorial">
                        <h4>Essential Column Names Information <span style={{float: 'right'}} onClick={(e) => this.setState({ display: false })} ><Icon name="window close outline" /></span></h4>
                        <ul>
                            <li>The Column Names here may NOT reflect what is currently in your File</li>
                            <li>Column Names are updated whenever you make a API Request or if you replace/refresh the file from <b>More Actions</b></li>
                        </ul>
                        <div style={{textAlign: 'center'}}>
                            <input type="checkbox" value={this.props.showingMinorTutorials.columnMap} onClick={this.handleChecked} onChange={this.handleChecked} id="columnNamesTutorialCheckbox" />
                            <label htmlFor="columnNamesTutorialCheckbox" style={{cursor: 'pointer', paddingLeft: '5px'}}>
                                <b>Don't show me this again</b>
                            </label>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    null
                )
            }
        } else {
            return (
                null
            )
        }

    }
}

const mapStateToProps = (state) => ({
    showingMinorTutorials: state.mainState.showingMinorTutorials
})

const mapActionsToProps = {
    modifyShowingMinorTutorials: modifyShowingMinorTutorials
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnNamesTutorial)