import React, {Component} from 'react'
import { Menu, Image } from 'semantic-ui-react'
import images from '../../Constants/Images'
import NavbarItem from './NavbarItem'
import NavbarLogIn from './NavbarLogIn'
import NavbarSignUp from './NavbarSignUp'
import getURLParam from '../../Constants/getURLParam'
import {connect} from "react-redux";
import {push} from "react-router-redux";
import ToggleDarkModeButton from "./ToggleDarkModeButton";

class NavbarNonAuth extends Component {
    render() {
        const urlParam = getURLParam(window.location.href);
        if (urlParam.includes("signup") || urlParam.includes("login")){
            return(
                null
            )
        }
        else{
            const color = this.props.isDarkMode ? "white" : "black";

            return (
                <div style={{color: color}}>
                    <Menu secondary stackable>
                        <Menu.Menu position="left">
                            <Menu.Item>
                                <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                    <Image style={{cursor: 'pointer'}} src={this.props.isDarkMode ? images.darkModeLogoNav : images.logoNav} href="/" size="small"/>
                                </div>
                            </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu position="right">
                            <Menu.Item>
                                <ToggleDarkModeButton/>
                                {/*<NavbarItem to="pricing" name="Pricing" color={color} />*/}
                            </Menu.Item>
                            {/*<Menu.Item>*/}
                                {/*<NavbarItem to="tutorials" name="Use Cases" color={color} />*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                                {/*<NavbarItem to="feedbackModal" name="Feedback" color="gray" modal/>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                                {/*<NavbarLogIn/>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                                {/*<NavbarSignUp />*/}
                            {/*</Menu.Item>*/}
                        </Menu.Menu>
                    </Menu>
                </div>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => ({
    id: ownProps,
    isDarkMode: state.mainState.isDarkMode
});

const mapActionsToProps = {
    navigateTo: push,
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarNonAuth)

