import React, {Component} from 'react'
import {connect} from 'react-redux'
import { push } from 'react-router-redux'
import {toggleModal} from "../Actions/ToggleModal";
import {modifyPaymentModalOrigin} from "../Actions/ModifyPaymentModalOrigin";

class UpgradeIndex extends Component {
    componentDidMount(){
        this.props.navigateTo("/files")
        this.props.modifyPaymentModalOrigin(null)
        this.props.toggleModal("paymentModal")

    }

    render() {
        return (
            <div />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(mapStateToProps, mapActionsToProps)(UpgradeIndex)