import React, {Component} from 'react'
import CreditCardDetails from './CreditCardDetails'
import {Elements, StripeProvider} from 'react-stripe-elements';
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyFilePKProBlock} from "../../Actions/ModifyFilePKProBlock";
import {modifyTier} from "../../Actions/ModifyTier";

class PaymentForm extends Component {
    constructor(props){
        super(props);

        this.changeProcess = this.changeProcess.bind(this);
    }

    changeProcess(process){
        if ('changeProcess' in this.props){
            this.props.changeProcess(process);
        }

        if (process === "completed"){
            let updatedFilePKProBlock = {};

            for (let pkID in this.props.filePKProBlock){
                updatedFilePKProBlock[pkID] = false
            }

            this.props.modifyFilePKProBlock(updatedFilePKProBlock);

            const planNames = ["pro", "team"]
            this.props.modifyTier(planNames[this.props.plan - 1])
        }
    }

    render() {
        return (
            <div>
                <StripeProvider apiKey="pk_live_nmeaYWsNdAbSbOTRd8MgnGLL">
                {/*<StripeProvider apiKey="pk_test_1rS8QGgeDU1Ewoyjc8AjaTx0">*/}
                    <div className="example">
                        <Elements>
                            <CreditCardDetails
                                changeProcess={this.changeProcess}
                                toggleModal={this.props.toggleModal}
                                plan={this.props.plan}
                                currency={this.props.currency}
                            />
                        </Elements>
                    </div>
                </StripeProvider>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePKProBlock: state.mainState.filePKProBlock,
    currency: state.mainState.currency
});

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyFilePKProBlock: modifyFilePKProBlock,
    modifyTier: modifyTier
};

export default connect(mapStateToProps, mapActionsToProps)(PaymentForm)
