import React, {Component} from 'react'
import {connect} from "react-redux";
import {Accordion, Icon } from 'semantic-ui-react'
import CodeSnippet from "./CodeSnippet"
import {getFileURL} from "../Constants/getFileURL";
import {getCodeSnippet} from "../Constants/getCodeSnippet"

class CodeAccordionUpdated extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0
        }

        this.handleAccordionClick = this.handleAccordionClick.bind(this);
    }

    handleAccordionClick(e, idxClicked){
        const { index } = idxClicked;
        const activeIndex = this.state.activeIndex;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex })
    }

    render() {
        const fileURL = getFileURL(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.outputFormat, this.props.apiType, this.props.rowLen, this.props.rows, this.props.readQuery);

        const code = {
            HTML: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "html"),
            cURL: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "cURL"),
            python: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "python"),
            javascript: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "javascript"),
            ruby: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "ruby"),
            PHP: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "php")
        }

        const activeIndex = this.state.activeIndex;

        return (
            <Accordion styled fluid>
                <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleAccordionClick}>
                    <Icon name='dropdown' />
                    <Icon name='code' /> Code Samples
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    <CodeSnippet code={code} height={150} apiType={this.props.apiType}/>
                </Accordion.Content>
            </Accordion>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(CodeAccordionUpdated)