import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyKeysPK} from "../../Actions/ModifyKeysPK";
import API_Root from "../../Constants/API_Root";
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";

class RegenerateKeysButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            keysPopup: false,
            regenerating: false
        }

        this.regenerateKeys = this.regenerateKeys.bind(this);

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);

    }

    handleClose(){
        this.setState({
            keysPopup: false
        })
    }

    handleOpen(){
        this.setState({
            keysPopup: true
        })
    }

    regenerateKeys(e){
        this.setState({
            regenerating: true
        });

        const resetStates = () => this.setState({ regenerating: false, keysPopup: false });

        fetch(API_Root + "api/regenerate-api-spreadsheets-keys/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                pk: this.props.pk
            })
        }).then(res => {

            if (res.status === 201){
                res.json().then((data) => {
                    if (!data.error){
                        const newAccessKey = data.accessKey;
                        const newSecretKey = data.secretKey;

                        const copiedFileInformation = JSON.parse(JSON.stringify(this.props.fileInformation));

                        copiedFileInformation[this.props.pkID]['accessKey'] = newAccessKey;
                        copiedFileInformation[this.props.pkID]['secretKey'] = newSecretKey;

                        this.props.modifyFileInformation(copiedFileInformation);

                        resetStates()
                    }
                    else{
                        resetStates()
                    }
                }).catch((err) => {
                    resetStates()
                })
            }
            else{
                resetStates()
            }
        }).catch(err => resetStates())
    }

    render() {
        return (
            <Popup trigger={<div><Button content="Get New Keys" basic color="red" icon="key" fluid/></div>}
                   content={
                       <div style={{textAlign: 'center'}}>
                           <h5 className="thinHeading">Are you sure you want to regenerate your keys?</h5>
                           <Button color='green' content='No' onClick={this.handleClose} />
                           <Button color='red' content='Regenerate' onClick={this.regenerateKeys} loading={this.state.regenerating} />
                       </div>
                   }
                   on='click'
                   open={this.state.keysPopup}
                   onClose={this.handleClose}
                   onOpen={this.handleOpen}
                   position='top'
            />
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyKeysPK: modifyKeysPK,
    modifyFileInformation: modifyFileInformation
}

export default connect(mapStateToProps, mapActionsToProps)(RegenerateKeysButton)