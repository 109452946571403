import React, {Component} from 'react'
import {toggleModal} from "../../../Actions/ToggleModal";
import {connect} from "react-redux";
import {modifyEditID} from "../../../Actions/ModifyEditID";
import {modifyModalQueryQuickstartType} from "../../../Actions/ModifyModalQueryQuickstartType";
import BetaLabel from "../../../SharedComponents/BetaLabel";
// import EmptyFileWarning from "./EmptyFileWarning";

class UpdateOptionsToolbar extends Component {
    constructor(props) {
        super(props);

        this.openQueryModal = this.openQueryModal.bind(this);
    }

    openQueryModal(e){
        this.props.modifyEditID(this.props.pkID)
        this.props.modifyModalQueryQuickstartType("updateAction")
        this.props.toggleModal("queryQuickstartModal")
    }

    render() {
        return (
            <div className="row" style={{height: '25px'}}>
                <div className="col-sm-12">
                    <span><BetaLabel/> A Query is required to update values. <span className="linkStyle" onClick={this.openQueryModal}>Read how</span> </span>
                </div>
                {/*{this.props.fileEmpty ? <EmptyFileWarning actionType="Update" pk={this.props.pk} pkID={this.props.pkID}/> : null}*/}
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID,
    modifyModalQueryQuickstartType: modifyModalQueryQuickstartType
}

export default connect(null, mapActionsToProps)(UpdateOptionsToolbar)