import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'

class FileOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            icon: "triangle right"
        }

        this.toggleHover = this.toggleHover.bind(this);
    }

    toggleHover(e){
        if (this.state.icon === "triangle right"){
            this.setState({
                icon: "triangle down"
            })
        }
        else{
            this.setState({
                icon: "triangle right"
            })
        }
    }

    render() {
        return (
            <div className="customDropdown">
                <div className="dropbtn" onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                       <Icon name={this.state.icon} /> <b>More Actions</b>
                </div>
                <div className="dropdown-content">
                    {this.props.downloadFileButton}
                    {this.props.reuploadFile}
                    {this.props.columnNames}
                    {this.props.shareButton}
                    {this.props.analyticsButton}
                    {this.props.testQuery}
                    {this.props.importSheets}
                    {this.props.editFile}
                    {this.props.keysButton}
                    {this.props.removeFile}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FileOptions)