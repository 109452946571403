import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class CustomAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }

        this.toggleOpen = this.toggleOpen.bind(this);
    }

    toggleOpen(){
        const toggleState = !this.state.open;

        this.setState({
            open: toggleState
        })
    }

    render() {
        let body = null;
        let icon = <Icon name="triangle right" />

        if (this.state.open){
            body =
                <div className="customAccordionBody" style={'bodyStyle' in this.props ? this.props.bodyStyle : null}>
                    {this.props.body}
                </div>
            icon = <Icon name="triangle down" />
        }


        return (
            <div className="customAccordion" style={'accordionStyle' in this.props ? this.props.accordionStyle : null}>
                <button className="customAccordionHeader" style={'headerDivStyle' in this.props ? this.props.headerDivStyle : null} onClick={this.toggleOpen}>
                        <span style={'iconStyle' in this.props ? this.props.iconStyle : null}>{icon}</span>
                        <span style={'headerTextStyle' in this.props ? this.props.headerTextStyle : null}>{this.props.header}</span>
                </button>
                {body}
            </div>
        )
    }
}

export default CustomAccordion