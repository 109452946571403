import React, {Component} from 'react'
import SampleStartTable from '../SampleStartTable'
import DeleteTable from './DeleteTable'
import DocsBaseURL from '../../DocsBaseURL'
import DocsGetURLParameters from "./DocsGetURLParameters";
import DocsGetResponseCode from "./DocsGetResponseCode"
class DeleteIndex extends Component {
    render() {
        let baseGetURL = 'data/[fileID]/?query=[query]&accessKey=[accessKey]&secretKey=[secretKey]/';

        return (
            <div>
                <h3 className="thinHeading"><b>Delete</b> refers to deleting entire rows</h3>
                <br/>
                <div className="row">
                    <div className="col-sm-6">
                        <SampleStartTable/>
                    </div>
                    <div className="col-sm-6">
                        <DeleteTable/>
                    </div>
                </div>
                <br/><br/>
                <h3>REQUEST</h3>
                <hr/>
                <h3>Make a <b>GET</b> Request to the following URL</h3>
                <DocsBaseURL url={baseGetURL} />
                <i>Example URL</i>
                <DocsBaseURL url="data/184/?query=deletefrom184wherecol_name=123&accessKey=12345678901234567890123456789012&secretKey=12345678901234567890123456789012/" />
                <br/>
                <DocsGetURLParameters/>
                <br/>
                <hr/>
                <br/><br/>
                <h3>RESPONSE</h3>
                <hr/>
                <span>The following response codes can be returned</span>
                <div style={{fontSize: '115%'}}>
                    <DocsGetResponseCode/>
                </div>
            </div>
        )
    }
}

export default DeleteIndex