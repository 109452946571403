import React, {Component} from 'react'
import {CardElement, injectStripe} from 'react-stripe-elements';
import API_Root from "../../Constants/API_Root";
import { Message, Button } from 'semantic-ui-react'

class PromoCreditCardDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cardSuccess: null,
            submitDisabled: true,
            loading: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
        this.refresh = this.refresh.bind(this);

    }

    refresh(){
        this.props.changeProcess()
    }

    dismissMessage(){
        this.setState({ cardSuccess: null })
    }

    handleInputChange(e){
        let elementName = e.target.name;

        if (elementName === "tandc"){
            this.setState({
                tandc: !this.state.tandc
            })
        }
        else{
            let elementValue = e.target.value;

            if (elementName === "email" || elementName === "name"){
                this.setState({
                    [elementName]: (elementValue).trim()
                })
            }
            else{
                this.setState({
                    [elementName]: elementValue
                })
            }
        }
    }

    async submit(e) {
        this.setState({
            loading: true
        })

        const failedMessage = "Your Payment Failed!";
        let {token} = await this.props.stripe.createToken({name: "Name"});

        if (token !== undefined){
            let response = await fetch(API_Root + "api/charge-api-spreadsheet-promo/", {
                method: "POST",
                headers: {"Content-Type": "text/plain"},
                body: JSON.stringify({
                    stripeToken: token.id,
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    currency: this.props.currency,
                    amount: this.props.amount
                })
            }).then().catch(err =>
                this.setState({
                    loading: false,
                    cardSuccess: <Message error
                                          onDismiss={this.dismissMessage}
                                          header={failedMessage}
                                          content="Please try again!"
                    />
                })
            );

            if (response !== undefined){
                if (response.ok){
                    this.setState({
                        loading: false,
                        cardSuccess: <Message positive={true}
                                              onDismiss={this.dismissMessage}
                                              header="Your payment was a success!"
                                              content={<Button content="Go to your dashboard 😀" color="green" onClick={this.refresh}/>}
                        />
                    })
                }
                else{
                    this.setState({
                        loading: false,
                        cardSuccess: <Message error
                                              onDismiss={this.dismissMessage}
                                              header={failedMessage}
                                              content="Please try again!"
                        />
                    })
                }
            }

        }
        else{
            this.setState({
                loading: false,
                cardSuccess: <Message error
                                      onDismiss={this.dismissMessage}
                                      header={failedMessage}
                                      content="Please enter all details and try again!"
                />
            })
        }
    }


    render() {
        return (
            <div className="checkout">
                <div className="semanticBorder">
                    <CardElement />
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button loading={this.state.loading}
                            onClick={this.submit}
                            color="purple"
                            size="large"
                            style={{textAlign: 'center'}}>
                        {"Get Lifetime Access to Pro for " + this.props.currencyAmount}
                        <span role="img" aria-label="smile" style={{paddingLeft: '7px'}}>😀</span>
                    </Button>
                </div>
                <br/>
                {this.state.cardSuccess}
            </div>
        )
    }
}

export default injectStripe(PromoCreditCardDetails)