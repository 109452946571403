import React, {Component} from 'react'
import pricing from "../../Constants/Pricing";
import { Icon } from 'semantic-ui-react'
import AddCommaToNumbers from "../../Constants/AddCommaToNumbers";
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import CurrencyDropdown from "./CurrencyDropdown"
import {getPricingText} from "../../Constants/getPricingText";
import { connect } from 'react-redux'
import CustomerCarousel from "../CustomerCarousel";

class PricingPage extends Component {
    constructor(props){
        super(props);

        this.goToFAQ = this.goToFAQ.bind(this);
    }

    goToFAQ(e, faq){
        window.scrollTo({
            top: document.getElementById(faq + "FAQ").offsetTop,
            behavior: 'smooth'
        })
    }

    render() {
            const mobileId = (mobileId) => {return <span className="faqSummary summaryMobileID">{mobileId}</span>}

            return (
                <div style={{minHeight: '100vh'}}>
                    <br/><br/>
                    <div className="pricingGrid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1 className="pricingHeroText">Simple pricing for all your needs</h1>
                            </div>
                            <div className="col-sm-5">
                                <div style={{float: 'right'}}>
                                    <div id="pricingOptionsGrid">
                                        <div id="pricingOptionsText">
                                            Currency
                                        </div>
                                        <div id="pricingOptionsDropdown">
                                            <CurrencyDropdown />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-1" />
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="pricingBox pricingBoxDisplay">
                                    <div className="pricingPageImageOuter">
                                        <div className="pricingPageImage">
                                            <h3 className="pricingHeader">P</h3>
                                            <span className="subheading">S</span>
                                            <h1 className="pricingCost">$0</h1>
                                            <span className="subheading">m</span>
                                            <p className="pricingCTA">G</p>
                                        </div>
                                    </div>
                                    <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "rows")}>Rows per File</span></p>
                                    <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "files")}>Files</span></p>
                                    <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "requests")}>Requests per Day</span></p>
                                    <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "private")}>Private?</span></p>
                                    <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "support")}>Support</span></p>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="pricingBox">
                                    <h3 className="pricingHeader">{pricing[0].name.toUpperCase()}</h3>
                                    <span className="subheading">{capitalizeHyphenated(pricing[0].desc)}</span>
                                    <h1 className="pricingCost">{getPricingText(this.props.currency, 0)}</h1>
                                    <span className="subheading">per month</span>
                                    <p className="pricingCTA"><a className="linkStyle" href="/signup">Get Started</a></p>
                                    <p className="featureItem">{AddCommaToNumbers(pricing[0].rowMax.toString())} {mobileId("Rows per File")}</p>
                                    <p className="featureItem">{AddCommaToNumbers(pricing[0].files.toString())} {mobileId("Files")}</p>
                                    <p className="featureItem">{AddCommaToNumbers(pricing[0].requests.toString())} {mobileId("Requests per Day")}</p>
                                    <p className="featureItem">{mobileId("Private?")} <Icon name={pricing[0].secure ? "check" : "remove"} /></p>
                                    <p className="featureItem">{pricing[0].support} {mobileId("Support")}</p>
                                    <p className="pricingCTA"><a className="linkStyle" href="/signup">Get Started</a></p>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="pricingBox proPricingBox">
                                    <h3 className="pricingHeader">{pricing[1].name.toUpperCase()}</h3>
                                    <span className="subheading">{capitalizeHyphenated(pricing[1].desc)}</span>
                                    <h1 className="pricingCost">{getPricingText(this.props.currency, 1)}</h1>
                                    <span className="subheading">per month</span>
                                    <p className="pricingCTA"><a className="linkStyle" href="/signup?pricing=1">Get Started</a></p>
                                    <p className="featureItem">{AddCommaToNumbers(pricing[1].rowMax.toString())} {mobileId("Rows per File")}</p>
                                    <p className="featureItem">{AddCommaToNumbers(pricing[1].files.toString())} {mobileId("Files")}</p>
                                    <p className="featureItem">{AddCommaToNumbers(pricing[1].requests.toString())} {mobileId("Requests per Day")}</p>
                                    <p className="featureItem">{mobileId("Private?")} <Icon name={pricing[1].secure ? "check" : "remove"} /></p>
                                    <p className="featureItem">{pricing[1].support} {mobileId("Support")}</p>
                                    <p className="pricingCTA"><a className="linkStyle" href="/signup?pricing=1">Get Started</a></p>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="pricingBox teamPricingBox">
                                    <div className="pricingBestValueTag">
                                        Best Value!
                                    </div>
                                    <h3 className="pricingHeader">{pricing[2].name.toUpperCase()}</h3>
                                    <span className="subheading">{capitalizeHyphenated(pricing[2].desc)}</span>
                                    <h1 className="pricingCost">{getPricingText(this.props.currency, 2)}</h1>
                                    <span className="subheading">per month</span>
                                    <p className="pricingCTA"><a className="linkStyle" href="/signup?pricing=2">Get Started</a></p>
                                    <p className="featureItem">{AddCommaToNumbers(pricing[2].rowMax.toString())} {mobileId("Rows per File")}</p>
                                    <p className="featureItem">{AddCommaToNumbers(pricing[2].files.toString())} {mobileId("Files")}</p>
                                    <p className="featureItem">{AddCommaToNumbers(pricing[2].requests.toString())} {mobileId("Requests per Day")}</p>
                                    <p className="featureItem">{mobileId("Private?")} <Icon name={pricing[2].secure ? "check" : "remove"} /></p>
                                    <p className="featureItem">{pricing[2].support} {mobileId("Support")}</p>
                                    <p className="pricingCTA"><a className="linkStyle" href="/signup?pricing=2">Get Started</a></p>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="pricingBox customPricingBox">
                                    <h3 className="pricingHeader">ENTERPRISE</h3>
                                    <span className="subheading">Agency & Business</span>
                                    <h1 className="pricingCost">custom</h1>
                                    <span className="subheading">annual</span>
                                    <p className="pricingCTA"><a className="linkStyle" href="/contact-us">Contact Us</a></p>
                                    <p className="featureItem">unlimited {mobileId("Rows per File")}</p>
                                    <p className="featureItem">unlimited {mobileId("Files")}</p>
                                    <p className="featureItem">unlimited {mobileId("Requests per Day")}</p>
                                    <p className="featureItem">{mobileId("Private?")} <Icon name="check"/></p>
                                    <p className="featureItem">Dedicated {mobileId("Support")}</p>
                                    <p className="pricingCTA"><a className="linkStyle" href="/contact-us">Contact Us</a></p>
                                </div>
                            </div>
                            <div className="col-sm-1" />
                        </div>
                    </div>
                    <div style={{marginTop: '50px', marginBottom: '50px'}}>
                        <CustomerCarousel/>
                    </div>
                    <div className="pricingFAQs">
                        <div className="row">
                            <div className="col-sm-4" />
                            <div className="col-sm-4">
                                <h6 style={{color: 'gray', textAlign: 'center'}} id="pricingFAQHeader">FREQUENTLY ASKED QUESTIONS</h6>
                            </div>
                            <div className="col-sm-4" />
                        </div>
                        <br/>
                        <section id="rowsFAQ">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>How do the rows count work?</h5>
                                        <p>Each row in the file besides the column names counts as a row</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What happens to the row count when I CREATE rows?</h5>
                                        <span>On the free plan, if you CREATE rows that make the file exceed the free limit then you will get a <b>402</b> error and the row won't be added.</span>
                                        <span>As a paying customer, our limits are actually soft limits. We won't prohibit you if you go over but if you consistently display this behavior then we will return a <b>402</b> error code.</span>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What happens if I manually add data to my Google Sheets or Dropbox File?</h5>
                                        <span>On the free plan, if manually adding the rows has exceeded the limit, then the next time you make an API request, you will get a <b>402</b> error.</span>
                                        <span>As a paying customer, our limits are soft limits. Even if your manually adding has exceeded the limit, you will still be able to make requests.</span>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <br/><br/><br/>
                        <section id="filesFAQ">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What counts as 1 file?</h5>
                                        <p>Each file uploaded, each Google Sheet connected and each DropBox File selected counts as 1 file each</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What happens when I reach my file limit?</h5>
                                        <p>You won't be able to upload any more files unless you upgrade.</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                </div>
                            </div>
                        </section>
                        <br/><br/><br/>
                        <section id="requestsFAQ">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What counts as 1 request?</h5>
                                        <p>Any call to the API, GET or POST counts as 1 request. Even if you are creating more than 1 row.</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What happens if I exceed my daily limit?</h5>
                                        <span>On the free plan, you will get a <b>402</b> error if you exceed your daily limit.</span>
                                        <span>As a paying customer, our limits are soft limits. You can go over (within reason) without fear. However if you constantly abuse this, we will start returning a <b>402</b> error code.</span>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What counts as 1 day?</h5>
                                        <p>We count 1 day using UTC time</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <br/><br/><br/>
                        <section id="privateFAQ">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What does private mean?</h5>
                                        <p>Private gives you the ability to secure your API URL with keys so your data can't be accessed by anyone else.</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What if my data is not private?</h5>
                                        <p>Don't worry, we don't share or use your data and API in any way. The API URL can only be accessed by anyone who has it.</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                </div>
                            </div>
                        </section>
                        <br/><br/><br/>
                        <section id="supportFAQ">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What do the FAQ and Email support type mean?</h5>
                                        <p>On the free plan, you are relegated to looking up answers in our FAQs and Guides. On the Pro plan, you have full email support from Monday to Friday, 9AM to 9PM EST.</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What does the Priority support type mean?</h5>
                                        <span>On the Team plan, you will have email and phone support from Monday to Friday, 9AM to 9PM with a guaranteed response time of a few hours.</span>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What does the Dedicated support type mean?</h5>
                                        <p>Depending on your agreement with us, you will have a given customer support agent dedicated to servicing your account.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <br/><br/><br/>
                        <section id="general">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>How do I pay for the plan?</h5>
                                        <p>We accept all Credit Cards using <a href="https://www.stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a> as the payment provider, so we don't store or see your credit card number. If you wish to pay via invoice or any other payment methods, please <a href="contact-us">Contact Us</a>.</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>Are there any other fees?</h5>
                                        <p>Nope, the price you see is the final price!</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What does a month mean?</h5>
                                        <p>A month begins and ends on the date you signed up. For ex: if you signed up on August 13th, then your month will renew on September 13th, October 13th and so on.</p>
                                    </div>
                                </div>
                            </div>
                            <br/><br/><br/>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>Do I receive any receipt of the monthly plan?</h5>
                                        <p>Yes, we send an invoice to your email each month your account is active.</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>How long can I use the free plan for?</h5>
                                        <p>As long as you want</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="pricingFAQDiv">
                                        <h5>What if I want to cancel my account?</h5>
                                        <p>Simply email <a href="mailto:cancel@apispreadsheets.com?Subject=API%20Spreadsheets%Cancel%20Account"> cancel@apispreadsheets.com</a>from the email you signed up with and we will cancel your account!</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <br/><br/><br/><br/><br/><br/>
                        <div className="row">
                            <div className="col-sm-4" />
                            <div className="col-sm-4">
                                <div className="pricingHaveMoreQuestions">
                                    <div className="pricingHaveMoreQuestionsText">
                                        <h2>Have more questions?</h2>
                                        <h4 style={{color: 'gray'}}>Email us at <a href="mailto:info@apispreadsheets.com?Subject=Pricing%20And%20Billing%20Questions">info@apispreadsheets.com</a></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4" />
                        </div>
                    </div>
                </div>
            )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

export default connect(mapStateToProps, null)(PricingPage)