import React, {Component} from 'react'
import API_Root from "../../Constants/API_Root";

class DocsBaseURL extends Component {
    render() {
        let API;
        if ('old' in this.props){
            API = API_Root
        }
        else{
            API = API_Root === "https://api-woyera.com/" ? "https://api.apispreadsheets.com/" : API_Root;
        }

        let identifier = null;
        if ('identifier' in this.props){
            identifier = this.props.identifier
        }

        return (
            <pre className="urlBox">
                {identifier} {API + this.props.url}
            </pre>
        )
    }
}

export default DocsBaseURL