import React, {Component} from 'react'
import GuideDetails from "../GuideDetails";
import TogglePrivate from "./Read/TogglePrivate"
import { Label } from 'semantic-ui-react'
import DocsBaseURL from "../../InfoPages/DocsPage/DocsBaseURL"
import SampleTable from "./SampleTable"
import JSONDisplay from "../../SharedComponents/JSONDisplay"
import PostPrivateHeaders from "./PostPrivateHeaders";
import {createFileInformation} from "./Functions/createFileInformation";
import {createFullURLFromBase} from "../../Constants/createFullURLFromBase";
import {createCodeSnippets} from "./Functions/createCodeSnippets";
import CodeSnippet from "../../FileDnD/CodeSnippet";
import BetaMessage from "../../SharedComponents/BetaMessage";

class UpdateSubsetRows extends Component {
    constructor(props){
        super(props);

        this.state = {
            sampleTableData: [
                [1, "Justin Trudeau", 252069.46, "Canada"],
                [2, "Jacinda Ardern", 339862.25, "New Zealand"],
                [3, "Boris Johnson", 187033.75, "UK"],
                [4, "Joko Widodo", 27200.8, "Indonesia"],
                [5, "Angela Merkel", 369727.4, "Germany"]
            ],
            updatedTableData: [
                [1, "Justin Trudeau", 252069.46, "Canada"],
                [2, "Jacinda Ardern", 339862.25, "New Zealand"],
                [3, "Boris Johnson", 187033.75, "UK"],
                [4, "Joko Widodo", 27200.8, "Indonesia"],
                [5, "Angela Merkel", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 370000}, {style: {backgroundColor: "lightgreen", color: 'green'}, value: "Deutschland"}]
            ],
            updatedTableData2Col: [
                [1, "Justin Trudeau", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, {style: {backgroundColor: "lightgreen", color: 'green'}, value: "NA"}],
                [2, "Jacinda Ardern", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, {style: {backgroundColor: "lightgreen", color: 'green'}, value: "NA"}],
                [3, "Boris Johnson", 187033.75, "UK"],
                [4, "Joko Widodo", 27200.8, "Indonesia"],
                [5, "Angela Merkel", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, {style: {backgroundColor: "lightgreen", color: 'green'}, value: "NA"}]
            ],
            sampleTableHeaders: ["id", "name", "salary_$", "country"],
            sampleDataType: ["num", "str", "num", "str"],
            isPrivate: false,
            dataFormat: "row"
        }

        this.togglePrivate = this.togglePrivate.bind(this);
        this.changeState = this.changeState.bind(this);
    }

    togglePrivate(e){
        this.setState({ isPrivate: !this.state.isPrivate })
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    render() {
        let baseGetURL = 'data/1023/'
        const sampleFileInformation1 = createFileInformation([[370000, "Deutschland"]], ["salary_$", "country"], ["num", "str"], 1023, "World Leaders - Updated Table", this.state.isPrivate, createFullURLFromBase('data/1023'))
        const sampleFileInformation2 = createFileInformation([[0, "NA"]], ["salary_$", "country"], ["num", "str"], 1023, "World Leaders - Updated Table", this.state.isPrivate, createFullURLFromBase('data/1023'))

        const info1AccessKey = sampleFileInformation1["1023_1"].accessKey;
        const info2AccessKey = sampleFileInformation2["1023_1"].accessKey;

        const info1SecretKey = sampleFileInformation1["1023_1"].secretKey;
        const info2SecretKey = sampleFileInformation1["1023_1"].secretKey;

        return (
            <div>
                <BetaMessage/>
                <p>Updating Data requires you to specify</p>
                <ol>
                    <li>Which <b>Rows</b> to update</li>
                    <li>Which <b>Columns</b> to update for those rows</li>
                    <li><b>Value</b> for each of the columns</li>
                </ol>
                <p>In this post, we will be updating <b>a subset of rows</b>. To update <b>all rows</b>, it's a minor adjustment to the query as you will see below.</p>
                <br/>
                <h2>We want to Update <b>salary_$</b> and <b>country</b> for <b>Angela Merkel</b></h2>
                <div className="row">
                    <div className="col-sm-6">
                        <SampleTable
                            tableData={this.state.sampleTableData}
                            tableHeaders={this.state.sampleTableHeaders}
                            header={<span style={{color: 'orange'}}>World Leaders - Current Table</span>}
                        />
                    </div>
                    <div className="col-sm-6">
                        <SampleTable
                            tableData={this.state.updatedTableData}
                            tableHeaders={this.state.sampleTableHeaders}
                            header="World Leaders - Updated Table"
                        />
                    </div>
                </div>
                <br/><br/>
                <h2>Make a POST request to this URL <TogglePrivate isPrivate={this.state.isPrivate} togglePrivate={this.togglePrivate}/></h2>
                <Label color="orange">POST</Label>
                <DocsBaseURL url={baseGetURL} />
                <br/>
                <JSONDisplay
                    heading="Include a JSON Encoded Body in your Request"
                    jsonCode={'{\n\t"data": {\n\t\t"salary_$": 370000,\n\t\t"country": "Deutschland"\n\t}, \n\t"query": "SELECT*FROM1023WHEREname=\'Angela Merkel\'"\n}'}
                />
                <br/>
                {
                    this.state.isPrivate ?
                        <div>
                            <PostPrivateHeaders
                                accessKeyInfo={info1AccessKey}
                                secretKeyInfo={info1SecretKey}
                            />
                            <br/>
                        </div> : null
                }
                <CodeSnippet
                    code={createCodeSnippets(sampleFileInformation1, "updateAction", createFullURLFromBase(baseGetURL), "columnValue", true, "SELECT*FROM1023WHEREname='Angela Merkel'")}
                    height={100}
                />
                <br/><br/><br/>
                <h2>Let's look at another example</h2>
                <p>We want to update <b>salary_$</b> and <b>country</b> to <b>0</b> and <b>NA</b>, respectively. For all countries except <b>UK</b> and <b>Indonesia</b></p>
                <div className="row">
                    <div className="col-sm-6">
                        <SampleTable
                            tableData={this.state.sampleTableData}
                            tableHeaders={this.state.sampleTableHeaders}
                            header={<span style={{color: 'orange'}}>World Leaders - Current Table</span>}
                        />
                    </div>
                    <div className="col-sm-6">
                        <SampleTable
                            tableData={this.state.updatedTableData2Col}
                            tableHeaders={this.state.sampleTableHeaders}
                            header="World Leaders - Updated Table"
                        />
                    </div>
                </div>
                <br/><br/><br/>
                <h2>Make a POST request to this URL <TogglePrivate isPrivate={this.state.isPrivate} togglePrivate={this.togglePrivate}/></h2>
                <Label color="orange">POST</Label>
                <DocsBaseURL url={baseGetURL} />
                <br/>
                <JSONDisplay
                    heading="Include a JSON Encoded Body in your Request"
                    jsonCode={'{\n\t"data": {\n\t\t"salary_$": 0,\n\t\t"country": "NA"\n\t}, \n\t"query": "SELECT*FROM1023WHEREcountryNOTIN(\'UK\',\'Indonesia\')"\n}'}
                />
                <br/>
                {
                    this.state.isPrivate ?
                        <div>
                            <PostPrivateHeaders
                                accessKeyInfo={info2AccessKey}
                                secretKeyInfo={info2SecretKey}
                            />
                            <br/>
                        </div> : null
                }
                <CodeSnippet
                    code={createCodeSnippets(sampleFileInformation2, "updateAction", createFullURLFromBase(baseGetURL), "columnValue", true, "SELECT*FROM1023WHEREcountryNOTIN('UK','Indonesia')")}
                    height={100}
                />
                <br/><br/><br/>
                <h2>Always follow these 2 rules</h2>
                <p>For updating data in a subset of rows, you ALWAYS need to have the following</p>
                <ol>
                    <li><code>data</code> parameter in the Request Body with the format specified above: <code>{'{"col_name_1": value_1, "col_name_2": value_2}'}</code></li>
                    <li><code>query</code> parameter with query of type: <code>SELECT*FROMfileIDWHEREfilterClause</code></li>
                </ol>
                <p>The only thing you should update in the query is the <code>filterClause</code> that specifies which row(s) should have their values updated. You can read more about how to write a valid query <a href={"/guides/" + GuideDetails[7].title}>here</a></p>
                <p>If you want to update <b>all rows</b>, the <code>query</code> must ALWAYS be <code>select*FROMfileID</code> without the <code>filterClause</code></p>
            </div>
        )
    }
}

export default UpdateSubsetRows