import React, {Component} from 'react'
import { Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';

class NavbarLogIn extends Component {
    constructor(props) {
        super(props);

        this.logIn = this.logIn.bind(this);
    }

    logIn(e){
        this.loginLink.click()

        if ('toggleOpen' in this.props){
            this.props.toggleOpen(e)
        }
    }

    render() {
        return (
            <span>
                <a
                    style={{display: 'none'}}
                    ref={loginLink => this.loginLink = loginLink}
                    href="/login"
                >Download hidden</a>
                <Button basic color="green" onClick={this.logIn} style={{marginTop: '2px'}}>
                    Log In
                </Button>
            </span>
        )
    }
}


export default withRouter(NavbarLogIn)