import React, {Component} from 'react'
import pricing from '../../Constants/Pricing'
import { List } from 'semantic-ui-react'
import AddCommaToNumbers from "../../Constants/AddCommaToNumbers";
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import { connect } from 'react-redux'
import {getPricingText} from "../../Constants/getPricingText";

class PopupPlanBody extends Component {
    render() {
        const pricingInfo = pricing[this.props.plan];

        let price = null;
        let pricePeriod = null;

        if (!this.props.noPrice){
            price = <h1 className="pricingCost">{getPricingText(this.props.currency, this.props.plan)}</h1>;
            pricePeriod = <span className="subheading">per month</span>
        }

        return (
            <div>
                <h3 className="pricingHeader">{pricingInfo.name.toUpperCase()}</h3>
                <span className="subheading">{capitalizeHyphenated(pricingInfo.desc)}</span>
                {price}
                {pricePeriod}
                <List>
                    <List.Item icon='th' content={AddCommaToNumbers(pricingInfo.rowMax.toString()) + " rows per file"} />
                    <List.Item icon='code' content={AddCommaToNumbers(pricingInfo.requests.toString()) + " requests per day"} />
                    <List.Item icon='file alternate outline' content={AddCommaToNumbers(pricingInfo.files.toString()) + " files"}/>
                    <List.Item icon={pricingInfo.secure ? "check" : "x"} content="Private?" />
                    <List.Item icon={supportIcon[pricingInfo.support]} content={pricingInfo.support + " support"} />
                </List>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

export default connect(mapStateToProps, null)(PopupPlanBody)

const supportIcon = {
    FAQ: 'question',
    Email: 'paper plane outline',
    Priority: 'phone'
}


