import React, {Component} from 'react'
import { Menu, Image, Icon } from 'semantic-ui-react'
import images from '../../Constants/Images'
import {connect} from "react-redux";
import {push} from "react-router-redux";
import AuthComponent from "../AuthComponent";
import NavbarItem from "./NavbarItem"
import NavbarLogIn from "./NavbarLogIn"
import NavbarSignUp from "./NavbarSignUp"
import SignOutButton from '../../Authentication/UserPages/SignOut/SignOutButton'

class NavbarMobile extends Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false
        }

        this.goToURL = this.goToURL.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
    }

    goToURL(e){
        e.preventDefault();

        this.props.navigateTo("/")
    }

    toggleOpen(e){
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        const navbar = <Menu secondary stackable>
            <Menu.Item>
                <AuthComponent
                    authComponent={<NavbarItem to="upload" name={<span><Icon name="upload" />Upload</span>} color="black" toggleOpen={this.toggleOpen}/>}
                    nonAuthComponent={<NavbarItem to="pricing" name="Pricing" color="black" toggleOpen={this.toggleOpen} />}
                />
            </Menu.Item>
            <Menu.Item>
                <AuthComponent
                    authComponent={<NavbarItem to="files" name={<span><Icon name="file alternate" />Files</span>} color="black" toggleOpen={this.toggleOpen} />}
                    nonAuthComponent={<NavbarLogIn toggleOpen={this.toggleOpen}/>}
                />
            </Menu.Item>
            <AuthComponent
                authComponent={<Menu.Item><NavbarItem to="profileModal" name={<span><Icon name="user" />Profile</span>} color="black" modal/></Menu.Item>}
                nonAuthComponent={null}
            />
            <Menu.Item>
                <AuthComponent
                    authComponent={<SignOutButton style={{marginTop: '5px'}} toggleOpen={this.toggleOpen}/>}
                    nonAuthComponent={<NavbarSignUp toggleOpen={this.toggleOpen}/>}
                />
            </Menu.Item>
        </Menu>


        return (
            <div>
                <Menu secondary>
                    <Menu.Menu position="left">
                        <Menu.Item>
                            <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                <Image style={{cursor: 'pointer'}} src={images.logoNav} onClick={this.goToURL} size="small"/>
                            </div>
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position="right">
                        <Menu.Item>
                            <div className="navbarMobileButton" onClick={this.toggleOpen}>
                                <Icon name={this.state.isOpen ? "close" : "align justify"} />
                            </div>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                {this.state.isOpen ? navbar : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarMobile)