const images = {
    logoNav: 'https://s3.amazonaws.com/project-static-assets/VizSpreadsheets/Logo.png',
    darkModeLogoNav: 'https://s3.amazonaws.com/project-static-assets/VizSpreadsheets/DarkModeLogo.png',
    image404: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Longhaired_Dachshund_portrait.jpg/1006px-Longhaired_Dachshund_portrait.jpg',
    imageIcon: 'https://project-static-assets.s3.amazonaws.com/VizSpreadsheets/LogoIcon.png',
    darkModeImageIcon: 'https://project-static-assets.s3.amazonaws.com/VizSpreadsheets/DarkModeLogoIcon.png',
    pythonLogo: 'https://woyera-static-files.s3.amazonaws.com/LanguageLogo/PythonLogo.png',
    rLogo: 'https://woyera-static-files.s3.amazonaws.com/LanguageLogo/R_Logo.png',
    loveLogo: 'https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/Logo.png'
};

export default images