import React, {Component} from 'react'
import { auth } from '../../firebase';
import { Button } from 'semantic-ui-react'
import {connect} from "react-redux";
import {resetAllStates} from "../../../Actions/ResetAllStates";

class SignOutButton extends Component{
    constructor(props){
        super(props);

        this.signOut = this.signOut.bind(this);
    }

    signOut(e){
        auth.doSignOut()
        this.props.resetAllStates();
    }

    render(){
        return(
            <div>
                <Button color="orange" onClick={this.signOut}>Logout</Button>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    resetAllStates: resetAllStates
}

export default connect(mapStateToProps, mapActionsToProps)(SignOutButton)