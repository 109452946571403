import React, {Component} from 'react'
import { Popup } from 'semantic-ui-react'
import { connect } from 'react-redux'
import NavbarItem from "./NavbarItem"

class NavbarHelp extends Component {
    render() {
        return (
            <Popup
                trigger={<span style={this.props.style} className="navLink"> {this.props.name} </span>}
                flowing
                on="click">
                <div id="navbarHelpLinks">
                    <div>
                        <NavbarItem to="FAQs" name="FAQs" color="black" icon="question circle" />
                    </div>
                    <div>
                        <NavbarItem to="docs" name="API Docs" color="black" icon="book" />
                    </div>
                    <div>
                        <NavbarItem to="guides" name="Guides" color="black" icon="map" />
                    </div>
                    <div>
                        <NavbarItem to="tutorials" name="Tutorials" color="black" icon="clipboard" />
                    </div>
                    <div>
                        <NavbarItem to="datasets" name="Datasets" color="black" icon="file alternate outline" />
                    </div>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(NavbarHelp)