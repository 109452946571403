import React, {Component} from 'react'
import { Divider } from 'semantic-ui-react'

class DocsGetResponseCode extends Component {
    render() {
        return (
            <div className="getResponseCodes">
                <h5 id="getResponseCodesHeading">Response Codes</h5>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">200</code> <span className="responseCodeParam codeSuccess">OK</span>
                    <span className="responseCodeDesc">Rows were successfully deleted</span>
                </div>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">400</code> <span className="responseCodeParam codeFail">Bad Request</span>
                    <span className="responseCodeDesc">URL Parameters were not valid</span>
                </div>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">401</code> <span className="responseCodeParam codeFail">Unauthorized</span>
                    <span className="responseCodeDesc">The data is private and incorrect access and secret keys were provided</span>
                </div>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">404</code> <span className="responseCodeParam codeFail">Not Found</span>
                    <span className="responseCodeDesc">The fileID does not exist</span>
                </div>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">500</code> <span className="responseCodeParam codeFail">Server Error</span>
                    <span className="responseCodeDesc">There was something wrong with our server</span>
                </div>
                <br/>
            </div>
        )
    }
}

export default DocsGetResponseCode