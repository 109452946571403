const pricing = [
    {
        rowMax: 1500,
        requests: 1500,
        files: 10,
        secure: false,
        price: 0,
        name: 'free',
        desc: 'hobbyists',
        support: 'FAQ'
    },
    {
        rowMax: 10000,
        requests: 10000,
        files: 100,
        secure: true,
        price: 10,
        name: 'pro',
        desc: 'professionals',
        support: 'Email'
    },
    {
        rowMax: 50000,
        requests: 50000,
        files: 250,
        secure: true,
        price: 20,
        name: 'team',
        desc: 'dev-teams',
        support: 'Priority'
    }
];

export default pricing