import React, {Component} from 'react'
import PromoBanner from "./PromoBanner";
import PromoTimer from "./PromoTimer";
import PromoPricing from "./PromoPricing";
import CurrencyDropdown from "../Pricing/CurrencyDropdown"
import PopupPlanDetails from '../../SharedComponents/Payment/PopupPlanDetails'
import { Icon } from 'semantic-ui-react';
import Confetti from 'react-confetti'
import PromoPaymentIndex from "./PromoPaymentIndex";
import AuthComponent from "../../SharedComponents/AuthComponent";
import PromoLogin from "./PromoLogin";
import { connect } from 'react-redux';

class PromoIndex extends Component {
    render() {
        const congrats = <div className="promoPaymentIndex">
            <h2>You are already a Customer! Enjoy
                <span role="img" aria-label="smile">😀</span>
                <span role="img" aria-label="confetti">🎉</span>
                <span role="img" aria-label="heart">❤️</span>
                <span role="img" aria-label="beer">🍻</span>
                <span role="img" aria-label="poppers">🎊</span>
            </h2>
        </div>

        let paymentArea = <AuthComponent
            authComponent={this.props.tier === "free" || this.props.tier === null ? <PromoPaymentIndex /> : congrats}
            nonAuthComponent={<PromoLogin />}
        />

        return (
            <div className="promoIndex">
                <Confetti
                    numberOfPieces={500}
                    recycle={false}
                />
                <PromoBanner/>
                <div style={{textAlign: 'center', paddingBottom: '10px', paddingTop: '50px'}}>
                    <h1>PRO</h1> <PopupPlanDetails trigger={<span className="linkStyle" style={{fontSize: '115%'}}><small><Icon name="eye" /> View Plan Details</small></span> }
                                                   plan={1} noPrice />
                </div>
                <PromoPricing/>
                <div className="promoCurrency">
                    <b>Currency</b>
                    <br/>
                    <CurrencyDropdown/>
                    <br/>
                </div>
                <PromoTimer/>
                <br/>
                {paymentArea}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

export default connect(mapStateToProps, null)(PromoIndex)