import React, {Component} from 'react'
import { Icon, Popup } from 'semantic-ui-react'

class InfoPopup extends Component {
    render() {
        return (
            <Popup content={this.props.info} trigger={<Icon name='info circle' style={{fontSize: '110%', cursor: 'pointer', paddingLeft: '5px', color: 'blue'}} />} on="click"/>
        )
    }
}

export default InfoPopup