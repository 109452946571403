import React, {Component} from 'react'
import ColumnTransformTable from "./ColumnTransformTable";
import CustomImage from "../../SharedComponents/CustomImage"

class APIQuickstart extends Component {
    render() {
        return (
            <div>
                <p>Getting started with the tool consists of three major steps</p>
                <ol>
                    <li>Upload your spreadsheet</li>
                    <li>Fix your Column Names (if needed)</li>
                    <li>Follow example code guides</li>
                </ol>
                <br/>
                <hr/>
                <br/>
                <h2>Upload your spreadsheet</h2>
                <p><span className="bold">Click on the appropriate dialog box and follow instructions to select a file</span><br/><span>You can select multiple files at a time as well</span></p>
                <CustomImage src="https://images2.imgbox.com/46/e7/AKPWrDcN_o.png" alt="Upload Connect Files" />
                <p className='bold'>After the file is read, you will be shown the File Information box</p>
                <CustomImage src="https://images2.imgbox.com/92/eb/5bJ978Xu_o.png" alt="File Information Box"  />
                <br/>
                <hr/>
                <br/>
                <h2>Fix your Column Names (if needed)</h2>
                <p>Column Names are the crux of the tool and need to be used verbatim to perform any of the CRUD operations.</p>
                <br/><br/>
                <p className="bold">COLUMN NAMES MUST BE UNIQUE</p>
                <ColumnTransformTable
                    transform="INVALID Column Names vs. VALID Column Names"
                    columnNames={[["ID", "First Name", "Revenue $", "First Name", "description"],
                        ["ID","First Name_1","Revenue $","First Name_2","description"]]}
                    changedCells={[1, 3]}
                />
                <br/>
                <p><b>What if my file doesn't have column names?</b></p>
                <p>Click on <b>File Options</b> in the <b>More Options</b> Drop Down</p>
                <CustomImage src="https://images2.imgbox.com/a5/ed/Ih8LbEKW_o.png" alt="File Options Dropdown" width="auto"/>
                <p>Deselect the <b>Does File Have Header Option?</b> and click on the <b>Read File</b> button</p>
                <CustomImage src="https://images2.imgbox.com/6d/a7/YjApzca6_o.png" alt="File Header Option" width="auto"/>
                <br/><br/>
                <p><b>What if my column names are not on the first line?</b></p>
                <p>You can enter the line of your column name in the <b>Start Row</b> field of the <b>File Options</b> and click on the <b>Read File</b> button</p>
                <CustomImage src="https://images2.imgbox.com/14/eb/8kWNL5Qp_o.png" alt="Start at Row" width="auto"/>
                <br/><br/>
                <hr/>
                <br/>
                <h2>Follow example code guides</h2>
                <p>You can get started easily from the File Information box itself.</p>
                    <ol>
                        <li>Select which operation you want to perform</li>
                        <li>Customize your operation selection</li>
                        <li>Pick your preferred language or get started with <b>cURL</b></li>
                    </ol>
                <CustomImage src="https://images2.imgbox.com/35/f6/YtfF2g9X_o.png" alt="image host"/>
            </div>
        )
    }
}

export default APIQuickstart