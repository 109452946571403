import React, {Component} from 'react'
import CustomImage from "../../../SharedComponents/CustomImage"
import BetaMessage from "../../../SharedComponents/BetaMessage";

class QueryBody extends Component {
    render() {
        return (
            <div>
                <BetaMessage/>
                <p>A query is only needed when you want to specify operations on a subset of rows and/or columns that meet a specified criteria. Such as when,</p>
                    <ol>
                        <li><a href="/guides/read-only-rows-specified-criteria">Reading a subset of row(s)</a></li>
                        <li>Both <a href="/guides/update-data-all-rows">Updating data in all rows</a> or <a href="/guides/update-data-only-rows-specified-criteria">Updating data in subset of row(s)</a></li>
                        <li><a href="/guides/delete-rows-in-file">Deleting row(s)</a></li>
                    </ol>
                <p>For other operations, such as <a href="/guides/read-all-rows">Reading all rows</a> or <a href="/guides/create-new-rows">Creating new rows</a>, you do not need a query.</p>
                <br/>
                <hr/>
                <br/>
                <h2>Query Format</h2>
                <p>A query for <b>READ</b> and <b>UPDATE</b> follows the same general syntax as SQL</p>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="codeTheoryBox">
                            <span>select<code>*</code>from<code>fileID</code>where<code>filterClause</code></span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="codeTheoryBox">
                            <span>select<code>col1,col2</code>from<code>fileID</code>where<code>filterClause</code></span>
                        </div>
                    </div>
                </div>
                <br/>
                <p>Some example queries would look like this</p>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="codeExampleBox">
                            <span>select<code>*</code>from<code>814</code>where<code>country='usa'orcountry='australia'</code></span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="codeExampleBox">
                            <span>select<code>id,name</code>from<code>814</code>where<code>id>5andnamein('jack','john')</code></span>
                        </div>
                    </div>
                </div>
                <br/>
                <p>A query for <b>DELETE</b> also follows the same general syntax as SQL</p>
                <div className="codeTheoryBox">
                    <span>deletefrom<code>fileID</code>where<code>filterClause</code></span>
                </div>
                <br/>
                <p>An example query would look something like this</p>
                <div className="codeExampleBox">
                    <span>deletefrom <code>814</code>where<code>country='usa'orcountry='australia'</code></span>
                </div>
                <br/><br/>
                <p><b>How The Query Format Differs from SQL</b></p>
                <ul>
                    <li><b>SELECT</b>, <b>FROM</b> and <b>WHERE</b> are the only SQL identifiers you can use. That means identifiers like <b>ORDER BY</b> or <b>GROUP BY</b> and others are not valid</li>
                    <li>There are no spaces allowed between any of the characters EXCEPT for characters inside quoted strings in the <code>filterClause</code> as you will see in the <a href="#filterClause"><b>Filter Clause</b></a> section below</li>
                    <li>The <code>filterClause</code> only supports a subset of SQL operators. You can read more about it in the <a href="#filterClause"><b>Filter Clause</b></a> section below</li>
                </ul>
                <br/>
                <br/>
                <hr/>
                <br/>
                <h2>File ID</h2>
                <p>You need to specify the <b>fileID</b> in the query. You can find this on your File Information box</p>
                <CustomImage src="https://images2.imgbox.com/62/6d/N2t6SsyE_o.png" alt="File ID Discover"  />
                <br/><br/>
                <hr/>
                <br/>
                <h2>Column Names</h2>
                <p>Column Names are important in the <code>filterClause</code> and after the <b>select</b> identifier if you are <b><a href="/guides/read-only-rows-specified-criteria">reading a subset of row(s)</a></b>.</p>
                <p>Column Names must be identical to the column names for your file. These can be found as shown below.</p>
                <img src="https://images2.imgbox.com/ca/3e/lutZ7bIg_o.png" alt="Column Name Button"/>
                <br/><br/>
                <hr/>
                <br/>
                <section id="filterClause">
                <h2>Filter Clause</h2>
                <p>The Filter Clause is specified after the <b>where</b> identifier and is a subset of what you are allowed to do in SQL. Let's look at a few examples of valid API Spreadsheets queries before we move on to the rules.</p>
                <div className="codeExampleBox">
                    <span>select<code>*</code>from<code>1056</code>where<code>{'id>5ANDid<=121'}</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <span>select<code>*</code>from<code>814</code>where<code>{"country='usa'ORcountry='australia'ORcountry='indonesia'"}</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <span>select<code>*</code>from<code>73</code>where<code>{"name<>'CJ Cregg'"}</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <span>deletefrom<code>512</code>where<code>{"continent_placeNOTIN('North America', 'Europe')"}</code></span>
                </div>
                <br/>
                <p>We currently only support <a href="https://www.w3resource.com/sql/comparison-operators/sql-comparison-operators.php" target="_blank" rel="noopener noreferrer">all SQL Comparison Operators</a> and <a href="https://www.w3resource.com/sql/boolean-operator/sql-boolean-operators.php" target="_blank" rel="noopener noreferrer"> some SQL Logical Operators</a></p>
                <p><b>We support all 6 comparison operators</b></p>
                <ol>
                    <li> <code>=</code> equal to</li>
                    <li> <code>{'<>'}</code> or <code>!=</code> not equal to</li>
                    <li> <code>{'>'}</code> greater than</li>
                    <li> <code>{'<'}</code> less than</li>
                    <li> <code>{'>='}</code> greater than or equal to</li>
                    <li> <code>{'<='}</code> less than or equal to</li>
                </ol>
                <p><b>We support only these 4 Logical Operators</b></p>
                <ol>
                    <li><code>AND</code></li>
                    <li><code>OR</code></li>
                    <li><code>IN</code></li>
                    <li><code>NOT IN</code> <b>NOTE: </b><i><code>NOT</code> is only supported in this form. You can't use NOT any other way</i></li>
                </ol>
                <p><b>There are a few more rules different than SQL to observe</b></p>
                <ol>
                    <li>String values when using comparison operators should only be quoted in SINGLE quotes
                        <br/>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="codeExampleBox">
                                    <b style={{color: 'green', display: 'block'}}>VALID</b>
                                    <code>name='John'</code>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="codeInvalidBox">
                                    <b style={{color: 'red', display: 'block'}}>INVALID</b>
                                    <code>name="John"</code>
                                </div>
                            </div>
                        </div>
                    </li>
                    <br/>
                    <li> There should be no space between any characters in the query EXCEPT in quoted string values
                        <br/>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="codeExampleBox">
                                    <b style={{color: 'green', display: 'block'}}>VALID</b>
                                    <code>name='John Adams'ORname='Josiah Bartlet'</code>
                                    <br/>
                                    <code>{"country<>'United States of America'"}</code>
                                    <br/>
                                    <code>{"nameNOTIN('Adam','AJ')"}</code>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="codeInvalidBox">
                                    <b style={{color: 'red', display: 'block'}}>INVALID</b>
                                    <code>name='John' OR name='Josiah'</code>
                                    <br/>
                                    <code>{"country <> 'United Kingdom'"}"</code>
                                    <br/>
                                    <code>{"name IN ('Adam', 'AJ')"}</code>
                                </div>
                            </div>
                        </div>
                    </li>
                    <br/>
                    <li>There is no <code>NULL</code> identifier. Use the empty string <code>''</code> instead.</li>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="codeExampleBox">
                                <b style={{color: 'green', display: 'block'}}>VALID</b>
                                <code>name=''</code>
                                <br/>
                                <code>{"name<>''"}</code>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="codeInvalidBox">
                                <b style={{color: 'red', display: 'block'}}>INVALID</b>
                                <code>name=NULL</code>
                                <br/>
                                <code>nameISNULL</code>
                            </div>
                        </div>
                    </div>
                </ol>
                </section>
                <br/>
                <hr/>
                <br/>
            </div>
        )
    }
}

export default QueryBody