import React, {Component} from 'react'
import { Segment, Input, Button, Message } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";
// import CTAButtonLanding from "../SharedComponents/CTAButtonLanding"
import MobileUpload from "./MobileLandingPage/MobileUpload.svg"
import CRUDActions from "../InfoPages/Features/FeaturesBody/CRUDActions";
import API from "../InfoPages/Features/API";
import HowDoesThisWork from "../SharedComponents/LandingUseCases/HowDoesThisWork"
import UpdatedCTA from "./UpdatedCTA"
import CustomerCarousel from "../InfoPages/CustomerCarousel";

class MobileLandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            message: null
        }

        this.sendEmail = this.sendEmail.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event){
        this.setState({
            email: event.target.value
        })
    }

    sendEmail(e){
        fetch(API_Root + "api/send-email/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: this.state.email,
                fromEmail: 'info@apispreadsheets.com'
            })
        }).then(res =>
            this.setState({
                message: <Message positive={true}
                                  header="Email sent successfully!"
                                  content="It is possible the email is sent to the spam or junk folder" />,
                email: ''
            })
        ).catch();
    }

    render() {
        return (
            <div style={{marginTop: '50px', marginBottom: '100px', marginLeft: '20px', marginRight: '20px'}}>
                <div style={{fontFamily: "museo sans rounded, sans-serif"}}>
                    <div style={{textAlign: 'center'}}>
                        <h1 style={{fontSize: "250%", fontWeight: '900'}}>
                            Instantly Use Your <br/>Spreadsheets as an <br/>API and Database
                        </h1>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <UpdatedCTA style={{marginTop: "50px"}}/>
                    </div>
                    <br/>
                    <Segment raised>
                        <h5 className="thinHeading" style={{textAlign: 'center'}}>Or Email yourself the website link</h5>
                        <Input onChange={this.handleInputChange} value={this.state.email} placeholder="Enter Email" fluid />
                        <br/>
                        <div style={{textAlign: 'center'}}>
                            <Button onClick={this.sendEmail} color="green" basic icon="paper plane" content="Email yourself"/>
                        </div>
                        {this.state.message}
                        <br/>
                    </Segment>
                </div>
                <br/><br/>
                <CustomerCarousel/>
                <br/><br/>
                <hr/>
                <br/>
                <div className="mobileFeaturesDiv">
                    <h1 style={{textAlign: 'center'}}>Upload from multiple sources</h1>
                    <img src={MobileUpload} alt="Multiple Sources Upload"/>
                </div>
                <div className="mobileFeaturesDiv">
                    <h1 style={{textAlign: 'center'}}>Execute any CRUD Action</h1>
                    <CRUDActions/>
                </div>
                <div className="mobileFeaturesDiv">
                    <h1 style={{textAlign: 'center'}}>Simple & Robust API</h1>
                    <API/>
                </div>
                <UpdatedCTA style={{marginTop: "50px"}}/>
                <br/><br/><br/>
                <hr/>
                <div className="mobileFeaturesDiv">
                    <HowDoesThisWork landing={true}/>
                </div>

            </div>
        )
    }
}

export default MobileLandingPage