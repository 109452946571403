import React, {Component} from 'react'

class StartUpdate extends Component {
    render() {
        return (
            <span>
                <h6><b>1. Query will be in a similar format to SQL</b></h6>
                <p>Only <b>select</b>, <b>where</b> and <b>from</b> identifiers are allowed. Other SQL identifiers like <b>order by</b> and <b>group by</b> are not allowed</p>
                <p>Only <b>*</b> is allowed as the selector. Columns that are to be updated are specified in the <b>Request Body</b></p>
                <div className="codeTheoryBox">
                    <span>select<code>*</code>from<code>fileID</code>where<code>filterClause</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <b>Valid</b>
                    <span>select<code>*</code>from<code>10232</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <b>Valid</b>
                    <span>select<code>*</code>from<code>814</code>where<code>{"country='usa'ORcountry='australia'ORcountry='indonesia'"}</code></span>
                </div>
                <br/>
                <div className="codeInvalidBox">
                    <b>Invalid</b>
                    <span>select<code>name,address</code>from<code>10232</code>where<code>id>5</code></span>
                </div>
                <br/>
                <div className="codeInvalidBox">
                    <b>Invalid</b>
                    <span>select<code>*</code>from<code>814</code>where<code>name='JFK'</code>groupby<code>date</code></span>
                </div>
                <br/>
            </span>
        )
    }
}

export default StartUpdate