import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import { isBrowser } from "react-device-detect";
import {modifyEditID} from "../../Actions/ModifyEditID";
import {toggleModal} from "../../Actions/ToggleModal";
import ImportSheets from '../ImportSheets'
import FixColumnNamesTable from "./FixColumnNamesTable";
import ReuploadFile from "../ReuploadFile";
import RemoveFile from "../RemoveFile"

class FixColumnNames extends Component {
    constructor(props){
        super(props);

        this.openEditModal = this.openEditModal.bind(this);
    }

    openEditModal(){
        this.props.modifyEditID(this.props.pkID)
        this.props.toggleModal("editModal")
    }


    render() {
        let fileInformation = this.props.fileInformation[this.props.pkID]
        const colInfo = JSON.parse(JSON.stringify(fileInformation.columnInformation));
        const fileType = fileInformation.inputType;

        let fileName = fileInformation.name;
        // const size = formatBytes(fileInformation.size);

        const isExcel = fileName.split('.').pop() !== "csv" && fileName.split('.').pop() !== "txt";
        const importSheets = isExcel ? <ImportSheets idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} /> : null;

        let fileNamesMap = {}

        if (colInfo.names.length > 0){
            for (let i=0; i<colInfo.names.length; i++){
                fileNamesMap[colInfo.names[i]] = {"fileColumn": colInfo["info"][colInfo.names[i]]["original"], "idx": i}
            }

            return (
                <div className="fixColumnNames">
                    <h3 className="thinHeading"><span style={{color: 'red'}}><Icon name="warning sign" /></span> Column Names in your File were not Valid</h3>
                    <div className="row">
                        <div className="col-sm-2" />
                        <div className="col-sm-8">
                            <div className="fixColumnNamesBox">
                                <h5>Change the Column Names in your <Icon name="file alternate outline" />File and {fileType === "file" ? "Reupload" : "Refresh"}</h5>
                                <FixColumnNamesTable columnNamesMap={fileNamesMap}/>
                                <br/>
                                <h5>AFTER you fix the Column Names {fileType === "file" ? "Reupload" : "Refresh"} your File</h5>
                                <ReuploadFile pk={this.props.pk} pkID={this.props.pkID} fixColumnNames/>
                                <br/>
                            </div>
                        </div>
                        <div className="col-sm-2" />
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-3" />
                        <div className="col-sm-6">
                            <div className="fixColumnNamesBox">
                                <h5>Or you can re-read your <Icon name="file alternate outline" />File with different options</h5>
                                <br/>
                                {isBrowser ? <div><Button content="File Options" basic color='purple' icon='edit outline' fluid onClick={this.openEditModal} /></div> : null}
                                <br/>
                                {importSheets}
                                <br/>
                            </div>
                        </div>
                        <div className="col-sm-3" />
                    </div>
                    <br/><br/>
                    <div className="row">
                        <div className="col-sm-3" />
                        <div className="col-sm-6">
                            <RemoveFile idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} isExcel={isExcel} />
                        </div>
                        <div className="col-sm-3" />
                    </div>
                </div>
            )
        }
        else{
            return(
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,

})

const mapActionsToProps = {
    modifyEditID: modifyEditID,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(FixColumnNames)