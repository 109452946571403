import React, {Component} from 'react'
// import LandingPageHero from "./LandingPageHero";
import { isBrowser } from 'react-device-detect'
import AppIndex from '../App/AppIndex'
import MobileLandingPage from './MobileLandingPage';
import AuthComponent from "../SharedComponents/AuthComponent";
// import WhatIsAnAPIButton from "./WhatIsAnAPIButton";
import UpdatedLandingPageIndex from "./UpdatedLandingPageIndex";

class LandingPageIndex extends Component {
    render() {
        const app = isBrowser ? <AuthComponent
            authComponent={<AppIndex/>}
            nonAuthComponent={<UpdatedLandingPageIndex/>}
        /> : <AuthComponent authComponent={<AppIndex/>} nonAuthComponent={<MobileLandingPage/>}/>;

        return (
            <div>
                {app}
            </div>
        )
    }
}

export default LandingPageIndex