import React, {Component} from 'react'
import {CardElement, injectStripe} from 'react-stripe-elements';
import API_Root from "../../Constants/API_Root";
import { Input, Message, Button } from 'semantic-ui-react'
import {getPricingText} from "../../Constants/getPricingText";

class CreditCardDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cardSuccess: null,
            name: '',
            submitDisabled: true,
            loading: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);

    }

    dismissMessage(){
        this.setState({ cardSuccess: null })
    }

    handleInputChange(e){
        this.setState({
            name: e.target.value
        })
    }

    async submit(e) {
        this.setState({
            loading: true
        })

        const failedMessage = "Your Payment Failed!";
        let {token} = await this.props.stripe.createToken({name: "Name"});

        if (token !== undefined){
            let response = await fetch(API_Root + "api/charge-api-spreadsheets/", {
                method: "POST",
                headers: {"Content-Type": "text/plain"},
                body: JSON.stringify({
                    stripeToken: token.id,
                    email: localStorage.getItem("email"),
                    name: this.state.name,
                    token: localStorage.getItem("token"),
                    plan: this.props.plan,
                    currency: this.props.currency
                })
            }).then().catch(err =>
                this.setState({
                    loading: false,
                    cardSuccess: <Message error
                                          onDismiss={this.dismissMessage}
                                          header={failedMessage}
                                          content="Please try again!"
                    />
                })
            );

            if (response !== undefined){
                if (response.ok){
                    this.setState({
                        loading: false,
                        cardSuccess: <Message positive={true}
                                              onDismiss={this.dismissMessage}
                                              header="Your payment was a success!"
                        />
                    })

                    this.props.changeProcess("completed")
                }
                else{
                    this.setState({
                        loading: false,
                        cardSuccess: <Message error
                                              onDismiss={this.dismissMessage}
                                              header={failedMessage}
                                              content="Please try again!"
                        />
                    })
                }
            }

        }
        else{
            this.setState({
                loading: false,
                cardSuccess: <Message error
                                      onDismiss={this.dismissMessage}
                                      header={failedMessage}
                                      content="Please enter all details and try again!"
                />
            })
        }
    }

    render() {
        return (
            <div className="checkout">
                    <Input icon="user"
                           iconPosition='left'
                           placeholder="Name on Card"
                           onChange={this.handleInputChange} value={this.state.name} fluid />
                    <br/>
                    <div className="semanticBorder">
                        <CardElement />
                    </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button loading={this.state.loading} onClick={this.submit} color="purple" style={{textAlign: 'center'}} fluid>
                        {"Upgrade for " +  getPricingText(this.props.currency, this.props.plan) + " per month"}
                        <span className="emoji" role="img" aria-label="smiley-emoji">😊</span>
                    </Button>
                </div>
                <br/>
                {this.state.cardSuccess}
            </div>
        )
    }
}

export default injectStripe(CreditCardDetails)