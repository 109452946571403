import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../Actions/ToggleModal";
import API_Root from "../Constants/API_Root";
import isObjectEmpty from "../Constants/isObjectEmpty";
import {removePKFromArray} from "../Constants/removePKFromArray";
import copyObject from "../Constants/copyObject";
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import {initUploadedPK} from "../Actions/InitUploadedPK";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {toggleToast} from "../Actions/ToggleToast";

class RefreshFile extends Component {
    constructor(props) {
        super(props);

        this.refreshFile = this.refreshFile.bind(this);
    }

    refreshFile(e){
        let modalName = this.props.inputType === "googleSheets" ? "loadingModalGoogleSheetsRefresh" : "loadingModalDropboxRefresh";
        this.props.toggleModal(modalName);

        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/read-api-sheets-dropbox/");

        // Offset to get created dt
        const current = new Date();
        const utcOffset = current.getTimezoneOffset();

        let filePK = [{'id': this.props.pkID, 'pk': this.props.pk}];
        console.log(filePK);

        // Send the PKs and if any column names
        socket.onopen = (event) => {
            socket.send(JSON.stringify({
                utcOffset: utcOffset,
                filePK: filePK,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                sheetRead: this.props.sheetRead,
                refresh: true
            }))
        };

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            if (status === "read"){
                let filePKProBlock = reply['filePKProBlock'];

                if (typeof this.props.filePKProBlock !== 'undefined'){
                    if (!isObjectEmpty(this.props.filePKProBlock)){
                        filePKProBlock = copyObject(this.props.filePKProBlock, reply['filePKProBlock']);
                    }
                }

                this.props.modifyFilePKProBlock(filePKProBlock);
            }

            if (status === "api"){
                // props.modifyStatus(status);
                let fileInformation = reply['fileInformation'];
                let sheetRead = reply['sheetRead'];

                if (typeof this.props.fileInformation !== 'undefined'){
                    if (Object.entries(this.props.fileInformation).length !== 0 && this.props.fileInformation.constructor === Object){
                        fileInformation = copyObject(this.props.fileInformation, reply.fileInformation);
                        sheetRead = copyObject(this.props.sheetRead, reply.sheetRead);
                    }
                }

                this.props.modifyFileInformation(fileInformation);
                this.props.modifySheetRead(sheetRead);
                this.props.toggleModal(null);
                socket.close()

            }

            if (status === "error"){
                socket.close()
                this.props.initUploadedPK(removePKFromArray(this.props.uploadedPK, filePK))
                this.props.modifyFilePK(removePKFromArray(this.props.filePK, filePK));

                this.props.toggleModal(null);
                const errorMessage = 'errorMessage' in reply ? reply['errorMessage'] : "There was an error refreshing your file from your source 😞. Please try again. Contact our Support if this error persists."
                this.props.toggleToast({show: true, message: errorMessage, type: "error"})
            }
        }

    }

    render() {
        return (
            <div>
                <Button content="Refresh File" basic color='green' icon='refresh' onClick={this.refreshFile} fluid />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock,
    fileInformation: state.mainState.fileInformation,
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyFilePKProBlock: modifyFilePKProBlock,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    initUploadedPK: initUploadedPK,
    modifyFilePK: modifyFilePK,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(RefreshFile)