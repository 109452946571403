import React, { Component } from 'react';
import AppRedux from "./AppRedux"

class App extends Component{
    render(){
        return(
            <div>
                <AppRedux/>
            </div>
        )
    }
}

export default App;
