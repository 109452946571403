import React, {Component} from 'react'

class StartDelete extends Component {
    render() {
        return (
            <span>
                <h6><b>1. Query will be in a similar format to SQL</b></h6>
                <p>Only <b>delete</b>, <b>where</b> and <b>from</b> identifiers are allowed. Other SQL identifiers like <b>order by</b> and <b>group by</b> are not allowed</p>
                <div className="codeTheoryBox">
                    <span>deletefrom<code>fileID</code>where<code>filterClause</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <span>deletefrom<code>10232</code>where<code>{"country='usa'ORcountry='australia'ORcountry='indonesia'"}</code></span>
                </div>
                <br/>
            </span>
        )
    }
}

export default StartDelete