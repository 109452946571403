import React from 'react'
import { Popup } from 'semantic-ui-react'
import PricingPopupBody from "../Pricing/PricingPopupBody";
import NavbarSignup from '../../SharedComponents/Navbar/NavbarSignUp'

const pricingPopup = <Popup flowing hoverable content={<PricingPopupBody/>} trigger={<span className="linkStyle">pricing </span> }/>

export const HelpInfo = [
    {
        info: {
            icon: 'question circle outline',
            name: 'General'
        },
        faq:[
            {question: "What is this all about?", answer: "API Spreadsheets is the easiest way for you to use your spreadsheets as a REST API/CRUD Database"},
            {question: "Why would I need to use my spreadsheets as an API?", answer: <span>Lots of reasons! Some of the most commons ones we have seen are <br/>
                    1. when it's a simple data collection process that doesn't require a database<br/>
                    2. when you are working with non-tech people and want them to easily see/edit data <br/>
                    3. when you have a bunch of data in spreadsheets that you want to use in an application <br/><br/>
                    You can check out our <a href="tutorials">Use Case based Tutorials</a> to learn more
                </span>},
            {question: "Can't I just use Google Sheets API or something else?", answer: <span>You sure can but like many of our customers, you will quickly see how cumbersome and feature deficient Google Sheets and other tools are. Check out how easy it is to get started with us on our <a href="guides/get-started-with-api-spreadsheets">API Quickstart</a> guide. And check out all our <a href="features">features</a>!</span>},
            {question: "Cool, I am ready to get started!", answer: <span>Just <NavbarSignup/>, upload your file and get started from your File Information box!<br/> You can also read more details on how to get started in our the <a href="guides">Guides</a>. </span>},
        ]
    },
    {
        info: {
            icon: 'file alternate outline',
            name: 'local-file'
        },
        faq:[
                {question: "What kind of files can I upload?", answer: "You can upload files with these extensions: .xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .csv, .txt"},
                {question: "How do I upload the files?", answer: <span>You can click on upload and select them or you can drag and drop them.</span>, image: {src: "https://images2.imgbox.com/e9/43/jg63PL0G_o.png", alt: "Upload Local File"}},
                {question: "Can I upload files only the first time?", answer: <span>Nope! You can replace the file in your File Information box. This preserves your API URL </span>, image: {src: "https://images2.imgbox.com/7c/7b/7rqLKmwJ_o.png", alt: "Replace Local File", width: 'auto'}},
                {question: "I need to view my file. How can I do that?", answer: <span>You can download the file in your File Information box</span>, image: {src: "https://images2.imgbox.com/76/d8/Exr7gbMC_o.png", alt: "Download Local File", width: 'auto'}},
                {question: "What size files can I upload?", answer: <span>Anything under 1,500 rows you can upload for free. See our {pricingPopup} to learn more!</span>},
        ]
    },
    {
        info: {
            icon: 'google drive',
            name: 'google-sheets'
        },
        faq:[
                {question: "How Do I Connect to Google Sheets?", answer: <span>Click on Google Sheets and follow the prompt.</span>, image: {src: "https://images2.imgbox.com/86/9c/oN6wHfxm_o.png", alt: "Upload Google Sheets"}},
                {question: "How many Google ACCOUNTS can I connect to?", answer: "As many as you want"},
                {question: "How many Google SHEETS can I connect to?", answer: "As many as you want"},
                {question: "What size Google Sheets can I upload?", answer: <span>Anything under 1,500 rows you can upload for free. See our {pricingPopup} to learn more!</span>},
        ]
    },
    {
        info: {
            icon: 'dropbox',
            name: 'dropbox'
        },
        faq:[
                {question: "What kind of files can I connect to in my Dropbox?", answer: "You can connect to files with these extensions: .xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .csv, .txt"},
                {question: "How many Dropbox ACCOUNTS can I connect to?", answer: "You can only connect to ONE account at a time. If you pick a file from another account, you will override the other account."},
                {question: "How many Dropbox FILES can I connect to?", answer: "As many as you want from your ONE account."},
                {question: "How do I connect to the files?", answer: <span>You can click on upload and select them or you can drag and drop them</span>, image: {src: "https://images2.imgbox.com/be/a4/Cxg8QqrW_o.png", alt: "Upload Dropbox"}},
                {question: "What size files can I connect to?", answer: <span>Anything under 1,500 rows you can upload for free. See our {pricingPopup} to learn more!</span>},
        ]
    },
    {
        info: {
            icon: 'book',
                name: 'read-file'
        },
        faq: [
                {question: "How are sheets in Excel Files read?", answer: "We automatically read the first sheet. Then you can read any of the other sheets in the file"},
                {question: "Can I change the sheet that was read?", answer: <span>Yes, of course. You can click on the Change Sheet button and pick the valid sheet</span>, image: {src: "https://images2.imgbox.com/d2/02/vblDGc71_o.png", alt: "Change Sheet Button", width: "auto"}},
                {question: "How are CSV files read?", answer: "They are automatically read with a comma delimiter. You can change the delimiter in the read options"},
                {question: "Can I change my CSV delimiter?", answer: <span>Yes, of course. You can click on the File Options button and pick the correct delimiter</span>, image: {src: "https://images2.imgbox.com/a5/ed/Ih8LbEKW_o.png", alt: "File Options Menu", width: 'auto'}},
                {question: "How do you find the column names?", answer: <span>We read the first row in the file as the header. You can change that in the read options. Column Names must be unique. You can read about it in our <a href="guides/column-names">Column Names</a> guide.</span>},
        ]
    },
    {
        info:{
            icon: 'code',
            name: 'api'
        },
        faq: [
                {question: 'How do I get my API URL?', answer: <span>You can get your Base API URL in your File Information box when you upload your file and afterwards in the files tab.</span>},
                {question: 'What kind of operations can I perform with the API?', answer: 'You can perform all CRUD operations: Create, Read, Update and Delete'},
                {question: 'How do I perform these operations?', answer: <span>You make a GET request for Read or Delete or a POST request with Create or Update. You can read more details in the <a href="docs">API Docs</a> or the <a href="guides">Guides</a>. Or you can get started quickly and use the code snippets in your File Information boxes.</span>, image: {src: "https://images2.imgbox.com/35/f6/YtfF2g9X_o.png", alt: "Get Started Code"}},
                {question: "What does making the API Private mean?", answer: "Making the API Private will give you an access and secret key for that API. This feature is NOT available on the free plan"},
                {question: 'What is your API up time?', answer: <span>We guarantee a constant up time and advise you at least 72 hours before any changes are to be made or we are expecting any downtime. You can check out the <a href="https://api-apispreadsheets-com.statuspal.io/" target="_blank" rel="noopener noreferrer">status</a> </span>},
                {question: "How many requests can I make in a day?", answer: <span>It depends on your plan. On the free plan you can make 1500 requests per day. See our {pricingPopup} to learn more</span>},
                {question: "How can I see how many requests I have made in a day?", answer: <span>You can click on the API Usage button under file options.</span>, image: {src: "https://images2.imgbox.com/dc/63/uHg8Au3W_o.png", alt: "API Usage Button", width: "auto"}},
        ]
    },
    {
        info: {
            icon: 'lock',
            name: 'security'
        },
        faq: [
                {question: 'How is my data secured?', answer: 'We use HTTPS/SSL in transmitting data to our server to keep it secure. We also store the data under encryption keys only accessible to one person.'},
                {question: 'Do you guys store any data?', answer: 'Yes, we store any local files you upload. If you delete the file then it is removed from our servers and database. We do NOT store your data from Google Sheets or Dropbox files.'},
                {question: 'How can I trust you with the data?', answer: "It's in our privacy policy and Terms & Conditions that we do not use the file data for ANY purpose."},
                {question: "Do you offer on-prem installation?", answer: <span>Yes! You can <a className="linkStyle" href="/contact-us">Contact Us</a> or <a className="linkStyle" href="mailto:info@apispreadsheets.com?Subject=On%20Prem%20Installation">Email Us</a> and we will get the process started.</span>}
        ]
    },
    {
        info: {
            icon: 'dollar sign',
            name: 'Billing'
        },
        faq: [
                {question: 'How do I upgrade my plan?', answer: <span>You can do it through your Profile tab or the Pricing Page</span>, image: {src: "https://images2.imgbox.com/2f/b1/GJm7ZRGX_o.png", alt: "Upgrade Button", width: "auto"}},
                {question: 'How do I pay for the plan?', answer: <span>We accept all Credit Cards using <a href="https://www.stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a> as the payment provider, so we don't store or see your credit card number. If you wish to pay via invoice or any other payment methods, please <a href="contact-us">Contact Us</a>.</span>},
                {question: 'Are there any other fees?', answer: <span>Nope, the price you see in our {pricingPopup} is the final price!</span>},
                {question: 'What does a month mean?', answer: "A month begins and ends on the date you signed up. For ex: if you signed up on August 13th, then your month will renew on September 13th, October 13th and so on."},
                {question: 'Do I receive any receipt of the monthly plan?', answer: 'Yes, we send an invoice to your email each month your account is active.'},
                {question: "How long can I use the free plan for?", answer: "As long as you want"},
                {question: "What if I want to switch plans?", answer: <span>You can do it through your Profile tab or the Pricing Page</span>},
                {question: "What if I want to cancel my account?", answer: <span>Simply email <a href="mailto:cancel@apispreadsheets.com?Subject=API%20Spreadsheets%Cancel%20Account"> cancel@apispreadsheets.com</a>from the email you signed up with and we will cancel your account!</span>}
        ]
    },
]