import React, {Component} from 'react'
import { Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';

class NavbarSignUp extends Component {
    constructor(props) {
        super(props);

        this.signUp = this.signUp.bind(this);
    }

    signUp(e){
        this.signupLink.click()

        if ('toggleOpen' in this.props){
            this.props.toggleOpen(e)
        }
    }

    render() {
        return (
            <span>
                <a
                    style={{display: 'none'}}
                    ref={signupLink => this.signupLink = signupLink}
                    href="/signup"
                >Download hidden</a>
                <Button color="#52bd95" onClick={this.signUp} style={{marginTop: '2px', backgroundColor: "#52bd95", color: 'white'}}>
                    Sign Up
                </Button>
            </span>
        )
    }
}

export default withRouter(NavbarSignUp);