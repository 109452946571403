import React, {Component} from 'react'
import FAQBlock from "./FAQBlock";
import { HelpInfo } from "./HelpInfo"
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";

class HelpDisplay extends Component {
    render() {
        let FAQs = [];
        let icon = null;
        let title = null;

        const menuItem = this.props.menuItem;

        for (let i=0; i<HelpInfo.length; i++){
            if (HelpInfo[i].info.name === menuItem){
                FAQs = HelpInfo[i].faq
                icon = HelpInfo[i].info.icon
                title = HelpInfo[i].info.name === "api" ? HelpInfo[i].info.name.toUpperCase() : capitalizeHyphenated(HelpInfo[i].info.name)
                break;
            }
        }

        return (
            <FAQBlock
                FAQs={FAQs}
                icon={icon}
                title={title}
            />
        )
    }
}

export default HelpDisplay