import React, {Component} from 'react'
import StartRead from "./StartRead";
import StartDelete from "./StartDelete";
import StartUpdate from "./StartUpdate";

class QuickstartRules extends Component {
    render() {
        const actionTypeSubheading = this.props.actionType.replace("Action", "")

        const startRows = {
            readAction: <StartRead/>,
            deleteAction: <StartDelete/>,
            updateAction: <StartUpdate/>
        }

        const select1 = <span>select<code>*</code></span>
        const select2 = <span>select<code>id,name</code></span>
        const deleteQuery = <span>delete</span>

        const queryStarts = {
            readAction: Math.random() > 0.5 ? select1 : select2,
            updateAction: select1,
            deleteAction: deleteQuery
        }

        return (
            <div className="queryQuickstartRulesDiv">
                <h5>Rules for Writing a Query</h5>
                <h6><i>{actionTypeSubheading.charAt(0).toUpperCase() + actionTypeSubheading.substring(1, actionTypeSubheading.length)}</i></h6>
                <br/>
                {startRows[this.props.actionType]}
                <br/>
                <h6><b>2. No spaces anywhere EXCEPT quoted values in the <code>filterClause</code>. Values must be single quoted <code>''</code></b></h6>
                <div className="codeExampleBox">
                    <b>Valid</b>
                    <span>{queryStarts[this.props.actionType]}from<code>73</code>where<code>{"name<>'CJ Cregg'ANDid>5"}</code></span>
                </div>
                <br/>
                <div className="codeInvalidBox">
                    <b>Invalid</b>
                    <span>{queryStarts[this.props.actionType]}    from     <code>73</code>   where<code>{'name<>"CJ Cregg" and id > 5'}</code></span>
                </div>
                <br/>
                <h6><b>3. <code>filterClause</code> can contain any SQL comparison operators. <code>NULL</code> is NOT allowed. Instead use a blank string <code>''</code></b></h6>
                <ul>
                    <li> <code>=</code>
                        <div className="codeExampleBox">
                            <span>{queryStarts[this.props.actionType]}from<code>73</code>where<code>{"name='CJ Cregg'"}</code></span>
                        </div>
                        <br/>
                    </li>
                    <li> <code>{"<>"}</code>
                        <div className="codeExampleBox">
                            <span>{queryStarts[this.props.actionType]}from<code>73</code>where<code>{"name<>''"}</code></span>
                        </div>
                        <br/>
                    </li>
                    <li> <code>{">"}</code>, <code>{">="}</code>
                        <div className="codeExampleBox">
                            <span>{queryStarts[this.props.actionType]}from<code>73</code>where<code>{"id>5"}</code></span>
                        </div>
                        <br/>
                    </li>
                    <li> <code>{"<"}</code>, <code>{"<="}</code>
                        <div className="codeExampleBox">
                            <span>{queryStarts[this.props.actionType]}from<code>73</code>where<code>{"id<=21"}</code></span>
                        </div>
                        <br/>
                    </li>
                </ul>
                <br/>
                <h6><b>4. <code>filterClause</code> can contain ONLY the 3 following SQL logical operators. <code>and</code>, <code>or</code>, <code>in</code>& <code>not in</code></b></h6>
                <ul>
                    <li> <code>and</code>
                        <div className="codeExampleBox">
                            <span>{queryStarts[this.props.actionType]}from<code>73</code>where<code>{"name='CJ Cregg'ANDid>5"}</code></span>
                        </div>
                        <br/>
                    </li>
                    <li> <code>or</code>
                        <div className="codeExampleBox">
                            <span>{queryStarts[this.props.actionType]}from<code>73</code>where<code>{"name='CJ Cregg'ORid<>''"}</code></span>
                        </div>
                        <br/>
                    </li>
                    <li> <code>in & not in</code> (<code>not</code> can only be used with <code>in</code>)
                        <div className="codeExampleBox">
                            <span>{queryStarts[this.props.actionType]}from<code>73</code>where<code>{"nameIN('CJ Cregg', 'Talulah Riley')"}</code></span>
                        </div>
                        <br/>
                        <div className="codeExampleBox">
                            <span>{queryStarts[this.props.actionType]}from<code>73</code>where<code>{"nameNOTIN('Adam Sandler', 'TOBY')"}</code></span>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}

export default QuickstartRules