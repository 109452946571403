import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Checkbox } from 'semantic-ui-react'
import {toggleDarkMode} from "../../Actions/ToggleDarkMode";

class ToggleDarkModeButton extends Component {
    constructor(props) {
        super(props);

        this.toggleDarkMode = this.toggleDarkMode.bind(this);
    }

    toggleDarkMode(e){
        this.props.toggleDarkMode(!this.props.isDarkMode)
    }

    render() {
        return (
            <span>
                <label className="switch">
                    <input type="checkbox" className="toggleButton" onChange={this.toggleDarkMode} checked={this.props.isDarkMode} />
                    <span className="slider round"></span>
                </label>

                <span style={{color: this.props.isDarkMode ? "white" : "black", paddingLeft: "5px", marginTop: "5px"}}>Dark Mode?</span>
            </span>
        )
    }
}

const mapStateToProps = (state) => ({
    isDarkMode: state.mainState.isDarkMode
})

const mapActionsToProps = {
    toggleDarkMode: toggleDarkMode
}

export default connect(mapStateToProps, mapActionsToProps)(ToggleDarkModeButton)