import { auth } from './firebase';

//Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
    auth.createUserWithEmailAndPassword(email, password);

//Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);

//Sign Out
export const doSignOut = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    auth.signOut();
}

// Password Reset
export const doPasswordReset = (email) =>
    auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) =>
    auth.currentUser.updatePassword(password);

// Reset Password sent via email
export const confirmPasswordReset = (code, updatedPassword) =>
    auth.confirmPasswordReset(code, updatedPassword);

// Delete User
export const deleteUser = () =>
    auth.currentUser.delete()