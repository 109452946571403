import React, {Component} from 'react'
import pricing from "../../Constants/Pricing";
import AddCommaToNumbers from "../../Constants/AddCommaToNumbers";
import { Icon } from 'semantic-ui-react'
import {connect} from "react-redux";
import {getPricingText} from "../../Constants/getPricingText";

class PricingPopupBody extends Component {
    render() {
        return (
            <div id="pricingPopupBody">
                <table>
                    <tr id="pricingPopupBodyHeader">
                        <td />
                        <th>FREE</th>
                        <th>PRO</th>
                        <th>TEAM</th>
                        <th>ENTERPRISE</th>
                    </tr>
                    <tr id="pricingPopupBodyPricing">
                        <th />
                        <td>{getPricingText(this.props.currency, 0)}</td>
                        <td>{getPricingText(this.props.currency, 1)}</td>
                        <td>{getPricingText(this.props.currency, 2)}</td>
                        <td>custom</td>
                    </tr>
                    <tr id="pricingPopupBodyEvenRow">
                        <th>Rows per File</th>
                        <td>{AddCommaToNumbers(pricing[0].rowMax.toString())}</td>
                        <td>{AddCommaToNumbers(pricing[1].rowMax.toString())}</td>
                        <td>{AddCommaToNumbers(pricing[2].rowMax.toString())}</td>
                        <td>unlimited</td>
                    </tr>
                    <tr id="pricingPopupBodyOddRow">
                        <th>Files</th>
                        <td>{AddCommaToNumbers(pricing[0].files.toString())}</td>
                        <td>{AddCommaToNumbers(pricing[1].files.toString())}</td>
                        <td>{AddCommaToNumbers(pricing[2].files.toString())}</td>
                        <td>unlimited</td>
                    </tr>
                    <tr id="pricingPopupBodyEvenRow">
                        <th>Requests per Day</th>
                        <td>{AddCommaToNumbers(pricing[0].requests.toString())}</td>
                        <td>{AddCommaToNumbers(pricing[1].requests.toString())}</td>
                        <td>{AddCommaToNumbers(pricing[2].requests.toString())}</td>
                        <td>unlimited</td>
                    </tr>
                    <tr id="pricingPopupBodyOddRow">
                        <th>Private?</th>
                        <td><Icon name={pricing[0].secure ? "check" : "remove"} /></td>
                        <td><Icon name={pricing[1].secure ? "check" : "remove"} /></td>
                        <td><Icon name={pricing[2].secure ? "check" : "remove"} /></td>
                        <td><Icon name="check" /></td>
                    </tr>
                    <tr id="pricingPopupBodyEvenRow">
                        <th>Support</th>
                        <td>{pricing[0].support}</td>
                        <td>{pricing[1].support}</td>
                        <td>{pricing[2].support}</td>
                        <td>Dedicated</td>
                    </tr>
                    <tr id="pricingPopupBodyCTA">
                        <th />
                        <td><a className="linkStyle" href="/signup">Get Started</a></td>
                        <td><a className="linkStyle" href="/signup?pricing=1">Get Started</a></td>
                        <td><a className="linkStyle" href="/signup?pricing=2">Get Started</a></td>
                        <td><a className="linkStyle" href="/contact-us">Contact Us</a></td>
                    </tr>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

export default connect(mapStateToProps, null)(PricingPopupBody)
