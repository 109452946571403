import React, {Component} from 'react'
import {connect} from 'react-redux'
import ImportSheetsButton from './ImportSheetsButton'
import {modifySheetRead} from "../Actions/ModifySheetRead";

class ImportSheetsBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            copiedSheetRead: JSON.parse(JSON.stringify(this.props.sheetRead))
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        // const copiedObject = JSON.parse(JSON.stringify(this.props.sheetRead));
        const copiedObject = JSON.parse(JSON.stringify(this.state.copiedSheetRead));
        copiedObject[this.props.editID.toString()] = value;

        // this.props.modifySheetRead(copiedObject)
        this.setState({ copiedSheetRead: copiedObject })
    }

    render() {
        const sheetNames = this.props.fileInformation[this.props.editID]['sheetNames'];
        // const sheetReadPK = this.props.sheetRead[this.props.editID.toString()];
        const sheetReadPK = this.state.copiedSheetRead[this.props.editID.toString()];

        let sheetOptions = [];

        for (let i=0; i<sheetNames.length; i++){
            let sheetName = sheetNames[i];

            sheetOptions.push(
                <div className="importSheetOptions">
                    <input style={{fontSize: '150%'}}
                           type="radio"
                           name="sheetPicked"
                           value={sheetName}
                           id={sheetName}
                           onChange={this.handleInputChange}
                           checked={sheetReadPK === sheetName}
                           key={i.toString()}
                    />
                    <label htmlFor={sheetName}>
                        <span style={{cursor: 'pointer', fontSize: '125%', paddingTop: '2px', paddingLeft: '7px'}}>{sheetName}</span>
                    </label>
                </div>
            )
        }

        const btnDisabled = sheetNames.length <= 1;

        return (
            <div>
                {sheetOptions}
                <br/>
                <ImportSheetsButton btnDisabled={btnDisabled}
                                    pk={this.props.pk}
                                    pkID={this.props.editID}
                                    sheetNames={sheetNames}
                                    copiedSheetRead={this.state.copiedSheetRead} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation,
    sheetRead: state.mainState.sheetRead
})

const mapActionsToProps = {
    modifySheetRead: modifySheetRead
}

export default connect(mapStateToProps, mapActionsToProps)(ImportSheetsBody)