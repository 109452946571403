import React, {Component} from 'react'
import API_Root from "../../Constants/API_Root";
import Loader from 'react-loader-spinner'
import isObjectEmpty from "../../Constants/isObjectEmpty";
import DatasetDownload from "./DatasetDownload"
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import DatasetSampleValues from "./DatasetSampleValues";
import AttributeDetails from "./AttributeDetails";
import DetailMetadata from "./DetailMetadata";
import { Message } from 'semantic-ui-react'
import FileSummaryBaseURL from "../../FileDnD/FileSummaryBaseURL";

class DatasetSpecificIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            fetching: false,
            data: {},
        }
    }

    componentDidMount(){
        this.setState({ fetching: true });

        const stopFetching = () => this.setState({ fetching: false })

        const id = window.location.href.substring(window.location.href.indexOf("datasets") + 9, window.location.href.length).replace("/", "").replace("\\", "")

        fetch(API_Root + "api/get-api-datasets/" + id + "/").then(res => {
            if (res.status === 200){
                res.json().then(data => this.setState({ fetching: false, data: data["datasets"]})).catch(err => stopFetching())
            }
            else {
                stopFetching()
            }
        }).catch(err => stopFetching())
    }

    render() {
        console.log(this.state.data)
        let body = null;
        if (this.state.fetching){
            body = <div style={{marginTop: '150px', textAlign: 'center'}}>
                <Loader
                    type="TailSpin"
                    color="black"
                    height="50"
                    width="50"
                />
                <h4 className="thinHeading" style={{color: 'black'}}>Getting Data...</h4>
            </div>
        }
        else{
            if (!isObjectEmpty(this.state.data)){
                body = <div style={{marginTop: '50px', marginBottom: '350px'}}>
                    <div className="row">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <img src={this.state.data.picture_url} alt={this.state.data.name} className="details-picture img-thumbnail" />
                        </div>
                        <div className="col-sm-5 dataset-header-names">
                            <h1 className="dataset-name">{capitalizeHyphenated(this.state.data.name, "_")}</h1>
                            <h4 className="thinHeading text-muted">{this.state.data.dataset_info['shortDesc']}</h4>
                            <DetailMetadata data={this.state.data}/>
                        </div>
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <DatasetDownload fileURL={this.state.data.file_url} curve/>
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row">
                        <div className="col-sm-1" />
                        <div className="col-sm-10">
                            <div style={{marginTop: '50px'}}>
                                {this.state.data.dataset_info['missingValues'] ? <Message header="Missing values are filled in with '?' for nominal and -100000 for numerical attributes" warning/> : null}
                            </div>
                            <DatasetSampleValues sampleValues={this.state.data.sample_values["values"]} rows={this.state.data.dataset_info['instances']}/>
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row">
                        <div className="col-sm-1" />
                        <div className="col-sm-10">
                            {(parseInt(this.state.data.dataset_info['instances']) * parseInt(this.state.data.dataset_info['attributes'])) < 100000
                                ? <div style={{marginTop: '50px'}}>
                                <h2>API</h2>
                                <FileSummaryBaseURL url={"api/dataset/" + this.state.data.name.replace("_", "-") + "/"}/>
                                </div>
                                : null
                            }
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row" style={{marginTop: '50px'}}>
                        <div className="col-sm-1" />
                        <div className="col-sm-6">
                            <AttributeDetails attributeInfo={this.state.data.attribute_info} name={this.state.data.name} />
                        </div>
                        <div className="col-sm-4">

                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row" style={{marginTop: '50px'}}>
                        <div className="col-sm-1" />
                        <div className="col-sm-10">
                            <h2>Description</h2>
                            {this.state.data.dataset_info['longDesc']}
                            <h2 style={{marginTop: '50px'}}>License</h2>
                            <p>{this.state.data.dataset_info['license']}</p>
                        </div>
                        <div className="col-sm-1" />
                    </div>
                </div>
            }
        }

        return (
            <div className="datasetIndex">
                {body}
            </div>
        )
    }
}

export default DatasetSpecificIndex