import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react';
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import API_Root from "../../Constants/API_Root";
import {toggleToast} from "../../Actions/ToggleToast";

class ColumnInfoSaveButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false
        }

        this.saveColumnInformation = this.saveColumnInformation.bind(this);
    }

    saveColumnInformation(e){
        this.setState({
            saving: true
        })

        let pk;

        for (let i=0; i<this.props.filePK.length; i++){
            if (this.props.filePK[i].id === this.props.editID){
                pk = this.props.filePK[i].pk
                break
            }
        }

        fetch(API_Root + "api/save-API-column-information/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                pk: pk,
                prevInfo: this.props.prevInfo,
                currInfo: this.props.currInfo
            })
        }).then((res) => {
            if (res.status === 201){
                res.json().then(data => {
                    const copiedFileInfo = JSON.parse(JSON.stringify(this.props.fileInformation))
                    copiedFileInfo[this.props.editID] = data.fileInformation
                    this.props.modifyFileInformation(copiedFileInfo);
                    this.props.toggleToast({show: true, message: "Column Information Updated!", type: "success"})
                }).catch(err => this.props.toggleToast({show: true, message: "These changes could not be saved", type: "error"}))
            }
            else{
                this.props.toggleToast({show: true, message: "These changes could not be saved", type: "error"})
            }
        }).catch(err => this.props.toggleToast({show: true, message: "These changes could not be saved", type: "error"}))

        this.setState({
            saving: false
        })
    }

    render() {
        return (
            <Button content="Save Changes" icon="save" fluid color="green" loading={this.state.saving} onClick={this.saveColumnInformation}/>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnInfoSaveButton)