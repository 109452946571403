import React, {Component} from 'react'
import {connect} from 'react-redux'
import Images from "../../Constants/Images"
import SocialMediaButtons from "./SocialMediaButtons"
import { Icon } from 'semantic-ui-react'
import FooterItem from "./FooterItem"
import GuideDetails from "../../Guides/GuideDetails";

class FullFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: ''
        };

        this.goToURL = this.goToURL.bind(this);
    }

    goToURL(e, url){
        this.setState({
            url: url
        }, () => this.hiddenLink.click())
    }

    render() {
        const hiddenURL = this.state.url;

        return (
            <div className="footerIndex fullFooter">
                <a style={{display: 'none'}} ref={hiddenLink => this.hiddenLink = hiddenLink} href={hiddenURL} target="_blank" rel="noopener noreferrer">Download hidden</a>
                <div className="row">
                    <div className="col-sm-3">
                        <div className="fullFooterBox">
                            <h3>Made by</h3>
                            <img alt="Love Spreadsheets Logo" src={Images.loveLogo} style={{cursor: 'pointer', width: '80%'}} onClick={(e) => this.goToURL(e, "https://www.lovespreadsheets.com/")}/>
                            <br/>
                            <p style={{color: 'white'}}><i>Easy Business Intelligence for Analysts & Small Biz</i></p>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="fullFooterBox">
                            <h3>COMPANY</h3>
                            <FooterItem to="https://www.lovespreadsheets.com/blog/" name="Blog" external/>
                            <FooterItem to="https://www.lovespreadsheets.com/about/" name="About" external/>
                            <FooterItem to="https://www.lovespreadsheets.com/contact/" name="Contact Us" external/>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="fullFooterBox">
                            {/*<h3>PRODUCT</h3>*/}
                            {/*<FooterItem to="features" name="Features" />*/}
                            {/*<FooterItem to={"guides/" + GuideDetails[0].title} name="Guides" />*/}
                            {/*<FooterItem to="FAQs" name="FAQs" />*/}
                            {/*<FooterItem to="tutorials" name="Use Cases" />*/}
                            {/*<FooterItem to="https://api-apispreadsheets-com.statuspal.io/" name="API Status" external />*/}
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="fullFooterBox apiSocialFooterBox">
                            <SocialMediaButtons/>
                            <img src={Images.logoNav} alt="API Spreadsheets Footer Logo" style={{float: 'right', width: '100%'}}/>
                            <br/>
                            <span><a href="/terms" target="_blank" rel="noopener noreferrer" style={{color: "whitesmoke"}}> Terms &
                                    Conditions</a> | <a href="/privacy" target="_blank" rel="noopener noreferrer" style={{color: "whitesmoke"}}>Privacy
                                    Policy</a></span>
                            <span style={{color: 'white'}}><Icon
                                name="copyright outline"/> 2020 Woyera Inc </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isDarkMode: state.mainState.isDarkMode
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FullFooter)