import React, {Component} from 'react'
import queryString from 'query-string';
import { auth } from '../../firebase';
import API_ROOT from "../../../Constants/API_Root";
import { Input, Button, Segment } from 'semantic-ui-react';
import {connect} from "react-redux";
import {push} from "react-router-redux";
import {toggleModal} from "../../../Actions/ToggleModal";
import ResetPasswordMessage from './ResetPasswordMessage'

class ResetPasswordIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            oobCode: '',
            email: '',
            passwordOne: '',
            error: null,
            message: null
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    componentWillMount(){
        let params = queryString.parse(this.props.location.search);
        const oobCode = params.oobCode;
        const apiKey = "AIzaSyAqYl2OmjDXEdk0xw8MGG6b44hsTrRtnrA";
        // const apiKey = params.apiKey;

        const API = "https://www.googleapis.com/identitytoolkit/v3/relyingparty/resetPassword?key=" + apiKey;

        fetch(API, {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                oobCode: oobCode
            })
        }).then(res => res.json())
            .then(data => {
                    this.setState({email: data.email, oobCode: oobCode})
                })
            .catch(err => this.props.navigateTo("/"));

    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changePassword(e){
        const {oobCode, email, passwordOne} = this.state;

        const completionMessage = (success) => this.setState({
            message: <ResetPasswordMessage dismissMessage={this.dismissMessage}
                                           success={success}/>
        });

        auth.confirmPasswordReset(oobCode, passwordOne)
            .then(() => {
                this.setState(() => ({
                    passwordOne: '',
                    error: null,
                    oobCode: '',
                    email: '',
                }));

                const API = API_ROOT + "api/reset-password/";

                fetch(API, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json, text/plain, */*',
                    },
                    body: JSON.stringify({
                        password: passwordOne,
                        email: email
                    })
                }).then(res =>
                    completionMessage(true)
                ).catch(err => completionMessage(false));
            })
            .catch(error => {
                completionMessage(false);
            });

    }

    dismissMessage(){
        this.setState({
            message: null
        })
    }

    render() {
        const {
            passwordOne,
            error,
            email
        } = this.state;


        const isInvalid =
            passwordOne === '' || passwordOne.length < 8 || error;

        const passwordLengthError = passwordOne.length < 8 ? <div><small style={{color: 'red'}}>Password must be at least 8 characters</small></div> : null;

        const bodyColor = "#008000";
        return (
            <div style={{minHeight: '100vh', backgroundColor: bodyColor}}>
                <div className="row">
                    <div className="col-sm-4" />
                    <div className="col-sm-4">
                    <br/><br/><br/>
                        <Segment raised>
                            <br/>
                            <h3 className="thinHeading">{"Changing Password for " + email}</h3>
                            <br/>
                            <Input
                                value={passwordOne}
                                onChange={this.handleInputChange}
                                type="password"
                                name="passwordOne"
                                placeholder="New Password"
                                error={passwordOne.length < 8}
                                fluid
                            />
                            {passwordLengthError}
                            <br/><br/>
                            <Button style={{textAlign: 'center'}} color="orange" disabled={isInvalid} onClick={this.changePassword}>
                                Reset Password
                            </Button>
                            {this.state.message}
                            <br/><br/>
                        </Segment>
                    </div>
                    <div className="col-sm-4" />
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal
}

export default connect(null,mapActionsToProps)(ResetPasswordIndex);