import React, {Component} from 'react'
import ModalApp from '../Modal'
import CompletePaymentForm from './CompletePaymentForm';

class PaymentModal extends Component {
    render() {
        const bodyColor = "#008000";
        let modalBody = null;
        let modalHeader = null;

        if (localStorage.getItem("email") !== null && localStorage.getItem("token") !== null){
            modalBody =
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                        <br/>
                        <CompletePaymentForm />
                        <br/><br/>
                    </div>
                    <div className="col-sm-1" />
                </div>;
            modalHeader = null;
        }

        return (
            <div>
                <ModalApp name="paymentModal"
                          bodyColor={bodyColor}
                          header={<span style={{color: 'white'}}>{modalHeader}</span>}
                          body={modalBody}
                          size="large"
                />
            </div>
        )
    }
}


export default PaymentModal