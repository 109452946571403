import React, {Component} from 'react'

class FixColumnNamesTable extends Component {
    render() {
        let columnTableRow = [];
        let key = 1;

        for (let transformedColumn in this.props.columnNamesMap){
            let fileColumnName = this.props.columnNamesMap[transformedColumn]['fileColumn'];

            if (fileColumnName.trim() !== transformedColumn){
                columnTableRow.push(
                    <tr key={key} className="fixColumnNamesTableBodyRow">
                        <td>{(this.props.columnNamesMap[transformedColumn]["idx"] + 1).toString()}</td>
                        <td>{fileColumnName}</td>
                        <td>Column Name is Duplicate</td>
                        <td>{transformedColumn}</td>
                    </tr>
                )

                key += 1
            }
        }


        return (
            <div className="fixColumnNamesTableDiv">
                <table className="fixColumnNamesTable">
                    <thead>
                        <tr className="fixColumnNamesTableHeaderRow">
                            <td>Column Number</td>
                            <td>Column Name</td>
                            <td>Problem?</td>
                            <td>Proposed Change</td>
                        </tr>
                    </thead>
                    <tbody>
                        {columnTableRow}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default FixColumnNamesTable