import React, {Component} from 'react'
import SignUpForm from './SignUpForm'
import images from "../../../Constants/Images";
import { Image } from 'semantic-ui-react'

class SignUpIndex extends Component {
    componentDidMount(){
        document.title = "Signup - API Spreadsheets"
    }

    render() {
        return (
            <div className="row" style={{backgroundColor: 'whitesmoke', minHeight: '100vh'}}>
                <div className="col-sm-3" />
                <div className="col-sm-6">
                    <br/><br/><br/>
                    <div style={{marginLeft: '1rem'}}>
                        <Image style={{marginTop: '5px'}} src={images.logoNav} href="/" size="medium"/>
                    </div>
                    <SignUpForm />
                    <br/>
                    <p style={{textAlign: 'center'}}>Already Have An Account? <a href="/login">Log In</a></p>
                </div>
                <div className="col-sm-3" />
            </div>
        )
    }
}

export default SignUpIndex