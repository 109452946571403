import React, {Component} from 'react'
import {connect} from 'react-redux'
import ReactJson from 'react-json-view'
import isObjectEmpty from "../Constants/isObjectEmpty";
import {getPostData} from "../Constants/getPostData";

class JSONOutputPreview extends Component {
    constructor(props) {
        super(props);

        this.makeMessage = this.makeMessage.bind(this);
    }

    makeMessage(jsonText, pkFileInfo){
        const outputFormat = this.props.outputFormat;

        const row = pkFileInfo['rowLength'];
        const col = pkFileInfo['colLength'];

        let message;

        // jsonRow, matrix, jsonColumn
        if(outputFormat === "jsonRow" || outputFormat === "matrix" || outputFormat === "row"){
            const sampleLength = jsonText['data'].length;
            message = "Showing " + sampleLength.toString() + " out of " + row.toString() + " rows"
        }
        else if (outputFormat === "jsonColumn" || outputFormat === "column") {

            let sample;

            if (!isObjectEmpty(jsonText)){
                for (let key in jsonText){
                    sample = jsonText[key].length;
                    break
                }
            }
            else{
                sample = 0;
            }

            message = "Showing all " + col.toString() + " columns. Showing " + sample.toString() + " out of " + row.toString() + " row values for each column"
        }
        else{
            message = "Showing all " + col.toString() + " columns"
        }

        return message
    }

    render() {
        const pkFileInfo = this.props.fileInformation[this.props.pkID];
        let outputFormat = this.props.outputFormat;
        let jsonText;

        if (outputFormat === "columnValue"){
            jsonText = JSON.parse(getPostData(pkFileInfo, "columnValue", this.props.apiType, this.props.pk, false, null))
        }
        else{
            if (outputFormat === "row"){
                outputFormat = "jsonRow"
            }
            else if (outputFormat === "column"){
                outputFormat = "jsonColumn"
            }

            jsonText = pkFileInfo[outputFormat + "Sample"];
        }

        const message = this.makeMessage(jsonText, pkFileInfo);

        const outputFormatDisplay = {'jsonRow': 'Row', 'matrix': 'Matrix (2D Array)', 'jsonColumn': 'Column', 'row': 'Row', 'column': 'Column', 'columnValue': 'Column Value'}

        return (
            <div style={{paddingTop: '10px'}}>
                <h6 className="thinHeading"><b>{outputFormatDisplay[this.props.outputFormat] + ": "}</b><i>{message}</i></h6>
                <ReactJson src={jsonText} name={null} theme="ocean" displayObjectSize={false}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(JSONOutputPreview)