import React, {Component} from 'react'

const allImgInfo = [
    {src: "https://www.goodlord.co/hubfs/goodlord-logo-large_green.svg", link: "https://www.goodlord.co/", name: "Goodlord", desc: "Software making renting simple"},
    {src: "https://project-static-assets.s3.amazonaws.com/APISpreadsheets/WildocrocLogo.png", link: "http://wildcroc.cz/", name: "Wildcroc", desc: "Web Consulting & Services", width: 222, height: 188},
    {src: "https://rolv.pl/wp-content/uploads/2019/07/RolvGroup500px.png", link: "https://rolv.pl/", name: "ROLV Group", desc: "Sales, E-Commerce and Marketing consulting", width: 500, height: 524},
    {src: "https://www.idea-ip.com/wp-content/uploads/2019/12/Novo-Logo_sem-fundo-768x454.png", link: "https://www.idea-ip.com/", name: "Idea-Ip", desc: "R&D and Engineering in Communication Products", width: 768, height: 454},
    {src: "https://cdn.shopify.com/s/files/1/0309/9521/files/silca-logo_35645e3f-fb5c-46c0-b57a-086cd1335bc8_410x.png?v=1556276613", link: "https://silca.cc/", name: "Silca", desc: "Premium Bicycle Parts", width: 410, height: 92}
]


class CustomerCarousel extends Component {
    render() {
        const height = 50;

        const logos = allImgInfo.map((x, idx) =>
            {
                return (
                    <div className="col-sm-2" >
                        <a href={x.link} target="_blank" rel="noopener noreferrer" className="nostyle" style={{textAlign: 'center'}}>
                            <div style={{margin: "0 auto"}}>
                                <img src={x.src} style={{width: 'width' in x ? (x.width / (x.height / 50)).toString() + "px" : "50%", height: height.toString() + "px"}} alt={x.name + " Logo"}/>
                            </div>
                            {/*<p><b>{x.desc}</b></p>*/}
                        </a>
                    </div>
                )
            }
        )
        return (
            <div>
                <h4 style={{textAlign: 'center', fontFamily: 'museo sans rounded, sans-serif'}}>Trusted by startups and small businesses</h4>
                <div className="row">
                    {"full" in this.props ? null : <div className="col-sm-1" />}
                    {logos}
                    {"full" in this.props ? <div className="col-sm-2" /> : <div className="col-sm-1" />}
                </div>
            </div>
        )
    }
}

export default CustomerCarousel