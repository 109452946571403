import React, {Component} from 'react'
import AuthUserContext from '../../Authentication/AuthUserContext';
import NavbarAuth from './NavbarAuth'
import NavbarNonAuth from './NavbarNonAuth'
import { isMobile } from 'react-device-detect'
import NavbarMobile from './NavbarMobile'
import { connect } from 'react-redux'

class NavbarIndex extends Component {
    render() {
        if (isMobile){
            return(
                <NavbarMobile />
            )
        }
        return (
            <div style={this.props.isDarkMode ? {backgroundColor: "black", color: "white"} : {backgroundColor: "white", color: "black"}}>
                <AuthUserContext.Consumer>
                    {  authUser => authUser
                        ? <NavbarAuth />
                        : <NavbarNonAuth />
                    }
                </AuthUserContext.Consumer>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isDarkMode: state.mainState.isDarkMode
})

export default connect(mapStateToProps, null)(NavbarIndex)