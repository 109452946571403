import React from 'react'
import { Route, Switch } from "react-router";
import LandingPageIndex from './LandingPage/LandingPageIndex'
import NotFound from './InfoPages/NotFound'
import PrivacyPolicy from './InfoPages/PrivacyPolicy'
import TermsAndConditions from './InfoPages/TermsAndConditions'
import ResetPasswordIndex from './Authentication/UserPages/Password/ResetPasswordIndex'
import PricingPage from './InfoPages/Pricing/PricingPage'
import HelpIndex from "./InfoPages/Help/HelpIndex";
import FilesAuthIndex from './FileDnD/FilesAuthIndex'
import DocsPage from './InfoPages/DocsPage/DocsPage'
import GuidesIndex from './Guides/GuidesIndex'
import LoginIndex from './Authentication/UserPages/LogIn/LoginIndex'
import SignUpIndex from './Authentication/UserPages/SignUp/SignUpIndex'
import ContactUsIndex from './InfoPages/ContactUs/ContactUsIndex'
import CompletePaymentIndex from './SharedComponents/Payment/CompletePaymentIndex'
import TutorialsIndex from "./InfoPages/Tutorials/TutorialsIndex";
import FeaturesIndex from "./InfoPages/Features/FeaturesIndex"
import UpgradeIndex from "./InfoPages/UpgradeIndex"
import CaseStudyIndex from "./InfoPages/CaseStudy/CaseStudyIndex"
import DatasetsIndex from "./InfoPages/Datasets/DatasetsIndex"
import DatasetSpecificIndex from "./InfoPages/Datasets/DatasetSpecificIndex"
import PromoIndex from "./InfoPages/Promo/PromoIndex"
import TutorialsDisplayIndex from "./InfoPages/Tutorials/TutorialsDisplayIndex"
import EmailUnsubscribeIndex from "./SharedComponents/EmailUnsubscribeIndex"

export default ({ childProps }) =>
    <Switch>
        <Route exact path='/' component={LandingPageIndex} />
        <Route exact path="/upload" component={LandingPageIndex} />
        <Route exact path='/files' component={FilesAuthIndex} />
        <Route exact path='/contact-us' component={ContactUsIndex} />
        <Route exact path='/reset-password/:id' component={ResetPasswordIndex} />
        <Route exact path='/promo' component={PromoIndex} />
        <Route exact path='/promo/amaf/' component={PromoIndex} />
        <Route exact path="/login" component={LoginIndex} />
        <Route exact path="/signup" component={SignUpIndex} />
        <Route exact path='/complete-payment' component={CompletePaymentIndex} />
        {/*Dumb Components aka Display Only and No Interaction with external data sources*/}
        <Route exact path='/FAQs' component={HelpIndex} />
        <Route exact path='/terms' component={TermsAndConditions} />
        <Route exact path='/features' component={FeaturesIndex} />
        <Route exact path='/privacy' component={PrivacyPolicy} />
        <Route exact path='/pricing' component={PricingPage} />
        <Route exact path="/docs" component={DocsPage} />
        <Route exact path="/guides" component={GuidesIndex} />
        <Route exact path="/guides/:id" component={GuidesIndex} />
        <Route exact path="/tutorials" component={TutorialsIndex} />
        <Route exact path="/tutorials/:id" component={TutorialsDisplayIndex} />
        <Route exact path="/upgrade" component={UpgradeIndex} />
        <Route exact path="/case-study/:id" component={CaseStudyIndex} />
        <Route exact path="/datasets" component={DatasetsIndex} />
        <Route exact path="/datasets/:id" component={DatasetSpecificIndex} />
        <Route exact path="/email/unsubscribe/:id" component={EmailUnsubscribeIndex} />
        <Route component={NotFound}/>
    </Switch>;