import React, {Component} from 'react'
import capitalizeHyphenated from "../Constants/capitalizeHyphenated";

class FeatureCard extends Component {
    render() {
        return (
            <div style={{textAlign: 'center', fontFamily: 'museo sans rounded, sans-serif'}}>
                <img src={this.props.img} style={{ height: '190px'}} alt={this.props.header}/>
                <br/><br/>
                <h2 style={{fontWeight: '700'}}>{capitalizeHyphenated(this.props.header)}</h2>
                <p style={{fontSize: '150%'}}>{this.props.desc}</p>
            </div>
        )
    }
}

export default FeatureCard