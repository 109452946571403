import React, {Component} from 'react'
import SelectOutputType from "../../SelectOutputType"
import ChangeRowLength from "../../ChangeRowLength"
import { Checkbox } from 'semantic-ui-react'
import {connect} from "react-redux";
import {toggleModal} from "../../../Actions/ToggleModal";
import {modifyEditID} from "../../../Actions/ModifyEditID";
import {modifyModalQueryQuickstartType} from "../../../Actions/ModifyModalQueryQuickstartType";
import BetaLabel from "../../../SharedComponents/BetaLabel";

class ReadOptionsToolbar extends Component {
    constructor(props) {
        super(props);

        this.changeState = this.changeState.bind(this);
        this.toggle = this.toggle.bind(this);
        this.openQueryModal = this.openQueryModal.bind(this);
    }

    changeState(name, value){
        this.props.changeState(name, value)
    }

    toggle(e){
        this.props.changeState("readQuery", !this.props.readQuery)
    }

    openQueryModal(e){
        this.props.modifyEditID(this.props.pkID)
        this.props.modifyModalQueryQuickstartType("readAction")
        this.props.toggleModal("queryQuickstartModal")
    }

    render() {
        return (
            <div className="row" style={{height: '25px'}}>
                <div className="col-sm-4">
                    <SelectOutputType idx={this.props.idx} outputFormat={this.props.outputFormat} changeState={this.changeState} />
                </div>
                <div className="col-sm-5">
                    <div style={{textAlign: 'center', marginLeft: '-100px'}}>
                    <span>
                        <BetaLabel/>
                        <Checkbox checked={this.props.readQuery}
                                  toggle
                                  onChange={this.toggle}
                                  label="Query data with filter?" />
                        <span className="linkStyle" onClick={this.openQueryModal}>  Read how </span>
                    </span>
                    </div>
                </div>
                <div className="col-sm-3">
                    <span style={{float: 'right'}}>
                        <ChangeRowLength pkID={this.props.pkID} rowLen={this.props.rowLen} rows={this.props.rows} changeState={this.changeState} apiType={this.props.apiType} />
                    </span>
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID,
    modifyModalQueryQuickstartType: modifyModalQueryQuickstartType
}

export default connect(null, mapActionsToProps)(ReadOptionsToolbar)