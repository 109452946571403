import React, {Component} from 'react'
import {createFileInformation} from "../../../Guides/GuidesText/Functions/createFileInformation";
import {createCodeSnippets} from "../../../Guides/GuidesText/Functions/createCodeSnippets";
import CodeSnippet from "../../../FileDnD/CodeSnippet";

class APIQuickstartCode extends Component {
    render() {
        const sampleFileInformation = createFileInformation([["Queen Liz", "94"],["Don Draper", "103"], ["Emma Watson", "30"]], ["name", "age"], ["str", "num"], 100, "Famous People", false, "https://api.apispreadsheets.com/data/100/?query=SELECTnameFROM100WHEREage>90")
        const codeSnippets = createCodeSnippets(sampleFileInformation, "readAction", "https://api.apispreadsheets.com/data/581/");

        return (
            <CodeSnippet
                code={codeSnippets}
                height={150}
            />
        )
    }
}

export default APIQuickstartCode