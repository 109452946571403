import React, {Component} from 'react'
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import { Button } from 'semantic-ui-react'
import PopupPlanDetails from "../../SharedComponents/Payment/PopupPlanDetails";
import {modifyPaymentModalOrigin} from "../../Actions/ModifyPaymentModalOrigin";
import { isMobile } from 'react-device-detect';

const plans = {
    "free": 0,
    "pro": 1,
    "team": 2
}

class PlanType extends Component {
    constructor(props){
        super(props)

        this.openPaymentModal = this.openPaymentModal.bind(this);
    }

    openPaymentModal(e){
        this.props.modifyPaymentModalOrigin(null)
        this.props.toggleModal("paymentModal")
    }

    render() {
        let title = this.props.pricingPlan.toUpperCase();
        const upgradeButton =
                    <Button color="green"
                            onClick={this.openPaymentModal}
                            content={<h5>Upgrade <span role="img" aria-label="smile" style={{paddingLeft: '2px'}}>😀</span> </h5>}
                            style={isMobile ? {marginTop: '10px'} : null}
                    />


        let upgrade = this.props.pricingPlan === "free" || this.props.pricingPlan === "pro" ? upgradeButton : null;

        return (
            <div id="profilePricingPlanRow">
                <div id="profilePricingPlan">
                    <PopupPlanDetails trigger={<h4><span className="faqSummary">{title}</span></h4>}
                                      plan={plans[this.props.pricingPlan]}
                    />
                </div>
                <div id="planTypeUpgradeButton">
                    {upgrade}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(mapStateToProps, mapActionsToProps)(PlanType)