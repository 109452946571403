import {TOGGLE_MODAL} from "../Actions/ToggleModal";
import {MODIFY_FILE_PK} from "../Actions/ModifyFilePK";
import {MODIFY_FILE_INFORMATION} from "../Actions/ModifyFileInformation";
import {TOGGLE_PRO_BLOCK} from "../Actions/ToggleProBlock";
import {MODIFY_STATUS} from "../Actions/ModifyStatus";
import {MODIFY_EDIT_OPTIONS} from "../Actions/ModifyEditOptions";
import {INIT_MERGED_VALUES} from "../Actions/InitMergedValues";
import {INIT_MERGED_COLUMN_NAMES} from "../Actions/InitMergedColumnNames";
import {MODIFY_EDIT_ID} from "../Actions/ModifyEditID";
import {MODIFY_SHEET_READ} from "../Actions/ModifySheetRead";
import {MODIFY_ADD_FILE_NAME} from "../Actions/ModifyAddFileName";
import {MODIFY_FILE_URL} from "../Actions/ModifyFileURL";
import {MODIFY_KEYS_PK} from "../Actions/ModifyKeysPK";
import {RESET_ALL_STATES} from "../Actions/ResetAllStates";
import {MODIFY_CUSTOM_CONTACT_FILES} from "../Actions/ModifyCustomContactFiles";
import {MODIFY_FILE_PK_PRO_BLOCK} from "../Actions/ModifyFilePKProBlock";
import {MODIFY_INTEGRATION_AUTH} from "../Actions/ModifyIntegrationAuth";
import {MODIFY_ANALYTICS_ID} from "../Actions/ModifyAnalyticsID";
import {MODIFY_TIER} from "../Actions/ModifyTier";
import {MODIFY_UPGRADE_TEXT} from "../Actions/ModifyUpgradeText";
import {TOGGLE_TOAST} from "../Actions/ToggleToast";
import {MODIFY_MODAL_QUERY_QUICKSTART_TYPE} from "../Actions/ModifyModalQueryQuickstartType";
import {MODIFY_PAYMENT_MODAL_ORIGIN} from "../Actions/ModifyPaymentModalOrigin";
import {INIT_ORIGINAL_FILE_PK} from "../Actions/InitOriginalFilePK";
import {MODIFY_MODAL_IMAGE_URL} from "../Actions/ModifyModalImageURL";
import {INIT_UPLOADED_PK} from "../Actions/InitUploadedPK";
import {MODIFY_CURRENCY} from "../Actions/ModifyCurrency";
import {INIT_USER_HAS_CURRENCY} from "../Actions/InitUserHasCurrency";
import {MODIFY_FILES_FETCHED_TIME} from "../Actions/ModifyFilesFetchedTime";
import {MODIFY_MODAL_INFO} from "../Actions/ModifyModalInfo";
import {TOGGLE_SHOWING_TUTORIAL} from "../Actions/ToggleShowingTutorial";
import {MODIFY_TUTORIAL_STEP} from "../Actions/ModifyTutorialStep";
import {MODIFY_NAVBAR_ITEM_CLICKED} from "../Actions/ModifyNavbarItemClicked";
import {MODIFY_SHOWING_MINOR_TUTORIALS} from "../Actions/ModifyShowingMinorTutorials";
import {TOGGLE_DARK_MODE} from "../Actions/ToggleDarkMode";

function mainState(
    state = {
        isDarkMode: false,
        filePK: [],
        fileInformation: {},
        editOptions: {},
        modalName: null,
        proBlock: false,
        status: "upload",
        mergedValues: '',
        mergedColumnNames: [],
        editID: null,
        sheetRead: {},
        addFileName: false,
        fileURL: '',
        keysPK: -1,
        customContactFiles: [],
        filePKProBlock: {},
        integrationAuth: {
            dropbox: false,
            googleSheets: false
        },
        analyticsID: null,
        tier: null,
        upgradeText: "",
        toast: {show: false, message: "", type: ""},
        modalQueryQuickstartType: "",
        paymentModalOrigin: null,
        originalFilePK: [],
        modalImageURL: null,
        uploadedPK: [],
        currency: "usd",
        userHasCurrency: false,
        filesFetchedTime: null,
        modalInfo: null,
        showingTutorial: false,
        tutorialStep: null,
        navbarItemClicked: "",
        showingMinorTutorials: {"columnMap": true, "refreshFile": true}
    }, action
) {
    switch (action.type) {
        case TOGGLE_DARK_MODE:
            return Object.assign({}, state, {
                isDarkMode: action.mainState.isDarkMode
            });
        case MODIFY_FILE_PK:
            return Object.assign({}, state, {
                filePK: action.mainState.filePK
            });
        case MODIFY_FILE_INFORMATION:
            return Object.assign({}, state, {
                fileInformation: action.mainState.fileInformation
            });
        case TOGGLE_MODAL:
            return Object.assign({}, state, {
                modalName: action.mainState.modalName
            });
        case TOGGLE_PRO_BLOCK:
            return Object.assign({}, state, {
                proBlock: action.mainState.proBlock
            });
        case MODIFY_STATUS:
            return Object.assign({}, state, {
                status: action.mainState.status
            });
        case MODIFY_EDIT_OPTIONS:
            return Object.assign({}, state, {
                editOptions: action.mainState.editOptions
            });
        case INIT_MERGED_VALUES:
            return Object.assign({}, state, {
                mergedValues: action.mainState.mergedValues
            });
        case INIT_MERGED_COLUMN_NAMES:
            return Object.assign({}, state, {
                mergedColumnNames: action.mainState.mergedColumnNames
            });
        case MODIFY_EDIT_ID:
            return Object.assign({}, state, {
                editID: action.mainState.editID
            });
        case MODIFY_SHEET_READ:
            return Object.assign({}, state, {
                sheetRead: action.mainState.sheetRead
            });
        case MODIFY_ADD_FILE_NAME:
            return Object.assign({}, state, {
                addFileName: action.mainState.addFileName
            });
        case MODIFY_FILE_URL:
            return Object.assign({}, state, {
                fileURL: action.mainState.fileURL
            });
        case MODIFY_KEYS_PK:
            return Object.assign({}, state, {
                keysPK: action.mainState.keysPK
            });
        case MODIFY_CUSTOM_CONTACT_FILES:
            return Object.assign({}, state, {
                customContactFiles: action.mainState.customContactFiles
            });
        case MODIFY_FILE_PK_PRO_BLOCK:
            return Object.assign({}, state, {
                filePKProBlock: action.mainState.filePKProBlock
            });
        case MODIFY_INTEGRATION_AUTH:
            return Object.assign({}, state, {
                integrationAuth: action.mainState.integrationAuth
            })
        case MODIFY_ANALYTICS_ID:
            return Object.assign({}, state, {
                analyticsID: action.mainState.analyticsID
            })
        case MODIFY_TIER:
            return Object.assign({}, state, {
                tier: action.mainState.tier
            })
        case MODIFY_UPGRADE_TEXT:
            return Object.assign({}, state, {
                upgradeText: action.mainState.upgradeText
            })
        case TOGGLE_TOAST:
            return Object.assign({}, state, {
                toast: action.mainState.toast
            })
        case MODIFY_MODAL_QUERY_QUICKSTART_TYPE:
            return Object.assign({}, state, {
                modalQueryQuickstartType: action.mainState.modalQueryQuickstartType
            })
        case MODIFY_PAYMENT_MODAL_ORIGIN:
            return Object.assign({}, state, {
                paymentModalOrigin: action.mainState.paymentModalOrigin
            })
        case INIT_ORIGINAL_FILE_PK:
            return Object.assign({}, state, {
                originalFilePK: action.mainState.originalFilePK
            })
        case MODIFY_MODAL_IMAGE_URL:
            return Object.assign({}, state, {
                modalImageURL: action.mainState.modalImageURL
            })
        case INIT_UPLOADED_PK:
            return Object.assign({}, state, {
                uploadedPK: action.mainState.uploadedPK
            })
        case MODIFY_CURRENCY:
            return Object.assign({}, state, {
                currency: action.mainState.currency
            })
        case INIT_USER_HAS_CURRENCY:
            return Object.assign({}, state, {
                userHasCurrency: action.mainState.userHasCurrency
            })
        case MODIFY_FILES_FETCHED_TIME:
            return Object.assign({}, state, {
                filesFetchedTime: action.mainState.filesFetchedTime
            })
        case MODIFY_MODAL_INFO:
            return Object.assign({}, state, {
                modalInfo: action.mainState.modalInfo
            })
        case TOGGLE_SHOWING_TUTORIAL:
            return Object.assign({}, state, {
                showingTutorial: action.mainState.showingTutorial
            })
        case MODIFY_TUTORIAL_STEP:
            return Object.assign({}, state, {
                tutorialStep: action.mainState.tutorialStep
            })
        case MODIFY_NAVBAR_ITEM_CLICKED:
            return Object.assign({}, state, {
                navbarItemClicked: action.mainState.navbarItemClicked
            })
        case MODIFY_SHOWING_MINOR_TUTORIALS:
            return Object.assign({}, state, {
                showingMinorTutorials: action.mainState.showingMinorTutorials
            })
        case RESET_ALL_STATES:
            return Object.assign({}, state, {
                filePK: action.mainState.filePK,
                fileInformation: action.mainState.fileInformation,
                editOptions: action.mainState.editOptions,
                modalName: action.mainState.modalName,
                proBlock: action.mainState.proBlock,
                status: action.mainState.status,
                mergedValues: action.mainState.mergedValues,
                mergedColumnNames: action.mainState.mergedColumnNames,
                editID: action.mainState.editID,
                sheetRead: action.mainState.sheetRead,
                addFileName: action.mainState.addFileName,
                fileURL: action.mainState.fileURL,
                keysPK: action.mainState.keysPK,
                customContactFiles: action.mainState.customContactFiles,
                filePKProBlock: action.mainState.filePKProBlock,
                integrationAuth: {
                    dropbox: false,
                    googleSheets: false
                },
                analyticsID: null,
                tier: null,
                upgradeText: "",
                toast: {show: false, message: "", type: ""},
                modalQueryQuickstartType: "",
                paymentModalOrigin: null,
                originalFilePK: [],
                modalImageURL: '',
                uploadedPK: [],
                currency: "usd",
                userHasCurrency: false,
                filesFetchedTime: null,
                modalInfo: null,
                showingTutorial: false,
                tutorialStep: null,
                navbarItemClicked: "",
                showingMinorTutorials: {"columnMap": true, "refreshFile": true}
            });
        default:
            return state
    }
}

export default mainState;

