import React, {Component} from 'react';
import {EmailIcon, FacebookShareButton, LinkedinShareButton, RedditShareButton, TwitterShareButton, TwitterIcon, FacebookIcon, LinkedinIcon, RedditIcon} from "react-share";

class SocialMediaButtons extends  Component {
    render() {
        return (
            <div className="footerShareGrid">
                <div className="socialMediaButton" id="twitterButton">
                    <TwitterShareButton
                        url={"vizspreadsheets.com"}
                        title={"I saved so much time with vizspreadsheets.com! Check it out if you need an easy solution to create a live dashboard from your spreadsheets or google sheets. "} >
                        <TwitterIcon
                            size={32}
                            round />
                    </TwitterShareButton>
                </div>
                <div className="socialMediaButton" id="facebookButton">
                    <FacebookShareButton
                        url={"vizspreadsheets.com"}
                        title={"I saved so much time with vizspreadsheets.com! Check it out if you need an easy solution to create a live dashboard from your spreadsheets or google sheets. "} >
                        <FacebookIcon
                            size={32}
                            round />
                    </FacebookShareButton>
                </div>
                <div className="socialMediaButton" id="redditButton">
                    <RedditShareButton
                        url={"vizspreadsheets.com"}
                        title={"I saved so much time with vizspreadsheets.com! Check it out if you need an easy solution to create a live dashboard from your spreadsheets or google sheets. "} >
                        <RedditIcon
                            size={32}
                            round />
                    </RedditShareButton>
                </div>
                <div className="socialMediaButton" id="linkedinButton">
                    <LinkedinShareButton
                        url={"vizspreadsheets.com"}
                        title={"I saved so much time with vizspreadsheets.com! Check it out if you need an easy solution to create a live dashboard from your spreadsheets or google sheets. "} >
                        <LinkedinIcon
                            size={32}
                            round />
                    </LinkedinShareButton>
                </div>
                <div className="socialMediaButton" id="emailButton">
                    <a href="mailto:info@vizspreadsheets.com?Subject=I%20Want%20To%20Convert%20Spreadsheets%20to%20API">
                        <EmailIcon size={32} round />
                    </a>
                </div>
            </div>
        );
    }
}


export default SocialMediaButtons