import React, {Component} from 'react'
import ModalApp from "../../SharedComponents/Modal"
import KeysModalBody from "./KeysModalBody"

class KeysModal extends Component {
    render() {
        return (
            <ModalApp name="keysModal"
                      header="Your Access & Secret Keys"
                      size="medium"
                      body={<KeysModalBody />}
            />
        )
    }
}

export default KeysModal