import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'
import SampleTable from "../SampleTable";
import DocsBaseURL from "../../../InfoPages/DocsPage/DocsBaseURL"
import JSONDisplay from "../../../SharedComponents/JSONDisplay";
import CodeSnippet from "../../../FileDnD/CodeSnippet"
import {createFileInformation} from "../Functions/createFileInformation";
import {createCodeSnippets} from "../Functions/createCodeSnippets";
import {createFullURLFromBase} from "../../../Constants/createFullURLFromBase";
import BetaMessage from "../../../SharedComponents/BetaMessage";

class ReadSubset extends Component {
    constructor(props){
        super(props);

        this.state = {
            sampleTableData: [
                [1, "Justin Trudeau", 252069.46, "Canada"],
                [2, "Jacinda Ardern", 339862.25, "New Zealand"],
                [3, "Boris Johnson", 187033.75, "UK"],
                [4, "Joko Widodo", 27200.8, "Indonesia"],
                [5, "Angela Merkel", 369727.4, "Germany"]
            ],
            sampleTableHeaders: ["id", "name", "salary_$", "country"],
            sampleDataType: ["num", "str", "num", "str"],
            dataFormat: "row"
        }

        this.changeState = this.changeState.bind(this);
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    render() {
        // POST: Orange
        // GET: Yellow
        let baseGetURL = 'data/[fileID]/?query=[query]/';
        const sampleFileInformation = createFileInformation(this.state.sampleTableData, this.state.sampleTableHeaders, this.state.sampleDataType, 1023, "Sample Data - World Leaders", false, createFullURLFromBase('data/1023'))

        return (
            <div>
                <BetaMessage/>
                <h2>Make a GET request to the following URL</h2>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={baseGetURL} />
                <br/>
                <p>We will only cover the <code>query</code> parameter in this guide. Be sure to go through the <a href="/guides/read-all-rows" target="_blank" rel="noopener noreferrer">Read All Rows</a> guide to learn how all the other parameters are used.</p>
                <br/><br/>
                <h2>Let's look at some examples</h2>
                <p>We will use the following sample data for our examples</p>
                <SampleTable
                    tableData={this.state.sampleTableData}
                    tableHeaders={this.state.sampleTableHeaders}
                    header="Sample Data - World Leaders"
                />
                <br/>
                <p>The following are examples of queries and the response that you will get from the API. You can read all about how to write a valid query in its <a href="/guides/write-a-valid-query">guide</a>.</p>
                <br/><br/>
                <h4>Example #1</h4>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={'data/1023/?query=SELECT*FROM1023WHEREname=\'Boris Johnson\'/'} />
                <CodeSnippet
                    code={createCodeSnippets(sampleFileInformation, "readAction", createFullURLFromBase('data/1023/?query=SELECT*FROM1023WHEREname=\'Boris Johnson\'/'))}
                    height={100}
                />
                <SampleTable
                    tableData={[this.state.sampleTableData[2]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    header={<code>SELECT*FROM1023WHEREname='Boris Johnson'</code>}
                />
                <JSONDisplay
                    heading="Your JSON Response"
                    tableData={[this.state.sampleTableData[2]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    dataTypes={this.state.sampleDataType}
                    outputType="row"
                />
                <br/><br/><br/>
                <h4>Example #2</h4>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={'data/1023/?query=SELECT*FROM1023WHEREsalary_$>=100000ANDsalary_$<=350000/'} />
                <CodeSnippet
                    code={createCodeSnippets(sampleFileInformation, "readAction", createFullURLFromBase('data/1023/?query=SELECT*FROM1023WHEREsalary_$>=100000ANDsalary_$<=350000/'))}
                    height={100}
                />
                <SampleTable
                    tableData={[this.state.sampleTableData[0], this.state.sampleTableData[1], this.state.sampleTableData[2]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    header={<code>{'SELECT*FROM1023WHEREsalary_$>=100000ANDsalary_$<=350000'}</code>}
                />
                <JSONDisplay
                    heading="Your JSON Response"
                    tableData={[this.state.sampleTableData[0], this.state.sampleTableData[1], this.state.sampleTableData[2]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    dataTypes={this.state.sampleDataType}
                    outputType="row"
                />
                <br/><br/><br/>
                <h4>Example #3</h4>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={"data/1023/?query=SELECTid,nameFROM1023WHEREid<>3"} />
                <CodeSnippet
                    code={createCodeSnippets(sampleFileInformation, "readAction", createFullURLFromBase('data/1023/?query=SELECTid,nameFROM1023WHEREid<>3'))}
                    height={100}
                />
                <SampleTable
                    tableData={[[1, "Justin Trudeau"],
                        [2, "Jacinda Ardern"],
                        [4, "Joko Widodo"],
                        [5, "Angela Merkel"]]}
                    tableHeaders={["id", "name"]}
                    header={<code>{'SELECTid,nameFROM1023WHEREid<>3'}</code>}
                />
                <JSONDisplay
                    heading="Your JSON Response"
                    tableData={[[1, "Justin Trudeau"],
                        [2, "Jacinda Ardern"],
                        [4, "Joko Widodo"],
                        [5, "Angela Merkel"]]}
                    tableHeaders={["id", "name"]}
                    dataTypes={["num", "str"]}
                    outputType="row"
                />
                <br/><br/><br/>
                <h4>Example #4</h4>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={"data/1023/?query=SELECT*FROM1023WHEREcountryNOTIN('Canada','Indonesia')"} />
                <CodeSnippet
                    code={createCodeSnippets(sampleFileInformation, "readAction", createFullURLFromBase('data/1023/?query=SELECT*FROM1023WHEREcountryNOTIN(\'Canada\',\'Indonesia\')'))}
                    height={100}
                />
                <SampleTable
                    tableData={[this.state.sampleTableData[1], this.state.sampleTableData[2], this.state.sampleTableData[4]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    header={<code>{"SELECT*FROM1023WHEREcountryNOTIN('Canada','Indonesia')"}</code>}
                />
                <JSONDisplay
                    heading="Your JSON Response"
                    tableData={[this.state.sampleTableData[1], this.state.sampleTableData[2], this.state.sampleTableData[4]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    dataTypes={this.state.sampleDataType}
                    outputType="row"
                />
            </div>
        )
    }
}

export default ReadSubset