import React, {Component} from 'react'
import SignOutButton from '../../Authentication/UserPages/SignOut/SignOutButton'
import { Menu, Image, Icon } from 'semantic-ui-react'
import images from "../../Constants/Images";
import NavbarItem from './NavbarItem'
import { push } from 'react-router-redux'
import {connect} from "react-redux";
import {modifyNavbarItemClicked} from "../../Actions/ModifyNavbarItemClicked";
import {toggleDarkMode} from "../../Actions/ToggleDarkMode";

class NavbarAuth extends Component {
    constructor(props){
        super(props);

        this.goToURL = this.goToURL.bind(this);
    }

    goToURL(e){
        e.preventDefault();
        this.props.modifyNavbarItemClicked("upload")
        this.props.navigateTo("/upload")
    }

    render() {
        return (
            <div className="navbarAuthDiv">
                <Menu secondary stackable>
                    <Menu.Menu position="left">
                        <Menu.Item>
                            <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                <Image style={{cursor: 'pointer'}} src={images.imageIcon} onClick={this.goToURL} size="mini"/>
                            </div>
                        </Menu.Item>
                        {/*<Menu.Item>*/}
                            {/*<NavbarItem to="/FAQs" name="FAQs" color="gray" external />*/}
                        {/*</Menu.Item>*/}
                        <Menu.Item>
                            <NavbarItem to="upload" name={<span><Icon name="upload" />Upload</span>} color="white" />
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="files" name={<span><Icon name="file alternate" />Files</span>} color="white" />
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position="right">
                        {
                            this.props.tier === 'free' ?
                            <Menu.Item>
                                <NavbarItem to="paymentModal" name="Upgrade" icon="dollar" color="white" modal/>
                            </Menu.Item> : null
                        }
                        <Menu.Item>
                            <NavbarItem to="profileModal" name="Profile" icon="user outline" color="white" modal/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="supportModal" name="Support" color="white" modal icon="envelope outline"/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="help" name={<span><Icon name="question circle outline" />Help</span>} color="white" />
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="feedbackModal" name="Feedback" color="white" modal/>
                        </Menu.Item>
                        <Menu.Item>
                            <SignOutButton style={{marginTop: '5px'}} />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    tier: state.mainState.tier,
    isDarkMode: state.mainState.isDarkMode
});

const mapActionsToProps = {
    navigateTo: push,
    modifyNavbarItemClicked: modifyNavbarItemClicked,
    toggleDarkMode: toggleDarkMode
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarAuth)