import React from 'react'
import SaveForm from "./TutorialsText/SaveForm";
import LiveDashboard from "./TutorialsText/LiveDashboard";
import ReactPost from "./TutorialsText/ReactPost";
import ApiBlog from "./TutorialsText/ApiBlog";
import ShareData from "./TutorialsText/ShareData";
import IntegrateFiles from "./TutorialsText/IntegrateFiles";

const TutorialsDetails = [
    {
        icon: 'wordpress forms',
        header: 'Save Web Form Data to Spreadsheets or Google Sheets',
        desc: 'Learn how to save web form data directly to spreadsheets or google sheets',
        title: 'save-web-form-data',
        menuType: 'tutorial',
        iconType: 'semantic',
        article: <SaveForm/>,
        tutorialInfo: {heading: "Web Forms", writtenBy: "Adhaar Sharma", date: "2020-06-10"}
    },
    {
        icon: 'dashboard',
        header: 'Create Live Dashboard with Google Sheets',
        desc: 'Learn how to create a live dashboard with Google Sheets',
        title: 'dashboard-with-google-sheets',
        menuType: 'tutorial',
        iconType: 'semantic',
        article: <LiveDashboard/>,
        tutorialInfo: {heading: "Visualize", writtenBy: "Adhaar Sharma", date: "2020-05-31"}
    },
    {
        icon: 'react',
        header: 'How to Make a React WebApp Powered by Spreadsheets',
        desc: 'Learn React without worrying about a backend',
        title: 'react-site-with-no-backend',
        menuType: 'tutorial',
        iconType: 'semantic',
        article: <ReactPost/>,
        tutorialInfo: {heading: "Power Apps", writtenBy: "Astha Sharma", date: "2020-06-09"}
    },
    {
        icon: 'code',
        header: 'What The Hell Is An API?',
        desc: 'Learn about what an API is, how it can help you, and how you can use it ',
        title: 'what-is-an-api',
        menuType: 'tutorial',
        iconType: 'semantic',
        article: <ApiBlog/>,
        tutorialInfo: {heading: "Learn", writtenBy: "Clarissa Ng", date: "2020-06-06"}
    },
    {
        icon: 'users',
        header: 'A New Way To Share Spreadsheet Data',
        desc: 'Emailing Spreadsheets Back and Forth is a Thing of the Past',
        title: 'share-spreadsheet-data',
        menuType: 'tutorial',
        iconType: 'semantic',
        article: <ShareData/>,
        tutorialInfo: {heading: "Share", writtenBy: "Adhaar Sharma", date: "2020-06-19"}
    },
    {
        icon: 'file alternate outline',
        header: 'Integrate Google Sheets, Dropbox and Local Files',
        desc: 'Say Goodbye to Spreadsheet Integration Headaches',
        title: 'integrate-google-sheets-dropbox-local-files',
        menuType: 'tutorial',
        iconType: 'semantic',
        article: <IntegrateFiles />,
        tutorialInfo: {heading: "Integrate", writtenBy: "Adhaar Sharma", date: "2020-06-03"}
    }
]

export default TutorialsDetails