import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../Constants/API_Root";
import Loader from 'react-loader-spinner'
import {dateToString} from "../Constants/dateToString";
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import {Checkbox} from 'semantic-ui-react'
import DownloadLogs from './DownloadLogs'

class AnalyticsBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vizInfo: null,
            fetching: false,
            getRequest: true,
            postRequest: true,
            fullVizInfo: null
        }

        this.reshapeData = this.reshapeData.bind(this);
        this.toggleRequest = this.toggleRequest.bind(this);
    }

    componentWillMount(){
        this.setState({ fetching: true });
        fetch(API_Root + "api/get-api-analytics/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                pk: this.props.analyticsID.pk
            })
        }).then(res => res.json())
            .then((data) => {
                if (!data.error){
                    this.setState({
                        vizInfo: this.reshapeData(data.vizInfo, this.state.getRequest, this.state.postRequest),
                        fetching: false,
                        fullVizInfo: data.vizInfo
                    })
                }
                else{
                    this.setState({ fetching: false })
                }
            }).catch((err) => {
                this.setState({ fetching: false });
            }).catch(err => this.setState({ fetching: false }));
    }

    toggleRequest(e, requestType){
        let currState = this.state[requestType];
        let getRequest = requestType === "getRequest" ? !currState : this.state.getRequest;
        let postRequest = requestType === "postRequest" ? !currState : this.state.postRequest;

        let reshapedData = this.reshapeData(this.state.fullVizInfo, getRequest, postRequest);

        this.setState({
            [requestType]: !currState,
            vizInfo: reshapedData
        })
    }

    reshapeData(vizInfo, getRequest, postRequest){
        if (vizInfo.length > 0){
            let vizData = [];

            let dateArr = [];
            let error = [];
            let success = [];

            let today = new Date();
            let weekAgo = new Date();
            weekAgo.setDate(today.getDate() - 7)

            while (weekAgo <= today) {
                dateArr.push(dateToString(new Date(weekAgo)).date);
                error.push(0)
                success.push(0)
                weekAgo.setDate(weekAgo.getDate() + 1);
            }

            for (let i=0; i<vizInfo.length; i++){
                const apiCall = vizInfo[i];

                let date = apiCall.created_dt.substr(0, 10);
                let dateIdx = dateArr.indexOf(date);

                const requestTypeBool = {"GET": getRequest, "POST": postRequest};

                if (requestTypeBool[apiCall.request_type])
                {
                    let statusCode = apiCall.status_code;

                    if (statusCode === 200 || statusCode === 201){
                        let updatedSuccess = success[dateIdx] + 1
                        success[dateIdx] = updatedSuccess
                    }
                    else{
                        let updatedError = error[dateIdx] + 1
                        error[dateIdx] = updatedError
                    }
                }
            }

            for (let i=0; i<dateArr.length; i++){
                vizData.push(
                    {
                        date: dateArr[i],
                        success: success[i],
                        error: error[i]
                    }
                )
            }

            return vizData
        }
        else{
            return vizInfo
        }


    }

    render() {
        let body = <div><br/><h5 className="thinHeading">You haven't made any calls to this API</h5></div>

        if (this.state.vizInfo !== null){
            if (this.state.vizInfo.length > 0){
                body =
                    <div style={{marginTop: '20px'}}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div style={{marginLeft: '60px'}}>
                                    <Checkbox
                                        name="get"
                                        label="GET"
                                        checked={this.state.getRequest}
                                        onChange={(e) => this.toggleRequest(e, "getRequest")}
                                    />
                                    <Checkbox
                                        name="post"
                                        label="POST"
                                        checked={this.state.postRequest}
                                        onChange={(e) => this.toggleRequest(e, "postRequest")}
                                        style={{marginLeft: '20px'}}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div style={{float: 'right', marginRight: '20px'}}>
                                    <DownloadLogs />
                                </div>
                            </div>
                        </div>
                        <br/>
                        <LineChart
                            width={500}
                            height={300}
                            data={this.state.vizInfo}
                            margin={{
                                top: 20, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="success" stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="error" stroke="#82ca9d" />
                        </LineChart>
                    </div>
            }
        }

        if (this.state.fetching){
            return(
                <div style={{padding: '100px 0', textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4 className="thinHeading" style={{color: 'black'}}>Getting your history...</h4>
                </div>
            )
        }
        else{
            return (
                body
            )
        }
    }
}

const mapStateToProps = (state) => ({
    analyticsID: state.mainState.analyticsID
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AnalyticsBody)