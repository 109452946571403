import React, {Component} from 'react'
import FileSummaryCodeIndex from "./FileSummaryCodeIndex"
import CustomAccordion from "../../SharedComponents/CustomAccordion";

class FileSummaryCodeAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accordionOpen: false
        }

        this.toggleAccordion = this.toggleAccordion.bind(this);
    }

    toggleAccordion(e){
        this.setState({
            accordionOpen: !this.state.accordionOpen
        })
    }

    render() {
        const codeIndex = <FileSummaryCodeIndex
            pk={this.props.pk}
            pkID={this.props.pkID}
            textPadding={this.props.textPadding}
            idx={this.props.idx}
            tableType={this.props.tableType}
        />

        if (this.props.tableType === "all"){
            return(
                <div>
                    <CustomAccordion
                        body={codeIndex}
                        header={<span>Quickstart Code</span>}
                        accordionStyle={{
                            // borderRadius: '5px',
                            backgroundColor: 'white',
                            border: '1px solid lightgrey'
                            // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                        }}
                        headerDivStyle={{
                            borderRadius: '5px',
                            backgroundColor: 'white',
                            fontSize: '16px',
                            fontWeight: '500',
                            paddingTop: '7px',
                            paddingBottom: '7px'
                        }}
                    />
                </div>
            )
        }
        else{
            return (
                <div>
                    <hr/>
                    {codeIndex}
                </div>
            )
        }
    }
}

export default FileSummaryCodeAccordion