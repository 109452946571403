import React, {Component} from 'react'
import { Icon, Label } from 'semantic-ui-react'

class FileSource extends Component {
    render() {
        const inputType = 'inputType' in this.props.fileInformation ? this.props.fileInformation['inputType'] : null;

        if (inputType === null){
            return (
                null
            )
        }
        else{
            let iconName = null;
            let source = null;
            let color = null;

            if (inputType.includes("file")){
                iconName = "file alternate outline"
                source = "Local File";
                color = "olive";
            }
            else if (inputType.includes("dropbox")){
                iconName = "dropbox"
                source = "Dropbox"
                color = "blue"
            }
            else if (inputType.includes("google")){
                iconName = "google drive"
                source = "Google Sheets"
                color = "green"
            }

            return(
                    <Label color={color}>
                        <Icon name={iconName} /> {source}
                    </Label>
            )

        }
    }
}

export default FileSource