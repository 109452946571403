import React, {Component} from 'react'
import {connect} from 'react-redux'
import { isMobile } from 'react-device-detect'
import FadeIn from 'react-fade-in';
import UseCaseCard from "../../SharedComponents/LandingUseCases/UseCaseCard";
import Forms from "../../SharedComponents/LandingUseCases/Forms.svg"
import PowerApps from "../../SharedComponents/LandingUseCases/PowerApps.svg"
import Dashboard from "../../SharedComponents/LandingUseCases/Dashboard.svg"
import IntegrateSources from '../../SharedComponents/LandingUseCases/IntegrateSources.svg'
import Charts from "../../SharedComponents/LandingUseCases/Charts.svg"
import Code from "../../SharedComponents/LandingUseCases/Code.svg"

class TutorialsIndex extends Component {
    render() {
        return (
            <div id="guidesIndex">
                <div className="blogHero">
                    <h1>Use Cases</h1>
                </div>
                <br/><br/>
                <div className="tutorialsPreviewDiv">
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Use Any and All Spreadsheet Data in Your Apps"
                                    url="tutorials/react-site-with-no-backend"
                                    img={PowerApps}
                                    landing={false}
                                    tutorialInfo={{heading: "Power Apps", writtenBy: "Astha Sharma", date: "2020-06-09"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Save Data from Web Forms to Spreadsheets"
                                    url="tutorials/save-web-form-data"
                                    img={Forms}
                                    landing={false}
                                    tutorialInfo={{heading: "Web Forms", writtenBy: "Adhaar Sharma", date: "2020-06-10"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Create a Live Dashboard with Google Sheets"
                                    url="tutorials/dashboard-with-google-sheets"
                                    img={Charts}
                                    landing={false}
                                    tutorialInfo={{heading: "Visualize", writtenBy: "Adhaar Sharma", date: "2020-05-31"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Collaborate With Your Fellow Data Analysts"
                                    url="tutorials/share-spreadsheet-data"
                                    img={Dashboard}
                                    landing={false}
                                    tutorialInfo={{heading: "Share", writtenBy: "Adhaar Sharma", date: "2020-06-19"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Learn About an API by Experimenting with Your Spreadsheet Data"
                                    url="tutorials/what-is-an-api"
                                    img={Code}
                                    landing={false}
                                    tutorialInfo={{heading: "Learn", writtenBy: "Clarissa Ng", date: "2020-06-06"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Integrate Your Google Sheets, Dropbox and Local Spreadsheets"
                                    url="tutorials/integrate-google-sheets-dropbox-local-files"
                                    img={IntegrateSources}
                                    landing={false}
                                    tutorialInfo={{heading: "Integrate", writtenBy: "Adhaar Sharma", date: "2020-06-03"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(TutorialsIndex)