import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import ProfileModalBody from './ProfileModalBody'
import { Icon } from 'semantic-ui-react'

class ProfileModal extends Component {
    render() {

        return (
            <ModalApp name="profileModal"
                      header={<h2><Icon name='user' /> Profile</h2>}
                      body={<ProfileModalBody/>}
                      size="medium"
            />
        )
    }
}

export default ProfileModal