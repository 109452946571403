import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Input, Dropdown } from 'semantic-ui-react'
import EditFileReadButton from './EditFileReadButton'

class EditOptions extends Component {
    constructor(props) {
        super(props);

        if (this.props.editID in this.props.editOptions) {
            const options = this.props.editOptions[this.props.editID];
            const delimiter = this.props.isCSV ? options.delimiter : null;

            this.state = {
                header: options.header,
                skipRows: options.skipRows,
                startRow: options.startRow,
                skipRowsError: false,
                startRowError: false,
                delimiter: delimiter
            }
        }
        else{
            this.state = {
                header: true,
                skipRows: "",
                startRow: "",
                skipRowsError: false,
                startRowError: false,
                delimiter: ","
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.error = this.error.bind(this);
        this.changeDelimiter = this.changeDelimiter.bind(this);
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name === "startRow" || name === "skipRows"){
            const error = this.error(name, value);

            if (error){
                this.setState({
                    [name + "Error"]: true
                })
            }
            else{
                this.setState({
                    [name + "Error"]: false
                })
            }
        }

    }

    error(name, value){
        let error = true;

        if (name === "startRow"){
            if (value.trim() === ""){
                error = false
            }
            else if (!isNaN(value)){
                if (parseInt(value) >= 0){
                    error = false;
                }
            }
        }

        if (name === "skipRows"){
            const strip = value.split(',');
            const trimmed = strip.map(x=> x.trim());

            let numberCount = 0;

            for (let i=0; i<trimmed.length; i++){
                let num = trimmed[i];

                if (!isNaN(num)){
                    if (parseInt(num) >= 0){
                        numberCount += 1
                    }
                }
            }

            if (numberCount === trimmed.length){
                error = false
            }

            if (value.trim() === ""){
                error = false
            }
        }

        return error
    }

    changeDelimiter(event, {value}){
        this.setState({
            delimiter: value
        })
    }

    render() {
        const delimiterOptions = [
            {key: 1, text: 'Comma (,)', value: ','},
            {key: 2, text: 'Semi-Colon (;)', value: ';'},
            {key: 3, text: 'Pipe (|)', value: '|'},
            {key: 4, text: 'Tab', value: 'tab'},
            {key: 5, text: 'Space ( )', value: 'space'},
        ];

        const delimiterInput = this.props.isCSV ?
            <div>
                <h5 className="thinHeading">Delimiter</h5>
                <Dropdown
                    placeholder="Select Delimiter"
                    name="delimiter"
                    value={this.state.delimiter}
                    options={delimiterOptions}
                    onChange={this.changeDelimiter}
                    selection
                />
            </div> : null;

        const startRowErrorMessage = this.state.startRowError ?
            <small style={{color: 'red'}}>Please enter only a single number</small> : null;

        // const skipRowsErrorMessage = this.state.skipRowsError ?
        // <small style={{color: 'red'}}>Please enter row numbers separated by a comma</small> : null;

        return (
            <div>
                <div className="editOptionDiv">
                    <input style={{fontSize: '150%'}} type="checkbox" name="header" id="header" onChange={this.handleInputChange} checked={this.state.header} />
                    <label htmlFor="header">
                        <span className="thinHeading" style={{cursor: 'pointer', fontSize: '125%', paddingTop: '2px', paddingLeft: '7px'}}>Does File Have a Header?</span>
                    </label>
                </div>
                <div className="editOptionDiv">
                    <h5 className="thinHeading">Start at Row</h5>
                    <Input onChange={this.handleInputChange} name="startRow" error={this.state.startRowError} placeholder="3" value={this.state.startRow} />
                    <br/>
                    {startRowErrorMessage}
                </div>
                {/*<div className="editOptionDiv">*/}
                    {/*<h5 className="thinHeading">Skip Rows</h5>*/}
                    {/*<Input onChange={this.handleInputChange} name="skipRows" error={this.state.skipRowsError} placeholder="5,8,9" value={this.state.skipRows} />*/}
                    {/*<br/>*/}
                    {/*{skipRowsErrorMessage}*/}
                {/*</div>*/}
                <div className="editOptionDiv">
                    {delimiterInput}
                </div>
                <br/><br/>
                <EditFileReadButton editOptions={this.state} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation,
    editOptions: state.mainState.editOptions
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(EditOptions)