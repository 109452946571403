import React, {Component} from 'react'
import PromoCreditCardDetails from './PromoCreditCardDetails'
import {Elements, StripeProvider} from 'react-stripe-elements';
import { connect } from 'react-redux'
import Pricing from "../../Constants/Pricing"
import currencyInfo from "../../Constants/currencyInfo"
import {getPricingText} from "../../Constants/getPricingText";
import { Icon } from 'semantic-ui-react';
class PromoPaymentIndex extends Component {
    constructor(props){
        super(props);

        this.changeProcess = this.changeProcess.bind(this);
    }

    changeProcess(){
        this.goToFiles.click()
    }

    render() {
        const currencyInformation = currencyInfo[this.props.currency];
        const amount = Pricing[1].price * currencyInformation.multiplier * 6;

        return (
            <div className="promoPaymentIndex">
                <a href="/files" style={{display: 'none'}} ref={goToFiles => this.goToFiles = goToFiles}>Nothing</a>
                <StripeProvider apiKey="pk_live_nmeaYWsNdAbSbOTRd8MgnGLL">
                {/*<StripeProvider apiKey="pk_test_1rS8QGgeDU1Ewoyjc8AjaTx0">*/}
                    <div className="example">
                        <h4><Icon name="credit card" /> Enter Credit Card Info</h4>
                        <Elements>
                            <PromoCreditCardDetails changeProcess={this.changeProcess}
                                                    currency={this.props.currency}
                                                    amount={amount}
                                                    currencyAmount={getPricingText(this.props.currency, 1, 6)}

                            />
                        </Elements>
                    </div>
                </StripeProvider>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(PromoPaymentIndex)
