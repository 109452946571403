import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown } from 'semantic-ui-react'

class FileTypeFilter extends Component {
    constructor(props) {
        super(props);

        this.initDropdownOptions = this.initDropdownOptions.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    initDropdownOptions(){
        const fileTypeMaps = {
            googleSheets: "Google Sheets",
            file: "Local File",
            dropbox: "Dropbox"
        }

        let options = [];

        for (let i=0; i<this.props.originalFileTypes.length; i++) {
            options.push(
                {
                    key: i, value: this.props.originalFileTypes[i], text: fileTypeMaps[this.props.originalFileTypes[i]]
                }
            )
        }

        return options
    }

    handleDropdownChange(e, { value }){
        this.props.changeState("fileTypes", value)
    }

    render() {
        return (
            <Dropdown
                fluid
                inline
                selection
                multiple={true}
                options={this.initDropdownOptions()}
                value={this.props.fileTypes}
                placeholder='File Source'
                onChange={this.handleDropdownChange}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FileTypeFilter)