import React, {Component} from 'react'
import API_Root from '../../../Constants/API_Root'
import { auth } from '../../firebase';
import { Header, Input, Button, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import {toggleModal} from "../../../Actions/ToggleModal";
import constants from "../../../Constants/constants";
import {modifyIntegrationAuth} from "../../../Actions/ModifyIntegrationAuth";
import {toggleShowingTutorial} from "../../../Actions/ToggleShowingTutorial";
import {modifyTutorialStep} from "../../../Actions/ModifyTutorialStep";


class SignUpForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email:'',
            passwordOne:'',
            passwordTwo:'',
            tandc: false,
            error: null,
            loading: false,
            plan: "0"
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.signUp = this.signUp.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    handleKeyPress(e){
        const isInvalid = this.state.passwordOne !== this.state.passwordTwo || this.state.passwordOne === '' || this.state.passwordOne.length < 8 || this.state.email === ''|| this.state.tandc === false;

        if (e.charCode === 13){
            if (!isInvalid){
                e.preventDefault()
                this.signUp(e)
            }
        }
    }

    dismissMessage(){
        this.setState({
            error: null
        })
    }

    handleInputChange(e){
        if (e.target.name === "tandc"){
            this.setState({
                tandc: !this.state.tandc
            })
        }
        else{
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    signUp(e){
        this.setState({
            loading: true
        })

        const email = this.state.email;
        const passwordOne = this.state.passwordOne;

        const API = API_Root + 'api/save-user/'; /*save user email to our db*/
        const formData = {
            "email": email,
            "username": email,
            "password": passwordOne,
        };

        let plan = "0";
        const url = window.location.href;

        if (url.includes("?pricing=")){
            plan = url.substring(url.indexOf("?pricing=")+9, url.indexOf("?pricing=")+10)
        }

        const errorMessage = {message: <p>There was an error on our server creating the
                account. Please <a
                    href={"mailto:support@apispreadsheets.com?Subject=Create%20Account%20Error%20for%20" + email}>Email
                    Us</a></p>};

        auth.doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                this.setState(() => ({
                    username: '',
                    email: '',
                    passwordOne: '',
                    passwordTwo: '',
                    tandc: false,
                    error: null,
                }));
                fetch(API, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                })
                .then((res) => {
                        if (res.status === 200){
                            res.json().then((data) => {
                                    localStorage.setItem("email", email.toLowerCase().trim());
                                    localStorage.setItem("token", data.token);

                                    const integrationAuth = {
                                        dropbox: false,
                                        googleSheets: false
                                    };

                                    this.props.modifyIntegrationAuth(integrationAuth);

                                    this.setState({
                                        loading: false
                                    }, () => {
                                        this.props.modifyTutorialStep("userPreference")
                                        this.props.toggleShowingTutorial(true)
                                        if (plan !== "0"){
                                            this.setState({
                                                plan: plan
                                            }, () => {
                                                this.paymentLink.click()
                                            })
                                            // this.props.navigateTo('/complete-payment?pricing=' + plan)
                                        }
                                        else{
                                            this.uploadLink.click()
                                        }
                                    })
                                }
                            ).catch(error => {
                                this.setState({
                                    error: errorMessage ,
                                    loading: false
                                });
                        })
                    }
                    else{
                        this.setState({
                            error: errorMessage ,
                            loading: false
                        })
                    }
                }).catch(error => {
                    this.setState({error:  errorMessage, loading: false});
                });
            }).catch(error => {
                this.setState({error: error, loading: false});
            });
    }

    render() {
        const {
            email,
            passwordOne,
            passwordTwo,
            tandc,
            error
        } = this.state;

        const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || passwordOne.length < 8 ||email === ''|| tandc === false;

        const passwordLengthError = passwordOne.length < 8 ? <div><small style={{color: 'red'}}>Password must be at least 8 characters</small></div> : null;
        const passwordMatchError = passwordOne !== passwordTwo ? <div><small style={{color: 'red'}}>Passwords Do Not Match</small></div> : null;

        let errorMessage = null;

        if (error !== null){
            if ("message" in error){
                errorMessage = <Message
                    onDismiss={this.dismissMessage}
                    content={error.message}
                    negative
                />
            }
        }

        const paymentURL = "/complete-payment?pricing=" + this.state.plan;

        return (
            <div className="segment-margin segment-raised" style={'modalForm' in this.props ? {backgroundColor: 'whitesmoke'} : null} onKeyPress={this.handleKeyPress}>
                <a
                    style={{display: 'none'}}
                    ref={uploadLink => this.uploadLink = uploadLink}
                    href="/upload"
                >Download hidden</a>
                <a
                    style={{display: 'none'}}
                    ref={paymentLink => this.paymentLink = paymentLink}
                    href={paymentURL}
                >Download hidden</a>
                <Header as='h1'>
                    Sign Up to Get Started
                </Header>
                <br/>
                <Input icon="at"
                       iconPosition='left'
                       placeholder="Email" type="email" name="email"
                       onChange={this.handleInputChange}
                       value={email} fluid/>
                <Input  icon="lock"
                        iconPosition="left"
                        placeholder="Password (at least 8 characters)" type="password" name="passwordOne"
                        onChange={this.handleInputChange}
                        value={passwordOne} error={passwordOne.length < 8} fluid/>
                <Input  icon="lock"
                        iconPosition="left"
                        placeholder="Confirm Password" type="password" name="passwordTwo"
                        onChange={this.handleInputChange}
                        value={passwordTwo} error={passwordTwo.length < 8} fluid/>
                <br/>
                <input type="checkbox" name="tandc" id="tandc" onChange={this.handleInputChange} checked={tandc} />
                <label htmlFor="tandc">
                    <span style={{paddingLeft: '7px'}}>{"I agree with " + constants.productName + "'"}</span>
                    <a href="/terms" target="_blank"> Terms and Conditions</a>
                </label>
                {passwordLengthError}
                {passwordMatchError}
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button loading={this.state.loading} color="green" disabled={isInvalid} onClick={this.signUp} onKeyPress={this.handleKeyPress} fluid>
                        <h4 className="thinHeading"> Sign Up</h4>
                    </Button>
                </div>
                <br/>
                {errorMessage}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal,
    modifyIntegrationAuth: modifyIntegrationAuth,
    toggleShowingTutorial: toggleShowingTutorial,
    modifyTutorialStep: modifyTutorialStep
}

export default connect(mapStateToProps,mapActionsToProps)(SignUpForm);
