import React, {Component} from 'react'
import {connect} from 'react-redux'
import GoogleSheetsVisual from "./GoogleSheetsVisual";
import API_Root from "../Constants/API_Root";
import {modifyIntegrationAuth} from "../Actions/ModifyIntegrationAuth";
import loadScript from 'load-script';
import {toggleModal} from "../Actions/ToggleModal";
import copyArray from "../Constants/copyArray";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import isObjectEmpty from "../Constants/isObjectEmpty";
import copyObject from "../Constants/copyObject";
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import {modifyStatus} from "../Actions/ModifyStatus";
import { push } from 'react-router-redux'
import { toggleToast } from "../Actions/ToggleToast";
import {initUploadedPK} from "../Actions/InitUploadedPK";
import {modifyUpgradeText} from "../Actions/ModifyUpgradeText";
import {removePKFromArray} from "../Constants/removePKFromArray";
import {isBrowser} from "react-device-detect";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

let scriptLoadingStarted = false;

class GoogleSheetsConnect extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onApiLoad = this.onApiLoad.bind(this);
        this.onChoose = this.onChoose.bind(this);

        this.storeSheetsInfo = this.storeSheetsInfo.bind(this);

        this.state = {
            clientId: '340136574451-49jb4g9qahbn3l2bsd40s2og3nuiodi1.apps.googleusercontent.com',
            developerKey:'AIzaSyB_mctRTSmKsVCAQRx1bKYtKWm1f8APl-Q',
            // scope:['https://www.googleapis.com/auth/drive'],
            scope:['https://www.googleapis.com/auth/drive.file', 'https://www.googleapis.com/auth/spreadsheets'],
            onChange: data => this.onChange(data),
            onAuthFailed: data => console.log('on auth failed:', data),
            multiselect: true,
            navHidden: false,
            authImmediate: false,
            mimeTypes: ['application/vnd.google-apps.spreadsheet'],
            query: '',
            viewId:'DOCS',
            origin: API_Root.includes("api") ? 'https://apispreadsheets.com' : 'http://localhost:5000',
            email: ''
        }

    }

    isGoogleReady() {
        return !!window.gapi;
    }

    componentWillMount() {
        if(this.isGoogleReady()) {
            // google api is already exists
            // init immediately
            this.onApiLoad();
        } else if (!scriptLoadingStarted) {
            // load google api and the init
            scriptLoadingStarted = true;
            loadScript(GOOGLE_SDK_URL, this.onApiLoad)
        } else {
            // is loading
        }
    }

    isGoogleAuthReady() {
        return !!window.gapi.auth;
    }

    isGooglePickerReady() {
        return !!window.google.picker;
    }

    onApiLoad() {
        window.gapi.load('auth');
        window.gapi.load('picker');
    }

    onChoose() {
        if (!this.isGoogleReady() || !this.isGoogleAuthReady() || !this.isGooglePickerReady() || this.state.disabled) {
            return null;
        }

        const token = window.gapi.auth.getToken();
        const oauthToken = token && token.access_token;

        if (oauthToken) {
            this.createPicker(token.access_token);
        } else {
            const scope = this.state.scope;

            window.gapi.auth2.authorize({client_id: this.state.clientId, scope: scope.join(" ") + " profile email", response_type:"code permission"},
               (authresult) => {
                   if (authresult.code){
                        const code = authresult.code;
                        fetch(API_Root + "api/api-store-google-sheets-auth/",
                           {
                               method: 'POST',
                               headers: {
                                   Accept: 'application/json, text/plain, */*',
                               },
                               body: JSON.stringify({
                                   email: localStorage.getItem("email"),
                                   token: localStorage.getItem("token"),
                                   code: code
                               })
                           }).then((res) =>
                               {
                                   if (res.status === 200){
                                       res.json().then((data) => {
                                            this.setState({ email: data.email }, () => {
                                                const currIntegration = JSON.parse(JSON.stringify(this.props.integrationAuth));

                                                currIntegration.googleSheets = true;

                                                this.props.modifyIntegrationAuth(currIntegration);

                                                this.createPicker(authresult.access_token)
                                            })
                                        })
                                   }
                                   else if (res.status === 406) {
                                       res.json().then((data) => {
                                           this.props.modifyModalInfo({gmailError: true, gmailEmail: data.email});
                                           this.props.toggleModal("googleSheetsTokenErrorModal");
                                       })
                                   }
                                   else{
                                       this.props.navigateTo("/");
                                       this.props.toggleToast({show: true, message: "Click on the Google Sheets Box and Try Again!", type: "warn"});
                                   }
                               })
                           }
                           else{
                                this.props.navigateTo("/");
                                this.props.toggleToast({show: true, message: "Click on the Google Sheets Box and Try Again!", type: "warn"});
                           }
                        })

        }
    }

    createPicker(accessToken) {
        // if(this.state.createPicker){
        //     return this.state.createPicker(window.google, accessToken)
        // }

        // const googleViewId = window.google.picker.ViewId[this.state.viewId];
        // const view = new window.google.picker.View(googleViewId);

        const view = new window.google.picker.DocsView();

        if (this.state.mimeTypes) {
            view.setMimeTypes(this.state.mimeTypes.join(','))
        }
        if (this.state.query) {
            view.setQuery(this.state.query)
        }

        if (!view) {
            throw new Error('Can\'t find view by viewId');
        }

        // if (this.state.origin) {
        //     picker.setOrigin(this.state.origin);
        // }

        const picker = new window.google.picker.PickerBuilder()
            .addView(view)
            .setOAuthToken(accessToken)
            .setDeveloperKey(this.state.developerKey)
            .setCallback(this.state.onChange);

        if (this.state.navHidden) {
            picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
        }

        if (this.state.multiselect) {
            picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
        }

        picker.build().setVisible(true);
    }

    onChange(data){
        const syncError = (errorMessage="There was an error syncing your file! Read and try some of our tips!") => {
            this.props.toggleToast({show: true, message: errorMessage, type: "error"});
            this.props.toggleModal("googleSheetsErrorModal");
        }

        if (data.action === "picked"){
            if (data.docs){
                if (data.docs.length > 0){
                    this.props.toggleModal("loadingModalGoogleSheets");
                    fetch(API_Root + 'api/api-save-sheets-dropbox/', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                        },
                        body: JSON.stringify({
                            documentType: "googleSheets",
                            documents: data.docs,
                            googleEmail: this.state.email,
                            email: localStorage.getItem("email")
                        })
                    }).then(res => res.json())
                        .then((data) => {
                            if (!data.error){
                                // Modify the File Primary Keys
                                let filePK = JSON.parse(JSON.stringify(data.filePK));
                                let uploadedPK = JSON.parse(JSON.stringify(data.filePK));

                                if (this.props.filePK.length > 0){
                                    filePK = copyArray(this.props.filePK, data.filePK);
                                }

                                if (this.props.uploadedPK.length > 0){
                                    uploadedPK = data.filePK.concat(this.props.uploadedPK)
                                }

                                this.props.initUploadedPK(uploadedPK)
                                this.props.modifyFilePK(filePK);
                                // Open up WebSocket when files are saved
                                this.storeSheetsInfo(data.filePK);

                            }
                            else{
                                if ('message' in data){
                                    this.props.toggleModal(null)
                                    this.props.modifyUpgradeText(data.message)
                                    this.props.toggleModal("upgradeModal")
                                }
                                else{
                                    syncError()
                                }
                            }

                        }).catch((err) => { syncError() })
                        .catch(err => syncError())
                }
            }
        }
    }

    storeSheetsInfo(filePK){
        // Open up WebSockets
        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/read-api-sheets-dropbox/");

        // Offset to get created dt
        const current = new Date();
        const utcOffset = current.getTimezoneOffset();

        // Send the PKs and if any column names
        socket.onopen = (event) => {
            socket.send(JSON.stringify({
                utcOffset: utcOffset,
                filePK: filePK,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                sheetRead: this.props.sheetRead,
            }))
        };

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            if (status === "read"){
                let filePKProBlock = reply['filePKProBlock'];

                if (typeof this.props.filePKProBlock !== 'undefined'){
                    if (!isObjectEmpty(this.props.filePKProBlock)){
                        filePKProBlock = copyObject(this.props.filePKProBlock, reply['filePKProBlock']);
                    }
                }

                this.props.modifyFilePKProBlock(filePKProBlock);
            }

            if (status === "api"){
                // props.modifyStatus(status);
                let fileInformation = reply['fileInformation'];
                let sheetRead = reply['sheetRead'];

                if (typeof this.props.fileInformation !== 'undefined'){
                    if (Object.entries(this.props.fileInformation).length !== 0 && this.props.fileInformation.constructor === Object){
                        fileInformation = copyObject(this.props.fileInformation, reply.fileInformation);
                        sheetRead = copyObject(this.props.sheetRead, reply.sheetRead);
                    }
                }

                this.props.modifyFileInformation(fileInformation);
                this.props.modifySheetRead(sheetRead);

                if (isBrowser) {
                    if (this.props.showingTutorial) {
                        if (this.props.tutorialStep === "userPreference") {
                            this.props.toggleModal("quickStartModal")
                        }
                    } else {
                        this.props.toggleModal(null);
                    }
                }

                socket.close()

            }

            if (status === "error"){
                socket.close()
                this.props.initUploadedPK(removePKFromArray(this.props.uploadedPK, filePK))
                this.props.modifyFilePK(removePKFromArray(this.props.filePK, filePK));

                this.props.toggleModal(null);
                const errorMessage = 'errorMessage' in reply ? reply['errorMessage'] : "There was an error reading your Google Sheet 😞. Please try again. Contact our Support if this error persists."
                this.props.toggleToast({show: true, message: errorMessage, type: "error"})
            }


        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <div onClick={this.onChoose}>
                        <GoogleSheetsVisual />
                    </div>
                </div>
                <div className="col-sm-1" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    integrationAuth: state.mainState.integrationAuth,
    filePK: state.mainState.filePK,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock,
    fileInformation: state.mainState.fileInformation,
    uploadedPK: state.mainState.uploadedPK,
    showingTutorial: state.mainState.showingTutorial,
    tutorialStep: state.mainState.tutorialStep
})

const mapActionsToProps = {
    modifyIntegrationAuth: modifyIntegrationAuth,
    toggleModal: toggleModal,
    modifyFilePK: modifyFilePK,
    modifyFilePKProBlock: modifyFilePKProBlock,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    modifyStatus: modifyStatus,
    navigateTo: push,
    toggleToast: toggleToast,
    initUploadedPK: initUploadedPK,
    modifyUpgradeText: modifyUpgradeText,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(GoogleSheetsConnect)