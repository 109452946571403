import React, {Component} from 'react'
import {connect} from 'react-redux'
import DropboxChooser from './DropboxChooser'
import DropboxConnect from "./DropboxConnect";

class DropboxAuth extends Component {
    render() {
        if (this.props.integrationAuth.dropbox){
            return (
                <DropboxChooser />
            )
        }
        else{
            return (
                <DropboxConnect/>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    integrationAuth: state.mainState.integrationAuth
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DropboxAuth)