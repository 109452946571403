import React, { Component } from 'react';
import { auth } from '../../firebase';
import API_ROOT from "../../../Constants/API_Root";
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Header, Input, Button, Icon, Message } from 'semantic-ui-react'
import {toggleModal} from "../../../Actions/ToggleModal";
import {toggleProBlock} from "../../../Actions/ToggleProBlock";
import {modifyFilePKProBlock} from "../../../Actions/ModifyFilePKProBlock";
import {modifyIntegrationAuth} from "../../../Actions/ModifyIntegrationAuth";

class LoginForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            error: null,
            loading: false
        }

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.login = this.login.bind(this);
        this.forgetPassword = this.forgetPassword.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    handleKeyPress(e){
        if (e.charCode === 13){
            if (!(this.state.password.trim() === "" || this.state.email.trim() === "")){
                e.preventDefault()
                this.login(e)
            }
        }
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    login(e){
        e.preventDefault();

        this.setState({
            loading: true
        })

        const {
            email,
            password,
        } = this.state;

        const API = API_ROOT + "api/login/"

        const errorMessageObject = {
            message: <p>There was a problem logging in with our server. Refresh and try again! If the problem persists email us at support@apispreadsheets.com</p>
        };

        auth.doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState(() => ({
                    email:'',
                    password:'',
                    error: null,
                }));
                localStorage.setItem("email", email.toLowerCase().trim());

                fetch(API, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json, text/plain, */*',
                    },
                    body: JSON.stringify({
                        email: email,
                        password: password,
                    })
                }).then((res) => {
                    if (res.status === 200){
                            res.json().then((data) => {
                                localStorage.setItem("token", data.token);
                                if (!('modal' in this.props)){
                                    this.filesLink.click()
                                } else {
                                    this.props.toggleModal(null)
                                    // window.location.reload()
                                }
                            }).catch((err) => { this.setState({ loading: false, error: errorMessageObject});
                        });
                    }
                    else{
                        this.setState({ loading: false, error: errorMessageObject })
                    }
                }).catch(err => this.setState({ loading: false, error: errorMessageObject }))
            }).catch(error => {
                this.setState({error: error, loading: false});
            });
    };

    forgetPassword(e){
        this.props.toggleModal(null);
        this.props.toggleModal("forgetPasswordModal")
    }

    dismissMessage(){
        this.setState({
            error: null
        })
    }

    render(){
        const {
            email,
            password,
            error,
        } = this.state;

        const isInvalid =
            password === '' ||
            email === '';

        let errorMessage = null;

        if (error !== null){
            if ("message" in error){
                errorMessage = <Message
                    onDismiss={this.dismissMessage}
                    content={error.message}
                    negative
                />
            }
        }

        return(
            <div className="segment-margin segment-raised" onKeyPress={this.handleKeyPress}>
                <a
                    style={{display: 'none'}}
                    ref={filesLink => this.filesLink = filesLink}
                    href="/files"
                >Download hidden</a>
                <Header as='h1'>
                    Log In
                </Header>
                <br/>
                <Input icon="at"
                       placeholder="Your Email"
                       iconPosition='left'
                       type="text" name="email" value={email}
                       onChange={this.handleInputChange}
                       fluid
                />
                <br/>
                <Input icon="lock"
                       placeholder="Your Password"
                       iconPosition='left'
                       type="password" name="password" value={password}
                       onChange={this.handleInputChange}
                       fluid
                />
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button loading={this.state.loading} color="green" basic disabled={isInvalid}
                            type="submit"
                            onKeyPress={this.handleKeyPress}
                            onClick={this.login} fluid>
                        <h4 className="thinHeading">Log In <Icon name="right arrow" /></h4>
                    </Button>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <p className="linkStyle" onClick={this.forgetPassword}>Forgot password?</p>
                </div>
                {errorMessage}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    filePKProBlock: state.mainState.filePKProBlock
})

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal,
    toggleProBlock: toggleProBlock,
    modifyFilePKProBlock: modifyFilePKProBlock,
    modifyIntegrationAuth: modifyIntegrationAuth
}

export default connect(mapStateToProps, mapActionsToProps)(LoginForm);
