import React, {Component} from 'react'
import { Menu, Icon } from 'semantic-ui-react'
import {HelpInfo} from "./HelpInfo";
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";

class HelpMenu extends Component {
    constructor(props) {
        super(props);

        this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    }

    handleMenuItemClick(e, { name }){
        this.props.changeMenuItem(name)
    }


    render() {
        const menuItems = HelpInfo.map((x, idx) =>
            <Menu.Item
                name={x.info.name}
                active={this.props.menuItem === x.info.name}
                key={idx.toString()}
                onClick={this.handleMenuItemClick}
            >
                <h4 className={this.props.menuItem === x.info.name ? "" : "thinHeading"}> <Icon name={x.info.icon} style={{paddingRight: '30px'}}/> {x.info.name === "api" ? x.info.name.toUpperCase() : capitalizeHyphenated(x.info.name)}</h4>
            </Menu.Item>
        )

        return (
            <div>
                <Menu pointing secondary vertical>
                    {menuItems}
                </Menu>
            </div>
        )
    }
}

export default HelpMenu