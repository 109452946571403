import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyEditID} from "../../Actions/ModifyEditID";
import {toggleModal} from "../../Actions/ToggleModal";

class KeysButton extends Component {
    constructor(props) {
        super(props);

        this.openModal = this.openModal.bind(this);
    }

    openModal(e){
        this.props.modifyEditID(this.props.pkID);
        this.props.toggleModal("keysModal")
    }

    render() {
        const isPrivate = this.props.fileInformation[this.props.pkID]['private'];

        if (isPrivate){
            return (
                <div>
                    <Button content="Your Keys" basic color="red" icon="key" fluid onClick={this.openModal}/>
                </div>
            )
        }
        else{
            return(
                null
            )
        }

    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyEditID: modifyEditID,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(KeysButton)