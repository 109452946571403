import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import API_Root from "../Constants/API_Root";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import {toggleToast} from "../Actions/ToggleToast";

class RemoveFile extends Component {
    constructor(props) {
        super(props);

        this.removeFile = this.removeFile.bind(this);
        this.deleteServerFile = this.deleteServerFile.bind(this);
        this.deleteSheetsRead = this.deleteSheetsRead.bind(this);
    }

    removeFile(){
        const pkID = this.props.pkID;
        const pk = this.props.pk;

        const isExcel = this.props.isExcel;

        let deleteServerFile = true;

        // Figure out whether to delete the file from the server or not and delete any sheets read
        if (isExcel){
            let countPK = 0;
            for (let i=0; i<this.props.filePK.length; i++){
                const currPK = this.props.filePK[i]['pk'];

                if (currPK === pk){
                    countPK += 1
                }
            }

            if (countPK > 1){
                deleteServerFile = false
            }

            // Delete from sheets read
            this.deleteSheetsRead(pk, pkID)
        }

        // Delete the file from the server
        if (deleteServerFile){
            this.deleteServerFile(pk)
        }

        // Delete the idx of filePK and update
        const removedFilePK = JSON.parse(JSON.stringify(this.props.filePK));
        removedFilePK.splice(this.props.idx, 1);

        this.props.modifyFilePK(removedFilePK);

        // Delete the file information associated to this
        const copiedFileInformation = JSON.parse(JSON.stringify(this.props.fileInformation));

        delete copiedFileInformation[pkID];

        this.props.modifyFileInformation(copiedFileInformation);

        this.props.toggleToast({show: true, message: "File Deleted", type: "success"})

    }

    deleteServerFile(pk){
        fetch(API_Root + "api/delete-api-file/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                pk: pk
            })
        }).then(res => {
        }).catch();
    }

    deleteSheetsRead(pk, pkID){
        const removedSheets = JSON.parse(JSON.stringify(this.props.sheetRead));
        delete removedSheets[pkID];

        this.props.modifySheetRead(removedSheets);

    }

    render() {
        return (
            <Popup
                trigger={
                    <div>
                        <Button content="Delete File" basic color='red' icon='trash alternate outline' fluid/>
                    </div>
                }
                content={
                    <div style={{textAlign: 'center'}}>
                        <h5 className="thinHeading">Are you sure you want to delete this file?</h5>
                        <Button color='red' content='Delete File' onClick={this.removeFile} /> <br/>
                        <small><b>NOTE: </b>if file is imported from Google Sheets or Dropbox then source file will NOT be deleted</small>
                    </div>}
                on='click'
                position='top right'
            />
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    sheetRead: state.mainState.sheetRead
})

const mapActionsToProps = {
    modifyFilePK: modifyFilePK,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(RemoveFile)