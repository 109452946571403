import React, {Component} from 'react'
import { Dropdown } from 'semantic-ui-react'
// import EmptyFileWarning from "./EmptyFileWarning";

class CreateOptionsToolbar extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, {value}){
        this.props.changeState("createFormat", value);
    }

    render() {
        const idx = this.props.idx.toString();
        const name = "createFormat" + idx;
        const createFormat = this.props.createFormat

        const dataTypeOptions = [
            {key: 3, text: 'Column Value', value: 'columnValue'},
            {key: 1, text: 'Row', value: 'row'},
            {key: 2, text: 'Column', value: 'column'},
        ];

        return (
            <div className="row" style={{height: '25px'}}>
                <div className="col-sm-12">
                    <div>
                        <span> Choose Data Format: {' '}
                            <Dropdown
                                placeholder="Select Data Format"
                                name={name}
                                value={createFormat}
                                options={dataTypeOptions}
                                onChange={this.handleChange}
                                inline
                            />
                        </span>
                    </div>
                    {/*{this.props.fileEmpty ? <EmptyFileWarning actionType="Create" pk={this.props.pk} pkID={this.props.pkID}/> : null}*/}
                </div>
            </div>
        )
    }
}

export default CreateOptionsToolbar