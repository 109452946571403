import React, {Component} from 'react'

class GoogleSheetsGuide extends Component {
    render() {
        return (
            <div>
                <p>Since Google Sheets is an independent tool that we interface with, there are a few situations where you might receive an unexpected error</p>
                <h2>Not being able to connect to Google Sheets</h2>
                <p>You may not be able to connect or upload your Google Sheets at all</p>
                <p>This is a known issue on <b>Mobile</b> browsers so try on a Desktop</p>
                <p>If you are on a desktop and it's not connecting, try the following steps</p>
                <ol>
                    <li>Log out of API Spreadsheets</li>
                    <li>Clear your browser cache and cookies. <a href="https://www.a2hosting.com/kb/getting-started-guide/internet-and-networking/clearing-a-web-browsers-cache-and-cookies" target="_blank" rel="noopener noreferrer">Read here how to do that</a></li>
                    <li>Log back in to API Spreadsheets and try again</li>
                </ol>
                <br/>
                <p>If it still doesn't connect, follow the same steps as above but on a different browser</p>
                <p>If you are still not able to connect, you MAY have to remove your access to API Spreadsheets from your Google Account</p>
                <ol>
                    <li>Go to your <a href="https://myaccount.google.com/" target="_blank" rel="noopener noreferrer">Google Account</a>.</li>
                    <li>On the left navigation panel, select <b>Security</b>.</li>
                    <li>On the <i>Third-party apps with account access panel</i>, select <b>Manage third-party access.</b></li>
                    <li>Select <b>API Spreadsheets</b>.</li>
                    <li>Select Remove Access.</li>
                    <li>Log out of <b>API Spreadsheets</b> and clear your browser <b>cache</b></li>
                    <li>Log back in to API Spreadsheets and try again</li>
                </ol>
                <br/><br/>
                <h2>Your Google Sheets File Deleted on Google Drive</h2>
                <p>If you delete or Google Sheet file from Google Drive and NOT from API Spreadsheets, you will get an error when you try to do anything</p>
                <p>Your API URL will be valid but since your file does not exist, you will receive a <code>500</code> error code</p>
                <br/>
                <h2>No multiple API Spreadsheets Accounts</h2>
                <p>Each Google Account can only connect with one API Spreadsheets account</p>
                <p>BUT you can connect to as many Google Accounts as you want from your API Spreadsheets account</p>
                <p>For example, let's say you have three Google Accounts</p>
                <ol>
                    <li>abc@gmail.com</li>
                    <li>xyz@gmail.com</li>
                    <li>123@gmail.com</li>
                </ol>
                <br/>
                <p>And 2 API Spreadsheets accounts</p>
                <ol>
                    <li>a123z@api.com</li>
                    <li>b789c@api.com</li>
                </ol>
                <br/>
                <p>You can connect <b>abc@gmail.com</b> and <b>xyz@gmail.com</b> to <b style={{color: "green"}}>a123z@api.com</b></p>
                <p>BUT if you try to also connect <b>abc@gmail.com</b> to <b style={{color: "red"}}>b789c@api.com</b></p>
                <p><b>You will receive an ERROR and not be able to proceed</b></p>
                <br/>
                <h2>Operation timed out</h2>
                <p>Sometimes if you do a heavy request of reading or writing multi thousand rows, Google Sheets times out</p>
                <p>This is not related to anything particular and often happens at random due to Google Sheets</p>
                <p>If you are unable to execute your request, wait a bit and try again!</p>
                <br/><br/><br/>
                <div className="faqEmailUs">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Have more questions about using Google Sheets?</h2>
                            <h4 style={{color: 'gray'}}>Email us at <a href="mailto:info@apispreadsheets.com?Subject=Google%20Sheets%20Error%20Questions">info@apispreadsheets.com</a></h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GoogleSheetsGuide