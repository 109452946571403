import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import API_Root from "../../Constants/API_Root";

class FileDescriptionModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            originalFileDesc: JSON.parse(JSON.stringify(this.props.fileInformation[this.props.pkID].description)),
            fileDesc: JSON.parse(JSON.stringify(this.props.fileInformation[this.props.pkID].description)),
            saving: false,
            editMode: this.initEditMode()
        }

        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.saveDescription = this.saveDescription.bind(this);
        this.initEditMode = this.initEditMode.bind(this);
        this.undoDesc = this.undoDesc.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
    }

    initEditMode(e){
        let fileDesc = JSON.parse(JSON.stringify(this.props.fileInformation[this.props.pkID].description));

        return fileDesc.trim() === ""
    }

    undoDesc(e){
        if (this.state.originalFileDesc === ""){
            this.setState({
                fileDesc: this.state.originalFileDesc,
                editMode: true
            })
        }
        else{
            this.setState({
                fileDesc: this.state.originalFileDesc,
                editMode: false
            })
        }
    }

    handleDescriptionChange(e){
        this.setState({
            fileDesc: e.target.value
        })
    }

    toggleEdit(e){
        const currEdit = this.state.editMode;

        this.setState({
            editMode: !currEdit
        })
    }

    saveDescription(e){
        this.setState({saving: true});

        const copiedFileInfo = JSON.parse(JSON.stringify(this.props.fileInformation))
        copiedFileInfo[this.props.pkID].description = this.state.fileDesc;
        this.props.modifyFileInformation(copiedFileInfo);

        let pk = this.props.pk;

        fetch(API_Root + 'api/change-api-file-description/', {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                pk: pk,
                description: this.state.fileDesc,
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then(
            (res) => {
                if (res.status === 200){
                    this.setState({originalFileDesc: this.state.fileDesc, saving: false, editMode: false});
                }
                else{
                    this.setState({originalFileDesc: this.state.fileDesc, saving: false, editMode: false});
                }
            }).catch(err => this.setState({originalFileDesc: this.state.fileDesc, saving: false, editMode: false}))

    }

    render() {
        const fileDesc = this.state.fileDesc;

        let alert = null;
        let saveBtnDisabled = true;

        if (this.state.originalFileDesc.trim() !== fileDesc.trim()){
            alert = <span style={{color: 'red', paddingLeft: '5px', marginBottom: '5px'}}>Description not saved<br/></span>
            saveBtnDisabled = false
        }

        if (this.state.editMode){
            return (
                <div className="fileDesc">
                    <div className="fileDescDiv">
                        <div className="fileDescTextArea">
                            <textarea
                                style={{width: '100%', display: 'block'}}
                                value={fileDesc}
                                onChange={this.handleDescriptionChange}
                                placeholder="Enter a description for the file"
                            />
                        </div>
                        <div className="fileDescButtons">
                            <Popup
                                trigger={<Button circular
                                        inverted
                                        icon="save outline"
                                        color="green"
                                        disabled={saveBtnDisabled}
                                        onClick={this.saveDescription}
                                        loading={this.state.saving}
                                        size="mini"
                                />}
                                content="Save Description"
                            />
                            <Popup
                                trigger={<Button circular
                                        icon="undo"
                                        color="red"
                                        inverted
                                        onClick={this.undoDesc}
                                        size="mini"
                                />}
                                content="Undo Changes"
                            />
                        </div>
                    </div>
                    {alert}
                </div>
            )
        }
        else{
            return (
                <div className="fileDesc">
                    <div className="fileDescDiv">
                        <div className="fileDescTextArea" onClick={this.toggleEdit}>
                            <p>{fileDesc}</p>
                        </div>
                        <div className="fileDescButtons">
                            <Popup
                                trigger={<Button circular
                                        icon="edit outline"
                                        color="blue"
                                        onClick={this.toggleEdit}
                                        inverted
                                        size="mini"
                                    />}
                                content="Edit Description"
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation
}

export default connect(mapStateToProps, mapActionsToProps)(FileDescriptionModalBody)