import React, {Component} from 'react'
import {connect} from 'react-redux'
import constants from "../Constants/constants";
import loadScript from 'load-script'
import API_Root from "../Constants/API_Root";
import copyArray from "../Constants/copyArray";
import {modifyStatus} from "../Actions/ModifyStatus";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import isObjectEmpty from "../Constants/isObjectEmpty";
import copyObject from "../Constants/copyObject";
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import DropboxVisual from "./DropboxVisual";
import {toggleModal} from "../Actions/ToggleModal";
import {push} from "react-router-redux";
import { toggleToast} from "../Actions/ToggleToast";
import {initUploadedPK} from "../Actions/InitUploadedPK";
import {removePKFromArray} from "../Constants/removePKFromArray";
import {isBrowser} from "react-device-detect";
import {modifyUpgradeText} from "../Actions/ModifyUpgradeText";

const DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js';
const SCRIPT_ID = 'dropboxjs';

let scriptLoadingStarted = false;

const documentType = "dropbox";

class DropboxChooser extends Component {
    constructor(props) {
        super(props);

        this.onSuccess = this.onSuccess.bind(this);
        this.onClick = this.onClick.bind(this);
        this.getResults = this.getResults.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    isDropboxReady() {
        return !!window.Dropbox;
    }

    componentWillMount(){
        if (!this.isDropboxReady() && !scriptLoadingStarted) {
            scriptLoadingStarted = true;
            loadScript(DROPBOX_SDK_URL, {
                attrs : {
                    id: SCRIPT_ID,
                    'data-app-key': "5wksa0jimylgr9z"
                }
            });
        }
    }

    onSuccess(files){
        this.props.toggleModal("loadingModalDropbox")
        fetch("https://api.dropboxapi.com/2/users/get_current_account/", {
            headers: {
                Authorization: "Bearer"
            },
            method: "POST"
        }).then(res => console.log(res)).catch(err => console.log(err))

        fetch(API_Root + "api/api-save-sheets-dropbox/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                documentType: documentType,
                documents: files,
                email: localStorage.getItem("email")
            })
        }).then(res => res.json())
            .then((data) => {
                if (!data.error){
                    // Modify the File Primary Keys
                    let filePK = JSON.parse(JSON.stringify(data.filePK));
                    let uploadedPK = JSON.parse(JSON.stringify(data.filePK));

                    if (this.props.filePK.length > 0){
                        filePK = copyArray(this.props.filePK, data.filePK);
                    }

                    if (this.props.uploadedPK.length > 0){
                        uploadedPK = data.filePK.concat(this.props.uploadedPK)
                    }

                    this.props.initUploadedPK(uploadedPK)
                    this.props.modifyFilePK(filePK);
                    // Open up WebSocket when files are saved
                    this.getResults(data.filePK);

                }
                else{
                    if ('message' in data){
                        this.props.toggleModal(null)
                        this.props.modifyUpgradeText(data.message)
                        this.props.toggleModal("upgradeModal")
                    }
                    else {
                        this.props.toggleModal(null);
                        this.props.toggleToast({show: true, message: "There was an error uploading your file! Please try again!", type: "error"})
                    }
                }
            }).catch((err) => {
            this.props.toggleModal(null);
            this.props.toggleToast({show: true, message: "There was an error uploading your file! Please try again!", type: "error"})
        }).catch((err) => {
            this.props.toggleModal(null);
            this.props.toggleToast({show: true, message: "There was an error uploading your file! Please try again!", type: "error"})
        });
    }

    onClick(e){
        if (!this.isDropboxReady()) {
            return null;
        }

        const options = {
            success: (files) => this.onSuccess(files),
            cancel: () => this.cancel(),
            linkType: "direct",
            multiselect: true,
            extensions: constants.extensions,
        };

        window.Dropbox.choose(options);
    }

    getResults(filePK){
        // Open up WebSockets
        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/read-api-sheets-dropbox/");

        const props = this.props;

        // Offset to get created dt
        const current = new Date();
        const utcOffset = current.getTimezoneOffset();

        // Send the PKs and if any column names
        socket.onopen = function(event) {
            socket.send(JSON.stringify({
                utcOffset: utcOffset,
                filePK: filePK,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                sheetRead: props.sheetRead
            }));
        };

        socket.onmessage  = function(event){
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            if (status === "read"){
                let filePKProBlock = reply['filePKProBlock'];

                if (typeof props.filePKProBlock !== 'undefined'){
                    if (!isObjectEmpty(props.filePKProBlock)){
                        filePKProBlock = copyObject(props.filePKProBlock, reply['filePKProBlock']);
                    }
                }

                props.modifyFilePKProBlock(filePKProBlock);
            }

            if (status === "api"){
                // props.modifyStatus(status);
                let fileInformation = reply['fileInformation'];
                let sheetRead = reply['sheetRead'];

                if (typeof props.fileInformation !== 'undefined'){
                    if (Object.entries(props.fileInformation).length !== 0 && props.fileInformation.constructor === Object){
                        fileInformation = copyObject(props.fileInformation, reply.fileInformation);
                        sheetRead = copyObject(props.sheetRead, reply.sheetRead);
                    }
                }

                props.modifyFileInformation(fileInformation);
                props.modifySheetRead(sheetRead);

                if (isBrowser) {
                    if (props.showingTutorial) {
                        if (props.tutorialStep === "userPreference") {
                            props.toggleModal("quickStartModal")
                        }
                    } else {
                        props.toggleModal(null);
                    }
                }

                socket.close()
            }

            if (status === "error"){
                props.initUploadedPK(removePKFromArray(this.props.uploadedPK, filePK))
                props.modifyFilePK(removePKFromArray(this.props.filePK, filePK));
                props.toggleModal(null);
                socket.close()
                const errorMessage = 'errorMessage' in reply ? reply['errorMessage'] : "There was an error uploading your file! Please try again! Contact Support if the problem persists."
                props.toggleToast({show: true, message: errorMessage, type: "error"})
            }
        }
    }

    cancel(){
        this.props.navigateTo("/")
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <div onClick={this.onClick}>
                        <DropboxVisual/>
                    </div>
                </div>
                <div className="col-sm-1" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock,
    fileInformation: state.mainState.fileInformation,
    uploadedPK: state.mainState.uploadedPK,
    showingTutorial: state.mainState.showingTutorial,
    tutorialStep: state.mainState.tutorialStep
})

const mapActionsToProps = {
    modifyStatus: modifyStatus,
    modifyFilePK: modifyFilePK,
    modifyFilePKProBlock: modifyFilePKProBlock,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    toggleModal: toggleModal,
    navigateTo: push,
    toggleToast: toggleToast,
    initUploadedPK: initUploadedPK,
    modifyUpgradeText: modifyUpgradeText
}

export default connect(mapStateToProps, mapActionsToProps)(DropboxChooser)