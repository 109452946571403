import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'

class BetaLabel extends Component {
    render() {
        return (
            <Label color='green' horizontal>
                BETA
            </Label>
        )
    }
}

export default BetaLabel