import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Accordion, Icon } from 'semantic-ui-react'
import JSONOutputPreview from './JSONOutputPreview'


class FileAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: this.props.tableType === "all" ? -1 : 0,
        }

        this.handleAccordionClick = this.handleAccordionClick.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }

    handleAccordionClick(e, idxClicked){
        const { index } = idxClicked;
        const activeIndex = this.state.activeIndex;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex })
    }

    handleRadioChange(e){
        this.props.changeState("outputFormat", e.target.value);
    }

    render() {
        if (this.props.apiType === "deleteAction"){
            return(
                null
            )
        }
        else{
            const activeIndex = this.state.activeIndex;
            const outputFormat = this.props.dataFormat;
            const title = this.props.apiType === "readAction" ? "Sample Response" : "Sample Request";

            return (
                <Accordion styled fluid>
                    <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleAccordionClick}>
                        <Icon name='dropdown' />
                        <b>{title}</b>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        <JSONOutputPreview pkID={this.props.pkID} outputFormat={outputFormat} apiType={this.props.apiType} pk={this.props.pk} />
                    </Accordion.Content>
                </Accordion>
            )
        }
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(FileAccordion)