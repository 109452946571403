import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class DropboxVisual extends Component {
    render() {
        return (
            <div className="uploadOuterSection">
                <div className="uploadInnerSection">
                    <div style={{textAlign: 'center'}}>
                                    <span className="uploadIcons">
                                        <Icon name="dropbox" />
                                    </span>
                        <h4 className="thinHeading">Dropbox</h4>
                    </div>
                </div>
            </div>
        )
    }
}

export default DropboxVisual