import React, {Component} from 'react'
import LoginForm from './LoginForm'
import { Image } from 'semantic-ui-react'
import images from '../../../Constants/Images'

class LoginIndex extends Component {
    componentDidMount(){
        document.title = "Login - API Spreadsheets"
    }

    render() {
        return (
            <div className="row" style={{backgroundColor: '#DCDCDC', minHeight: '100vh'}}>
                <div className="col-sm-3" />
                <div className="col-sm-6">
                    <br/><br/><br/>
                    <div style={{marginLeft: '1rem'}}>
                        <Image style={{marginTop: '5px'}} src={images.logoNav} href="/" size="medium"/>
                    </div>
                    <LoginForm />
                    <br/>
                    <p style={{textAlign: 'center'}}>Don't Have An Account? <a href="/signup">Create One</a></p>
                </div>
                <div className="col-sm-3" />
            </div>
        )
    }
}

export default LoginIndex