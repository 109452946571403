import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";
import {modifyStatus} from "../Actions/ModifyStatus";
import {modifyFinalColumnPK} from "../Actions/ModifyFinalColumnPK";
import {modifyFinalColumnNames} from "../Actions/ModifyFinalColumnNames";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {toggleModal} from "../Actions/ToggleModal";
import {modifyEditOptions} from "../Actions/ModifyEditOptions";
import {modifyEditFileResponse} from "../Actions/ModifyEditFileResponse";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import isObjectEmpty from "../Constants/isObjectEmpty";
import {toggleToast} from "../Actions/ToggleToast";

class EditFileReadButton extends Component {
    constructor(props) {
        super(props);

        this.editFile = this.editFile.bind(this)
    }

    editFile(){
        // Check if there is any error
        const skipRowsError = this.props.skipRowsError;
        const startRowError = this.props.startRowError;

        if (!skipRowsError && !startRowError){
            let filePK;

            // Get the PK of the file(s)
            if (this.props.editID !== "all"){
                for (let i=0; i<this.props.filePK.length; i++){
                    const filePKObj = this.props.filePK[i];
                    const fileID = filePKObj['id'];

                    if (fileID === this.props.editID){
                        filePK = [filePKObj];
                        break
                    }
                }
            }
            else{
                filePK = this.props.filePK
            }

            // Open up WebSockets
            const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
            const props = this.props;

            let socket = new WebSocket(websocketRoute + "ws/api/read-api-files/");
            if (props.fileInformation[props.editID]['inputType'] !== "file"){
                socket = new WebSocket(websocketRoute + "ws/api/read-api-sheets-dropbox/");
            }


            // Offset to get created dt
            const current = new Date();
            const utcOffset = current.getTimezoneOffset();


            // Send the PKs and if any column names
            socket.onopen = (event) => {
                socket.send(JSON.stringify({
                    filePK: filePK,
                    sheetRead: props.sheetRead,
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    editOptions: props.editOptions,
                    utcOffset: utcOffset
                }));
            };

            props.toggleModal(null);
            props.toggleModal("loadingModalEditFile");

            socket.onmessage = (event) => {
                const reply = JSON.parse(event.data);
                const status = reply['status'];

                props.modifyStatus(status);

                if (status === "read"){
                    let filePKProBlock = reply['filePKProBlock'];

                    let propsFilePKProBlock = JSON.parse(JSON.stringify(props.filePKProBlock));

                    if (typeof propsFilePKProBlock !== 'undefined') {
                        if (!isObjectEmpty(propsFilePKProBlock)) {
                            for (let pkID in filePKProBlock) {
                                const proBlock = filePKProBlock[pkID];
                                propsFilePKProBlock[pkID] = proBlock;
                            }
                        }
                    }

                    props.modifyFilePKProBlock(propsFilePKProBlock);
                }

                if (status === "api"){
                    let fileInformation = reply.fileInformation;
                    let sheetRead = reply.sheetRead;

                    let propsFileInformation = JSON.parse(JSON.stringify(props.fileInformation));

                    if (typeof propsFileInformation !== 'undefined') {
                        if (Object.entries(propsFileInformation).length !== 0 && propsFileInformation.constructor === Object) {
                            for (let pkID in fileInformation) {
                                const fileInfo = fileInformation[pkID];
                                propsFileInformation[pkID] = fileInfo
                            }
                        }
                    }

                    props.modifyFileInformation(propsFileInformation);
                    props.modifySheetRead(sheetRead);

                    const reduxEditOptions = JSON.parse(JSON.stringify(props.reduxEditOptions));

                    if (props.editID !== "all"){
                        reduxEditOptions[props.editID] = props.editOptions;
                    }
                    else{
                        for (let i=0; i<props.filePK.length; i++){
                            const filePKObj = props.filePK[i];
                            const fileID = filePKObj['id'];

                            reduxEditOptions[fileID] = props.editOptions;
                        }

                        reduxEditOptions["all"] = props.editOptions;
                    }

                    props.modifyEditOptions(reduxEditOptions);

                    socket.close();
                    props.toggleModal(null);
                }

                if (status === "error"){
                    socket.close()
                    props.toggleModal(null);
                    props.toggleToast({show: true, message: "There was an error reading your file with these options 😞. Please try again. Contact our Support if this error persists.", type: "error"})
                }
            };
        }
    }

    render() {
        const text = this.props.editID === "all" ? "Read All Files" : "Read File";
        return (
            <Button color="purple" icon="edit outline" content={text} onClick={this.editFile}/>
        )
    }
}

const mapStateToProps = (state) => ({
    finalColumnNames: state.mainState.finalColumnNames,
    finalColumnPK: state.mainState.finalColumnPK,
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    editID: state.mainState.editID,
    reduxEditOptions: state.mainState.editOptions,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock
})

const mapActionsToProps = {
    modifyStatus: modifyStatus,
    modifyFinalColumnPK: modifyFinalColumnPK,
    modifyFinalColumnNames: modifyFinalColumnNames,
    modifyFileInformation: modifyFileInformation,
    modifyEditOptions: modifyEditOptions,
    toggleModal: toggleModal,
    modifyEditFileResponse: modifyEditFileResponse,
    modifySheetRead: modifySheetRead,
    modifyFilePKProBlock: modifyFilePKProBlock,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(EditFileReadButton)