import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../Constants/API_Root";
import { Button } from 'semantic-ui-react'
import {modifyFinalColumnPK} from "../Actions/ModifyFinalColumnPK";
import {modifyStatus} from "../Actions/ModifyStatus";
import {modifyEditOptions} from "../Actions/ModifyEditOptions";
import {modifyFinalColumnNames} from "../Actions/ModifyFinalColumnNames";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {toggleModal} from "../Actions/ToggleModal";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import isObjectEmpty from "../Constants/isObjectEmpty";
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import {toggleToast} from "../Actions/ToggleToast";

class ImportSheetsButton extends Component {
    constructor(props) {
        super(props);

        this.importSheets = this.importSheets.bind(this);
    }

    importSheets(){
        // Check if there is any error
        const importSheets = true;

        if (importSheets){
            const props = this.props;

            // Close the Import Sheets Modal
            props.toggleModal(null);
            // Open up the import sheets loading modal
            props.toggleModal('loadingModalImportSheets');

            // Open up WebSockets
            const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");

            let socket = new WebSocket(websocketRoute + "ws/api/read-api-files/");
            if (props.fileInformation[props.pkID]['inputType'] !== "file"){
                socket = new WebSocket(websocketRoute + "ws/api/read-api-sheets-dropbox/");
            }

            let filePK = [{'pk': props.pk, 'id': props.pkID}];
            // const sheetRead = this.props.sheetRead;
            const sheetRead = this.props.copiedSheetRead;

            // Offset to get created dt
            const current = new Date();
            const utcOffset = current.getTimezoneOffset();

            // Send the PKs
            socket.onopen = (event) => {
                socket.send(JSON.stringify({
                    filePK: filePK,
                    sheetRead: sheetRead,
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    utcOffset: utcOffset,
                    importSheets: true
                }));
            };

            socket.onmessage = (event) => {
                const reply = JSON.parse(event.data);
                const status = reply['status'];

                props.modifyStatus(status);

                if (status === "read"){
                    let filePKProBlock = reply['filePKProBlock'];

                    if (typeof props.filePKProBlock !== 'undefined') {
                        if (!isObjectEmpty(props.filePKProBlock)) {
                            const copiedFilePKProBlock = JSON.parse(JSON.stringify(props.filePKProBlock));
                            copiedFilePKProBlock[props.pkID] = filePKProBlock[props.pkID];

                            props.modifyFilePKProBlock(filePKProBlock);
                        }
                    }
                }

                if (status === "api"){
                    let fileInformation = reply.fileInformation;
                    if (typeof props.fileInformation !== 'undefined'){
                        if (Object.entries(props.fileInformation).length !== 0 && props.fileInformation.constructor === Object){

                            const copiedFileInformation = JSON.parse(JSON.stringify(props.fileInformation));
                            copiedFileInformation[props.pkID] = fileInformation[props.pkID];

                            props.modifyFileInformation(copiedFileInformation);
                            props.modifySheetRead(reply['sheetRead'])
                        }
                    }

                    socket.close();
                    // Close the loading modal
                    props.toggleModal(null);
                }

                if (status === "error"){
                    socket.close()
                    props.toggleModal(null);
                    props.toggleToast({show: true, message: "There was an error changing your sheet 😞. Please try again. Contact our Support if this error persists.", type: "error"})
                }

            };
        }
    }

    render() {
        return (
            <Button disabled={this.props.btnDisabled} color="purple" icon="file alternate outline" content="Change Sheet" onClick={this.importSheets}/>
        )
    }
}

const mapStateToProps = (state) => ({
    finalColumnNames: state.mainState.finalColumnNames,
    finalColumnPK: state.mainState.finalColumnPK,
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    editID: state.mainState.editID,
    reduxEditOptions: state.mainState.editOptions,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock
})

const mapActionsToProps = {
    modifyFilePK: modifyFilePK,
    modifyStatus: modifyStatus,
    modifyFinalColumnPK: modifyFinalColumnPK,
    modifyFinalColumnNames: modifyFinalColumnNames,
    modifyFileInformation: modifyFileInformation,
    modifyEditOptions: modifyEditOptions,
    modifySheetRead: modifySheetRead,
    toggleModal: toggleModal,
    modifyFilePKProBlock: modifyFilePKProBlock,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(ImportSheetsButton)