import React, {Component} from 'react'
import {connect} from 'react-redux'
import {mapPkAndPkid} from "../../Constants/mapPkAndPkid";
import ReactJson from 'react-json-view'
import RegenerateKeysButton from './RegenerateKeysButton'

class KeysModalBody extends Component {
    render() {
        const pk = mapPkAndPkid(this.props.filePK, "id", this.props.editID)
        const keys = {
            accessKey: this.props.fileInformation[this.props.editID]['accessKey'],
            secretKey: this.props.fileInformation[this.props.editID]['secretKey']
        }

        return (
            <div>
                <br/>
                <ReactJson src={keys} name={null} theme="ocean" displayObjectSize={false}/>
                <br/>
                <RegenerateKeysButton pk={pk} pkID={this.props.editID} />
                <br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation

})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(KeysModalBody)