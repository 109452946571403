import React, {Component} from 'react'
import FileNameSearch from "./FileNameSearch"
import FileTypeFilter from "./FileTypeFilter"
import DateFilter from "./DateFilter"
import DateSort from "./DateSort"
import { connect } from 'react-redux'
import {modifyFilePK} from "../../Actions/ModifyFilePK";
import { Button, Popup } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'

class FilesFilterSortIndex extends Component {
    constructor(props) {
        super(props);

        const originalFileTypes = this.initFileTypes();

        this.state = {
            isFiltering: false,
            searchName: '',
            originalFileTypes: originalFileTypes,
            fileTypes: originalFileTypes,
            startDt: this.props.originalFilePK[this.props.originalFilePK.length - 1].createdDt.substring(0, 10),
            endDt: this.props.originalFilePK[0].createdDt.substring(0, 10)
        }

        this.initFileTypes = this.initFileTypes.bind(this);
        this.changeState = this.changeState.bind(this);
        this.filter = this.filter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.canClearFilterAppear = this.canClearFilterAppear.bind(this);
    }

    initFileTypes(){
        let fileTypes = [];

        for (let pkID in this.props.fileInformation){
            if (fileTypes.length === 3){
                break
            }
            else{
                if (!fileTypes.includes(this.props.fileInformation[pkID]['inputType'])){
                    fileTypes.push(this.props.fileInformation[pkID]['inputType'])
                }
            }
        }

        return fileTypes
    }

    changeState(name, value){
        if (name === "dates"){
            this.setState({
                startDt: value.startDt,
                endDt: value.endDt,
                isFiltering: true
            }, () => this.filter())
        }
        else{
            this.setState({
                [name]: value,
                isFiltering: true
            }, () => this.filter())
        }
    }

    filter(){
        let filteredFilePK = filterPK(this.props.originalFilePK, this.props.fileInformation,
            {
                searchName: this.state.searchName,
                fileTypes: this.state.fileTypes,
                startDt: this.state.startDt,
                endDt: this.state.endDt
            }
        );

        this.props.modifyFilePK(filteredFilePK)

        this.setState({
            isFiltering: false
        })

    }

    clearFilter(e){
        this.setState({
            isFiltering: false,
            searchName: '',
            fileTypes: this.initFileTypes(),
            startDt: this.props.originalFilePK[this.props.originalFilePK.length - 1].createdDt.substring(0, 10),
            endDt: this.props.originalFilePK[0].createdDt.substring(0, 10)
        });

        this.props.modifyFilePK(this.props.originalFilePK)
    }

    canClearFilterAppear(){
        let canAppear = false;
        if (this.props.originalFilePK.length !== this.props.filePK.length){
            canAppear = true
        }
        else{
            for (let i=0; i<this.props.originalFilePK.length; i++){
                if (this.props.originalFilePK[i].id !== this.props.filePK[i].id){
                    canAppear = true
                    break
                }
            }
        }

        return canAppear

    }

    render() {
        return (
            <div style={{border: '1px solid lightgrey', padding: '10px', marginBottom: '20px', backgroundColor: 'whitesmoke'}}>
                <div className="row">
                    <div className="col-sm-6">
                        <FileNameSearch
                            searchName={this.state.searchName}
                            isFiltering={this.state.isFiltering}
                            changeState={this.changeState}
                        />
                        {isMobile ? <br/> : null}
                    </div>
                    <div className="col-sm-6">
                        <FileTypeFilter
                            originalFileTypes={this.state.originalFileTypes}
                            fileTypes={this.state.fileTypes}
                            changeState={this.changeState}
                        />
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-6">
                        <DateFilter
                            startDt={this.state.startDt}
                            endDt={this.state.endDt}
                            changeState={this.changeState}
                        />
                        {isMobile ? <br/> : null}
                    </div>
                    <div className="col-sm-4">
                        <DateSort />
                    </div>
                    <div className="col-sm-2">
                        {this.canClearFilterAppear() ? <Popup trigger={<Button onClick={this.clearFilter} icon="remove" color='red' basic circular style={{float: 'right'}} />} content="Clear All Filters" />  : null}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation,
    originalFilePK: state.mainState.originalFilePK
})

const mapActionsToProps = {
    modifyFilePK: modifyFilePK
}

export default connect(mapStateToProps, mapActionsToProps)(FilesFilterSortIndex)

const filterPK = (originalFilePK, fileInformation, filterValues) => {
    let filteredPK = [];

    for (let i=0; i<originalFilePK.length; i++){
        const pkElement = originalFilePK[i];
        const pkID = pkElement.id;

        const filteredFileInfo = getRelevantFileInfo(fileInformation, pkID);

        if (isSearchNameInFileName(filteredFileInfo.fileName, filterValues.searchName) &&
            isDateBetweenFilters(filteredFileInfo.createdDt, filterValues.startDt, filterValues.endDt) &&
            isFileTypeInFilters(filteredFileInfo.fileType, filterValues.fileTypes))
        {
            filteredPK.push(pkElement)
        }
    }

    return filteredPK
}

const getRelevantFileInfo = (fileInformation, pkID) => {
    const fileInfo = fileInformation[pkID];
    return {
        fileType: fileInfo['inputType'],
        fileName: fileInfo['name'],
        createdDt: fileInfo['createdDt'].substring(0, 10)
    }
}

const isSearchNameInFileName = (fileName, searchName) => {
    return fileName.toLowerCase().trim().indexOf(searchName.toLowerCase().trim()) !== -1
}

const isDateBetweenFilters = (createdDt, startDt, endDt) => {
    return createdDt >= startDt && createdDt <= endDt
}

const isFileTypeInFilters = (fileType, fileTypes) => {
    return fileTypes.includes(fileType)
}